/* eslint-disable react/display-name */
import React, { useState, useEffect, forwardRef } from "react";
import Button from "@mui/material/Button";

interface PhotoCaptureProps {
    onPhotoTaken: (blob: Blob) => void;
}

const PhotoCapture = forwardRef<HTMLButtonElement, PhotoCaptureProps>(
    ({ onPhotoTaken }: any, ref?: any) => {
        const [imageCapture, setImageCapture] = useState<ImageCapture | null>(null);

        const CROPWIDTH = 612;
        const CROPHEIHT = 1080;

        const getDevices = async (): Promise<MediaDeviceInfo[]> => {
            return await navigator.mediaDevices.enumerateDevices();
        };

    const getCamera = (devices: MediaDeviceInfo[]): MediaDeviceInfo => {
        const camera = devices.find(
            (device) =>
                device.kind === "videoinput" && device.label.startsWith("Elgato Virtual Camera")
        );

            if (!camera) {
                throw new Error("Elgato Virtual Camera not found");
            }

            return camera;
        };

        const getConstraints = (camera: MediaDeviceInfo): any => {
            return {
                video: {
                    width: { min: 1920 },
                    height: { min: 1080 },
                    deviceId: { exact: camera.deviceId }
                }
            };
        };

        const startMediaStream = (constraints: MediaStreamConstraints): void => {
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((mediaStream) => {
                    const track = mediaStream.getVideoTracks()[0];
                    setImageCapture(new ImageCapture(track));
                })
                .catch((error) => {
                    console.error(error);
                });
        };

        const onGetUserMediaButtonClick = async (): Promise<void> => {
            try {
                const devices = await getDevices();
                const camera = getCamera(devices);
                const constraints = getConstraints(camera);

                startMediaStream(constraints);
            } catch (error) {
                console.error(error);
            }
        };

        const onGrabFrameButtonClick = (): void => {
            if (imageCapture) {
                imageCapture
                    .grabFrame()
                    .then((imageBitmap: ImageBitmap) => {
                        const startX = (imageBitmap.width - CROPWIDTH) / 2;
                        const startY = 0;

                        const canvas = document.createElement("canvas");
                        canvas.width = CROPWIDTH;
                        canvas.height = CROPHEIHT;
                        const context = canvas.getContext("2d");

                        if (context) {
                            context.drawImage(
                                imageBitmap,
                                startX,
                                startY,
                                CROPWIDTH,
                                CROPHEIHT,
                                0,
                                0,
                                CROPWIDTH,
                                CROPHEIHT
                            );

                            canvas.toBlob((blob) => {
                                if (blob) {
                                    onPhotoTaken(blob);
                                }
                            }, "image/jpeg");
                        }
                    })
                    .catch((error: any) => {
                        console.error(error);
                    });
            }
        };

        useEffect(() => {
            onGetUserMediaButtonClick();
        }, []);

        return (
            <div>
                <Button ref={ref} onClick={onGrabFrameButtonClick} variant="contained">
                    Photo
                </Button>
            </div>
        );
    }
);

export default PhotoCapture;
