import { handleCallError } from "services/exceptions";
import { patchProductInfos } from "services/products";

import { MenuItem, Select } from "@mui/material";

import { useAppDispatch } from "store/hooks";

import type { ProductState, RatedCriteria } from "types/product";

interface Props {
    attractivitytagsList: RatedCriteria[];
    controlledProducts: string[];
    isRowDisabled: boolean;
    product: ProductState;
    setControlledProducts: React.Dispatch<React.SetStateAction<string[]>>;
    setIsRowDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const AttractivityTagActions = ({
    attractivitytagsList,
    controlledProducts,
    isRowDisabled,
    product,
    setControlledProducts,
    setIsRowDisabled
}: Props): JSX.Element => {
    const dispatch = useAppDispatch();

    // Patch product attractivity tag
    async function updateAttractivityTag(id: number): Promise<void> {
        const attractivityTag = attractivitytagsList.find((attractivityTag) => {
            return attractivityTag.id === id;
        });
        const result = await handleCallError(
            dispatch,
            patchProductInfos,
            { attractivity_tag: attractivityTag },
            product.sku
        );
        if (result) {
            setIsRowDisabled(true);
            if (product.sku && !controlledProducts.includes(product.sku)) {
                setControlledProducts([...controlledProducts, product.sku]);
            }
        }
    }

    return (
        <Select
            defaultValue={product.attractivity_tag?.id}
            disabled={isRowDisabled}
            id="attractivity_tag"
            label="attractivity_tag"
            onChange={(event: any) => {
                updateAttractivityTag(event.target.value);
            }}
        >
            {attractivitytagsList.map((item) => {
                return (
                    <MenuItem key={item.id} value={item.id}>
                        {item.label}
                    </MenuItem>
                );
            })}
        </Select>
    );
};

export default AttractivityTagActions;
