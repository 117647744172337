import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Login from "../login/Login";

import { verifyTokenAndFetchUserInfo } from "services/login";
import { handleCallErrorWithLoader } from "services/exceptions";

import { useAppDispatch, useAppSelector } from "store/hooks";
import {
    selectAuthentication,
    setAuthenticationInfos,
    clearAuthenticationInfos
} from "store/authenticationSlice";
import { handleGroupRedirect } from "hooks/handleGroupRedirect";

const ProtectedRoute = (): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        void verifyTokenAndFetchUserInfoOnLoad();
    }, []);

    async function verifyTokenAndFetchUserInfoOnLoad(): Promise<void> {
        const token = localStorage.getItem("access-token");

        if (!token) {
            dispatch(clearAuthenticationInfos());
            return;
        }

        const result = await handleCallErrorWithLoader(
            dispatch,
            verifyTokenAndFetchUserInfo,
            token
        );

        if (!result?.user) {
            dispatch(clearAuthenticationInfos());
            return;
        }

        dispatch(setAuthenticationInfos(result.user));
        handleGroupRedirect(result.user.groups, dispatch, navigate);
    }

    const authenticationInfos = useAppSelector(selectAuthentication);

    if (authenticationInfos.isLoggedIn === true) {
        return <Outlet />;
    } else if (authenticationInfos.isLoggedIn === false) {
        return <Login />;
    } else {
        return null;
    }
};

export default ProtectedRoute;
