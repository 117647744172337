import { axiosClient } from "helpers/axios";

async function postControlledProducts(controlName: string, skus: string[]): Promise<any> {
    const data = { products: skus };
    const result = await axiosClient().post(`product-controls/${controlName}/`, data);

    return result.data;
}

export { postControlledProducts };
