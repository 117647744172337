import FormField from "components/formFields/FormField";
import UploadButton from "components/formFields/UploadButton";

import type { Control } from "react-hook-form";
import type { ProductPhoto } from "types/product";

interface Props {
    control: Control;
    existantPhoto: ProductPhoto | undefined;
    isRequired?: boolean;
}

const PhotoReverseButton = ({ control, existantPhoto, isRequired }: Props): JSX.Element => {
    return (
        <FormField>
            <UploadButton
                control={control}
                existantPhoto={existantPhoto}
                isRequired={isRequired}
                name="photo_REVERSE"
                text="Photo dos"
                variant="contained"
            />
        </FormField>
    );
};

export default PhotoReverseButton;
