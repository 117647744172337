import { useNavigate, useParams } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import CartButton from "./CartButton";
import NotFoundButton from "./NotFoundButton";
import PhotoCarousel from "./PhotoCarousel";

import type { OrderedProduct } from "types/picking";
import { PRODUCTS_TO_PICK_PARAM } from "constants/operationsRules";

interface Props {
    productToPick?: OrderedProduct;
}

const PickingInProgress = ({ productToPick }: Props): JSX.Element => {
    const navigate = useNavigate();

    const { product_picking_status } = useParams();

    return (
        <>
            <Box
                sx={{
                    backgroundColor: productToPick?.picking_rail_product.location?.rack_area_color,
                    color: "white",
                    width: "100%"
                }}
            >
                <Typography variant="h4">
                    {productToPick?.picking_rail_product.location?.label}
                </Typography>
            </Box>

            {productToPick?.picking_rail_product?.photos?.length && (
                <PhotoCarousel photosToDisplay={productToPick.picking_rail_product.photos} />
            )}

            <Box>
                <Typography sx={{ fontWeight: "bold" }} variant="h4">
                    {productToPick?.picking_rail_product.item_qr?.slice(0, -4)}
                    <Box component="span" sx={{ color: "#d32f2f" }}>
                        {productToPick?.picking_rail_product.item_qr?.slice(-4)}
                    </Box>
                </Typography>
                <Typography sx={{ fontWeight: "bold" }} variant="h5">
                    {productToPick?.picking_rail_product.brand?.label}
                </Typography>
                <Typography sx={{ marginTop: "-8px" }} variant="h6">
                    {productToPick?.picking_rail_product.category?.label} -{" "}
                    {productToPick?.picking_rail_product.size?.label}
                </Typography>
                <Typography sx={{ marginTop: "-8px" }} variant="h6">
                    {productToPick?.picking_rail_product.color?.label}
                </Typography>
            </Box>

            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    gap: 2,
                    height: "60px",
                    justifyContent: "space-between",
                    width: "100%"
                }}
            >
                {product_picking_status === PRODUCTS_TO_PICK_PARAM && productToPick ? (
                    <NotFoundButton productToPick={productToPick} />
                ) : (
                    <Box sx={{ width: "65px" }} />
                )}
                <Button
                    color="primary"
                    endIcon={<QrCodeScannerIcon sx={{ fontSize: "30px" }} />}
                    onClick={() => {
                        product_picking_status === PRODUCTS_TO_PICK_PARAM
                            ? navigate(
                                  `${String(productToPick?.picking_rail_product.item_qr)}/scan`
                              )
                            : navigate(`scan`);
                    }}
                    size="large"
                    sx={{ fontSize: "30px", padding: "2px", width: "50%" }}
                    variant="outlined"
                >
                    PICK
                </Button>
                <CartButton />
            </Box>
        </>
    );
};

export default PickingInProgress;
