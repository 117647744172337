import { useState } from "react";
import { useParams } from "react-router-dom";

import { handlePickingSessionCreation } from "helpers/picking/handlePickingSessionCreation";
import { handlePickedProductCreation } from "helpers/picking/handlePickedProductCreation";

import { Box, Button, Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectPicking } from "store/pickingSlice";

import type { OrderedProduct, PickedProduct } from "types/picking";

interface Props {
    productToPick: OrderedProduct;
}
const NotFoundButton = ({ productToPick }: Props): JSX.Element => {
    const dispatch = useAppDispatch();

    const { picking_rail_id } = useParams();

    const pickedProducts: PickedProduct[] = useAppSelector(selectPicking).pickedProducts;
    let pickingSessionId = useAppSelector(selectPicking).id;

    const [isNotFoundDialogOpen, setIsNotFoundDialogOpen] = useState<boolean>(false);

    // On dialog confirm : not found PickedProduct is created
    // When it's first picked product and no picking session, it creates new picking session
    async function handleNotFoundProduct(): Promise<void> {
        if (!pickedProducts.length && !pickingSessionId && picking_rail_id) {
            pickingSessionId = await handlePickingSessionCreation(dispatch, picking_rail_id);
        }

        if (pickingSessionId) {
            const isRetrieval = Boolean(productToPick?.retrieval);

            const pickedProductData = {
                has_wrong_item_qr: false,
                is_not_found: true,
                is_picked: false,
                is_retrieval: isRetrieval,
                ...(isRetrieval
                    ? { retrieved_product_id: productToPick.id }
                    : { ordered_product_id: productToPick.id })
            };

            await handlePickedProductCreation(
                dispatch,
                pickedProductData,
                pickingSessionId,
                productToPick
            );
        }

        setIsNotFoundDialogOpen(false);
    }

    return (
        <>
            <IconButton
                aria-label="Not found"
                onClick={() => {
                    setIsNotFoundDialogOpen(true);
                }}
                sx={{ backgroundColor: "#d32f2f" }}
            >
                <CloseIcon sx={{ color: "white", fontSize: "45px" }} />
            </IconButton>
            <Dialog
                onClose={() => {
                    setIsNotFoundDialogOpen(false);
                }}
                open={isNotFoundDialogOpen}
            >
                <Box sx={{ padding: "16px 24px 0" }} textAlign="center">
                    <Typography color="error" sx={{ fontWeight: "bold" }} variant="h5">
                        Produit introuvable
                    </Typography>
                    <Typography variant="h5">
                        {productToPick.picking_rail_product.item_qr?.slice(0, -4)}
                        <Box component="span" sx={{ color: "#d32f2f" }}>
                            {productToPick.picking_rail_product.item_qr?.slice(-4)}
                        </Box>
                    </Typography>
                </Box>
                <DialogContent
                    sx={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <Button
                        color="primary"
                        onClick={handleNotFoundProduct}
                        size="large"
                        sx={{ fontSize: "20px", margin: "20px", padding: "2px 20px" }}
                        variant="contained"
                    >
                        Confirmer
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default NotFoundButton;
