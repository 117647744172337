import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import ChoiceButton from "components/buttons/ChoiceButton";
import ScreenTitle from "components/screenTitle";

import { selectAuthentication } from "store/authenticationSlice";
import { clearErrorMessage } from "store/errorSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

const WarehouseHome = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const authenticationInfos = useAppSelector(selectAuthentication);

    useEffect(() => {
        dispatch(clearErrorMessage());
    }, []);

    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                gap: 1
            }}
        >
            <ScreenTitle text={`Hello ${String(authenticationInfos.username)}`} />

            <Box sx={{ display: "flex", gap: 6, justifyContent: "center", marginTop: 4 }}>
                <ChoiceButton
                    color="primary"
                    onClick={() => {
                        navigate("pickingrails");
                    }}
                    sx={{ fontSize: "18px", padding: "12px", minWidth: 200 }}
                    text="⛏️ Picking"
                    variant="outlined"
                />
            </Box>
        </Box>
    );
};

export default WarehouseHome;
