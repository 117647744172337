import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { authenticateUserAndGetToken } from "services/login";
import { handleCallErrorWithLoader } from "services/exceptions";

import ChoiceButton from "components/buttons/ChoiceButton";
import FormField from "components/formFields/FormField";
import ScreenTitle from "components/screenTitle";
import TextInputField from "components/formFields/TextInputField";
import { Box } from "@mui/material";

import { setAuthenticationInfos } from "store/authenticationSlice";
import { useAppDispatch } from "store/hooks";
import { handleGroupRedirect } from "hooks/handleGroupRedirect";

const Login = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { handleSubmit, control } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange"
    });

    const handleLogin = (data: any): void => {
        void (async () => {
            const result = await handleCallErrorWithLoader(
                dispatch,
                authenticateUserAndGetToken,
                data
            );

            if (result) {
                result.token.access && localStorage.setItem("access-token", result.token.access);
                dispatch(setAuthenticationInfos(result.user));
                handleGroupRedirect(result.user.groups, dispatch, navigate, "Login");
            }
        })();
    };

    return (
        <div>
            <ScreenTitle text={"Veuillez vous identifier"} />
            <form>
                <Box sx={{ width: 300, mx: "auto", my: 4 }}>
                    <FormField>
                        <TextInputField
                            color="primary"
                            control={control}
                            label={"Utilisateur.ice"}
                            name="username"
                        />
                    </FormField>
                    <FormField>
                        <TextInputField
                            color="primary"
                            control={control}
                            label={"Mot de passe"}
                            name="password"
                            type="password"
                        />
                    </FormField>
                    <ChoiceButton
                        onClick={handleSubmit(handleLogin)}
                        text="Se connecter"
                        type="submit"
                        variant="contained"
                    />
                </Box>
            </form>
        </div>
    );
};

export default Login;
