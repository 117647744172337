import { handlePhotosSorting } from "helpers/photos/handlePhotosSorting";
import { handlePhotosFiltering } from "helpers/photos/handlePhotosFiltering";
import handlePickedTargetProduct from "./handlePickedTargetProduct";
import {
    addNotFoundProducts,
    addPickedProducts,
    removeNotFoundProduct,
    removeProductToPick
} from "store/pickingSlice";

import type { Dispatch } from "redux";
import type { PickedProduct } from "types/picking";
import { PICKING_PHOTO_FORMAT_DISPLAYED } from "constants/operationsRules";

export function handlePickedProductStorage(
    dispatch: Dispatch,
    pickedProduct: PickedProduct,
    productToPickId?: number
): void {
    // In picking store :
    // - Add pickedProduct to NotFoundProducts/PickedProducts
    // - remove it from ProductsToPick/NotFoundProducts
    // Store products with only needed photos
    let productPhotos = pickedProduct.ordered_product?.picking_rail_product.photos;

    if (productPhotos?.length) {
        productPhotos = handlePhotosSorting(
            handlePhotosFiltering(PICKING_PHOTO_FORMAT_DISPLAYED, productPhotos)
        );

        const targetProduct = handlePickedTargetProduct(pickedProduct);

        if (targetProduct?.picking_rail_product) {
            targetProduct.picking_rail_product.photos = productPhotos;
        }
    }

    if (pickedProduct.is_not_found) {
        dispatch(addNotFoundProducts(pickedProduct));
    } else {
        dispatch(addPickedProducts(pickedProduct));
        !productToPickId && dispatch(removeNotFoundProduct(pickedProduct.id));
    }
    productToPickId && dispatch(removeProductToPick(productToPickId));
}
