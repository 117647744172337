import ScreenTitle from "components/screenTitle";
import ControlButton from "./ControlButton";
import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { handleCallErrorWithLoader } from "services/exceptions";
import { fetchAttractivitytags } from "services/criterias";
import { useAppDispatch } from "store/hooks";
import type { RatedCriteria } from "types/product";
import ControlSection from "./ControlSection";
import ControlButtonsContainer from "./ControlButtonsContainer";

const ControlPage = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const [attractivitytagsList, setAttractivitytagsList] = useState<RatedCriteria[]>([]);

    // On page render : fetch attractivity tags for attractivity tags controls
    useEffect(() => {
        handleCallErrorWithLoader(dispatch, fetchAttractivitytags).then((result) => {
            setAttractivitytagsList(result.reverse());
        });
    }, []);

    return (
        <Container>
            <ScreenTitle text="Contrôle - Studio" />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                    justifyContent: "center",
                    marginTop: 4
                }}
            >
                <ControlSection bgColor="#cfcfcf" title="DAILY">
                    <ControlButtonsContainer>
                        <ControlButton color="purple" controlName="brands" text="Marques Standby" />
                        <ControlButton color="purple" controlName="universes" text="Univers" />
                        <ControlButton color="purple" controlName="categories" text="Catégories" />
                    </ControlButtonsContainer>
                    <ControlButtonsContainer>
                        <ControlButton color="purple" controlName="sizes" text="Tailles" />
                        <ControlButton
                            color="purple"
                            controlName="worn_photos"
                            text="Photos portées"
                        />

                        {/* <ControlButton color="yellow" controlName="price" text="Pricing" /> */}
                    </ControlButtonsContainer>
                </ControlSection>

                <ControlSection>
                    <ControlButtonsContainer>
                        {attractivitytagsList.map((attractivityTag, i) => {
                            return (
                                <ControlButton
                                    key={i}
                                    color="green"
                                    controlName="attractivity_tags"
                                    subcontrolId={attractivityTag.id}
                                    text={`Tag ${attractivityTag.label}`}
                                />
                            );
                        })}
                    </ControlButtonsContainer>
                    <ControlButtonsContainer></ControlButtonsContainer>
                </ControlSection>
            </Box>
        </Container>
    );
};

export default ControlPage;
