import { axiosClient } from "helpers/axios";

async function fetchConditions(): Promise<any> {
    const result = await axiosClient().get("conditions/");

    return result.data;
}

async function fetchBrands(inputBrand: string): Promise<any> {
    const result = await axiosClient().get("brands?brand=" + encodeURIComponent(inputBrand));

    return result.data;
}

async function fetchMaterials(): Promise<any> {
    const result = await axiosClient().get("materials/");

    return result.data;
}

async function fetchSizeTypes(): Promise<any> {
    const result = await axiosClient().get("sizes/types/");

    return result.data;
}

async function fetchSizes(category_id?: number, type?: string): Promise<any> {
    const params = {
        category_id,
        type
    };
    const result = await axiosClient().get("sizes/", { params });

    return result.data;
}

async function getWorthSize(category_id: number, size_id: number): Promise<any> {
    const params = {
        category_id,
        size_id
    };
    const result = await axiosClient().get("sizes/worth/", { params });

    return result.data;
}

async function fetchColors(): Promise<any> {
    const result = await axiosClient().get("colors/");

    return result.data;
}

async function fetchAttractivitytags(): Promise<any> {
    const result = await axiosClient().get("attractivitytags/");

    return result.data;
}

async function fetchCategories(): Promise<any> {
    const result = await axiosClient().get("categories/");

    return result.data;
}

async function fetchCategoriesByLabel(label: string): Promise<any> {
    const result = await axiosClient().get(`categories/?label=${label}`);

    return result.data;
}

async function fetchCategoriesByLabelAndUniverse(label?: string, universe?: string): Promise<any> {
    const params = {
        label,
        universe
    };
    const result = await axiosClient().get("categories/", { params });

    return result.data;
}

async function fetchCategoriesByParent(parent_id: number): Promise<any> {
    const result = await axiosClient().get(`categories/?parent_id=${parent_id}`);

    return result.data;
}

export {
    fetchAttractivitytags,
    fetchConditions,
    fetchBrands,
    fetchMaterials,
    fetchSizeTypes,
    fetchSizes,
    getWorthSize,
    fetchColors,
    fetchCategories,
    fetchCategoriesByLabel,
    fetchCategoriesByLabelAndUniverse,
    fetchCategoriesByParent
};
