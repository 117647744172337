import { Box, Paper, Typography } from "@mui/material";
import ChoiceButton from "components/buttons/ChoiceButton";

import { CONTROL_BRANDS, CONTROL_SIZES } from "constants/usersRules";

interface Props {
    currentControlTitle: string;
    onClick: any;
    remainingControls: number;
    taskName?: string;
}

const ControlFooter = ({
    currentControlTitle,
    onClick,
    remainingControls,
    taskName
}: Props): JSX.Element => {
    function isRowButtonDisabled(): boolean {
        if (taskName) {
            return (
                [CONTROL_BRANDS, CONTROL_SIZES].includes(taskName?.toUpperCase()) &&
                remainingControls > 0
            );
        } else {
            return false;
        }
    }
    return (
        <Paper
            elevation={3}
            sx={{
                backgroundColor: "#e5e5e5",
                bottom: 0,
                left: 0,
                position: "fixed",
                right: 0,
                width: "100vw",
                zIndex: "100"
            }}
        >
            <Box color="secondary" sx={{ margin: "10px" }}>
                <Box sx={{ alignItems: "center", display: "flex", padding: 0 }}>
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            marginLeft: "10px",
                            textAlign: "left",
                            textTransform: "uppercase"
                        }}
                    >
                        🌈 Contrôle : {currentControlTitle}
                    </Typography>
                    <Typography sx={{ flexGrow: "1" }}>
                        {`${remainingControls} produits restants à contrôler`}
                    </Typography>
                    <ChoiceButton
                        color="primary"
                        disabled={isRowButtonDisabled()}
                        onClick={onClick}
                        sx={{ fontSize: "12px" }}
                        text="Valider la session"
                        variant="contained"
                    />
                </Box>
            </Box>
        </Paper>
    );
};

export default ControlFooter;
