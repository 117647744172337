import { useLayoutEffect } from "react";

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import ChoiceButton from "components/buttons/ChoiceButton";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { clearErrorMessage, selectError } from "store/errorSlice";

import sounds from "assets/sounds";

const ErrorDialog = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const error = useAppSelector(selectError);
    const errorSound = new Audio(sounds.errorSound);

    // When error is displayed, it plays buzz sound
    useLayoutEffect(() => {
        if (error.message) {
            errorSound.play().catch((e) => {
                console.log(e);
            });
        }
    }, [error]);

    return (
        <Dialog aria-labelledby="error-dialog" open={!!error.message} sx={{ zIndex: "101" }}>
            <DialogTitle color="error" sx={{ fontWeight: "bold", textAlign: "center" }}>
                ⚠️ Erreur ⚠️
            </DialogTitle>
            <DialogContent>
                <Typography color="error" sx={{ textAlign: "center" }}>
                    {error.message}
                </Typography>
                <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                    <ChoiceButton
                        color="error"
                        onClick={() => {
                            dispatch(clearErrorMessage());
                        }}
                        text="OK"
                        variant="contained"
                    />
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default ErrorDialog;
