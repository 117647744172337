import { useEffect, useState } from "react";

import { handleCallErrorWithLoader } from "services/exceptions";
import { fetchProductsToPrice } from "services/products";

import PricingRow from "components/pricingPage/PricingRow";
// import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import { type PricelessProduct } from "types/product";

import { useAppDispatch } from "store/hooks";
// import { clearPrice } from "store/priceSlice";

import pricingStyles from "./PricingPage.module.css";

const PricingPage = (): JSX.Element => {
    const dispatch = useAppDispatch();

    // Manage Catalog / Designer views
    const [productIsDesigner /* setProductIsDesigner */] = useState<boolean>(false);

    // const handleToggle = (event: React.MouseEvent<HTMLElement>, value: boolean) => {
    //     setProductIsDesigner(value);
    //     dispatch(clearPrice());
    // };

    // Product list fetched by api call
    const [productsList, setProductsList] = useState<PricelessProduct[]>([]);

    useEffect(() => {
        fetchPricingData();
    }, [productIsDesigner]);

    const fetchPricingData = async (): Promise<void> => {
        const result = await handleCallErrorWithLoader(
            dispatch,
            fetchProductsToPrice,
            productIsDesigner
        );
        if (result) {
            setProductsList(result);
        }
    };

    // Remove product row after price post
    const [processedProduct, setProcessedProduct] = useState<string>();

    const removeRow = (): void => {
        const newList = productsList.filter((product) => product.sku !== processedProduct);
        setProductsList(newList);
    };

    useEffect(() => {
        removeRow();
    }, [processedProduct]);

    return (
        <div className={pricingStyles.pricing__container}>
            <h2>Outil de Pricing</h2>
            <div className={pricingStyles.pricing__header}>
                <p>{productsList.length} prix à saisir</p>
                {/* <ToggleButtonGroup
                    color="primary"
                    value={productIsDesigner}
                    exclusive
                    onChange={handleToggle}
                    className={pricingStyles.pricing__headerButtons}
                >
                    <ToggleButton value={false} disabled={!productIsDesigner}>
                        Catalogue
                    </ToggleButton>
                    <ToggleButton value={true} disabled={productIsDesigner}>
                        Luxe
                    </ToggleButton>
                </ToggleButtonGroup> */}
            </div>
            {productsList.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Photos</th>
                            <th>Marque</th>
                            <th>Catégorie</th>
                            <th>Source</th>
                            <th>Prix</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productsList.map((product) => {
                            return (
                                <PricingRow
                                    key={product.sku}
                                    product={product}
                                    productIsDesigner={productIsDesigner}
                                    setProcessedProduct={setProcessedProduct}
                                />
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <p>Pas de produit à estimer</p>
            )}
        </div>
    );
};

export default PricingPage;
