import { useEffect, useState } from "react";

import { handleCallErrorWithLoaderCached } from "services/exceptions";
import { endPickingSession } from "services/picking";

import { Box } from "@mui/material";
import PickingFinished from "./sections/PickingFinished";
import PickingInProgress from "./sections/PickingInProgress";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectPicking } from "store/pickingSlice";

import type { OrderedProduct } from "types/picking";

const CurrentPicking = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const pickingSessionId = useAppSelector(selectPicking).id;
    const productsToPick: OrderedProduct[] = useAppSelector(selectPicking).productsToPick;

    const [productToPick, setProductToPick] = useState<OrderedProduct>();

    // On page render : Verify if there is a picking session in progress, then :
    // get product to pick infos from pickingSlice
    // if no product left to pick : clear picking store & close picking session
    useEffect(() => {
        if (productsToPick.length === 0) {
            closePickingSession();
        } else {
            setProductToPick(productsToPick[0]);
        }
    }, [productsToPick]);

    async function closePickingSession(): Promise<void> {
        if (pickingSessionId) {
            await handleCallErrorWithLoaderCached(dispatch, endPickingSession, pickingSessionId);
        }
    }

    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                gap: 1,
                marginTop: "-1rem",
                height: "calc(90vh - 72px)",
                width: "100%"
            }}
        >
            {productsToPick.length && productToPick ? (
                <PickingInProgress productToPick={productToPick} />
            ) : (
                <PickingFinished />
            )}
        </Box>
    );
};

export default CurrentPicking;
