import { useState } from "react";

import { Box, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import type { ProductPhoto } from "types/product";

interface Props {
    photosToDisplay: ProductPhoto[];
}

const PhotoCarousel = ({ photosToDisplay }: Props): JSX.Element => {
    // use localHost for local build
    const photosBaseUrl: string = import.meta.env.VITE_APP_LOCAL_BASE_URL ?? "";

    const [photoIndex, setPhotoIndex] = useState<number>(0);

    function changePhotoIndex(operator: number): void {
        let newIndex = (photoIndex + operator) % photosToDisplay.length;
        if (newIndex < 0) {
            newIndex = photosToDisplay.length + newIndex;
        }
        setPhotoIndex(newIndex);
    }

    return (
        <Box sx={{ height: "calc(100% - 200px)", position: "relative", width: "100%" }}>
            <Box
                alignItems={"center"}
                display="flex"
                justifyContent={"space-between"}
                sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%"
                }}
            >
                <IconButton
                    aria-label="previous"
                    onClick={() => {
                        changePhotoIndex(-1);
                    }}
                    sx={{ margin: 1 }}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <IconButton
                    aria-label="next"
                    onClick={() => {
                        changePhotoIndex(+1);
                    }}
                    sx={{ margin: 1 }}
                >
                    <ChevronRightIcon />
                </IconButton>
            </Box>
            <img
                alt={photosToDisplay[photoIndex].id.toString()}
                src={`${photosBaseUrl}${photosToDisplay[photoIndex].photo_url}`}
                style={{ maxHeight: "100%", objectFit: "fill", width: "100%" }}
            />
        </Box>
    );
};

export default PhotoCarousel;
