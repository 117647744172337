import { Route, Routes, useParams } from "react-router-dom";

import CurrentPickedProduct from "../currentPickedProduct/CurrentPickedProduct";
import CurrentPicking from "../currentPicking/CurrentPicking";
import ScanProductQr from "../scanProductQr/ScanProductQr";

import { PICKED_PRODUCTS_PARAM, PRODUCTS_TO_PICK_PARAM } from "constants/operationsRules";

const ProductPickingStatusRouter = (): JSX.Element => {
    const { product_picking_status } = useParams();

    return (
        <>
            {product_picking_status === PRODUCTS_TO_PICK_PARAM && (
                <Routes>
                    <Route element={<CurrentPicking />} index />
                    <Route element={<ScanProductQr />} path=":product_itemqr/scan" />
                </Routes>
            )}
            {product_picking_status === PICKED_PRODUCTS_PARAM && (
                <Routes>
                    <Route element={<CurrentPickedProduct />} path=":picked_product_id" />
                    <Route element={<ScanProductQr />} path=":picked_product_id/scan" />
                </Routes>
            )}
        </>
    );
};

export default ProductPickingStatusRouter;
