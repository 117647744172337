import { useNavigate } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import CartButton from "./CartButton";
import ChoiceButton from "components/buttons/ChoiceButton";

const PickingFinished = (): JSX.Element => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                justifyContent: "center",
                height: "100%"
            }}
        >
            <Typography variant="h4">BRAVO ! 🎉</Typography>
            <Typography mb={12}>Plus de produit à picker</Typography>
            <CartButton />
            <ChoiceButton
                color="primary"
                onClick={() => {
                    navigate(-1);
                }}
                sx={{
                    fontSize: "18px",
                    marginTop: "20px",
                    padding: "6px 20px",
                    minWidth: "120px"
                }}
                text="Écran de Picking"
                variant="outlined"
            />
        </Box>
    );
};

export default PickingFinished;
