import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { handleCallErrorWithLoader } from "services/exceptions";
import { fetchStockFromBarcode } from "services/stocks";

import { IconButton } from "@mui/material";
import ChoiceButton from "components/buttons/ChoiceButton";
import CollapseIcon from "@mui/icons-material/ExpandMore";
import ExpandIcon from "@mui/icons-material/ExpandLess";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectStock, clearStock } from "store/stockSlice";

import type { StockOverview } from "types/stock";
import footerStyle from "./StockFooter.module.css";
import StockFooterDialog from "./StockFooterDialog";

interface Props {
    isFooterCollapsed: boolean;
    setIsFooterCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const StockFooter = ({ isFooterCollapsed, setIsFooterCollapsed }: Props): JSX.Element => {
    const stock = useAppSelector(selectStock);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [isStockFooterDialogOpen, setIsStockFooterDialogOpen] = useState<boolean>(false);
    const [finishedStockProducts, setFinishedStockProducts] = useState<StockOverview>();

    /**
     * Clear current stock
     * Clear local storage and dispatch event
     */
    const clearCurrentStock = (): void => {
        localStorage.setItem("stock", "");
        dispatch(clearStock());
        navigate("/studio");
    };

    /**
     * Close Button
     * - Fetch closing stock to display products counts (total, accepted, refused)
     */
    async function handleStockClosingConfirmation(): Promise<void> {
        const closingStock = await handleCallErrorWithLoader(
            dispatch,
            fetchStockFromBarcode,
            stock.barcode
        );
        if (closingStock) {
            setIsStockFooterDialogOpen(true);

            setFinishedStockProducts({
                total: closingStock.number_of_processed_products,
                accepted: closingStock.number_of_accepted_products,
                refused: closingStock.number_of_refused_products
            });
        }
    }

    return (
        <>
            <div className={footerStyle.footer__container}>
                <div
                    className={
                        isFooterCollapsed
                            ? footerStyle.footer__title__collapsed
                            : footerStyle.footer__title
                    }
                >
                    <h3>{"📦 Stock ouvert"}</h3>
                    {isFooterCollapsed && (
                        <span className={footerStyle.footer__stockInfos__value}>
                            {stock.barcode}
                        </span>
                    )}
                    <IconButton
                        onClick={() => {
                            setIsFooterCollapsed(!isFooterCollapsed);
                        }}
                        sx={{ padding: 1 }}
                    >
                        {isFooterCollapsed ? <ExpandIcon /> : <CollapseIcon />}
                    </IconButton>
                </div>
                {!isFooterCollapsed && (
                    <div className={footerStyle.footer__body}>
                        <div
                            className={
                                stock.enableCloseStock
                                    ? footerStyle.footer__stockInfos__small
                                    : footerStyle.footer__stockInfos__large
                            }
                        >
                            <p>
                                <span className={footerStyle.footer__stockInfos__label}>
                                    {"Id"}
                                </span>
                                <span className={footerStyle.footer__stockInfos__value}>
                                    {stock.barcode}
                                </span>
                            </p>
                            <p>
                                <span className={footerStyle.footer__stockInfos__label}>
                                    {"Pièces processées"}
                                </span>
                                <span className={footerStyle.footer__stockInfos__value}>
                                    {stock.numberOfProcessedProducts} /{" "}
                                    {stock.numberOfDeclaredItems}
                                </span>
                            </p>
                        </div>
                        {stock.enableCloseStock && (
                            <div className={footerStyle.footer__buttons__container}>
                                <ChoiceButton
                                    fullWidth
                                    onClick={() => {
                                        handleStockClosingConfirmation();
                                    }}
                                    sx={{ padding: "5.5px 16px" }}
                                    text="Clôturer"
                                    variant="contained"
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            <StockFooterDialog
                clearCurrentStock={clearCurrentStock}
                finishedStockProducts={finishedStockProducts}
                isStockFooterDialogOpen={isStockFooterDialogOpen}
                setIsStockFooterDialogOpen={setIsStockFooterDialogOpen}
            />
        </>
    );
};

export default StockFooter;
