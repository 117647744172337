import { Box, type SxProps, type Theme } from "@mui/material";

import theme from "theme";

interface Props {
    children?: JSX.Element | JSX.Element[];
    sx?: SxProps<Theme> | undefined;
}

const FormField = ({ children, sx }: Props): JSX.Element => (
    <Box mx={theme.spacing(1)} my={theme.spacing(2)} sx={sx}>
        {children}
    </Box>
);

export default FormField;
