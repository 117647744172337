import { type RootState } from ".";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type ProductPriceToPost } from "types/product";

const initialState: ProductPriceToPost = {};

export const priceSlice = createSlice({
    name: "price",
    initialState,
    reducers: {
        setPriceToPost: (state: ProductPriceToPost, action: PayloadAction<ProductPriceToPost>) => {
            state = action.payload;
            return state;
        },
        clearPrice: (state: ProductPriceToPost) => {
            state = initialState;
            return state;
        }
    }
});

export const { setPriceToPost, clearPrice } = priceSlice.actions;
export const selectPrice = (state: RootState): ProductPriceToPost => state.price;

export default priceSlice.reducer;
