import { useNavigate } from "react-router-dom";

import ChoiceButton from "components/buttons/ChoiceButton";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { clearProduct } from "store/productSlice";
import { selectLoader } from "store/loaderSlice";

const CancelButton = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const loader = useAppSelector(selectLoader);

    function cancelForm(): void {
        dispatch(clearProduct());
        navigate("/studio/product/open");
    }

    return (
        <ChoiceButton
            color="error"
            disabled={loader.isLoading}
            onClick={cancelForm}
            sx={{ padding: "6px 10px" }}
            text="Annuler"
            variant="outlined"
        />
    );
};

export default CancelButton;
