import { useState } from "react";
import { QrReader } from "react-qr-reader";

interface Props {
    setScannedQr: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const QrScanner = ({ setScannedQr }: Props): JSX.Element => {
    const scannerConstraints: MediaTrackConstraints = { facingMode: "environment" };

    const [isQrScanned, setIsQrScanned] = useState<boolean>(false);

    function handleQrScan(scannedQr: string): void {
        setIsQrScanned(true);
        scannedQr && setScannedQr(scannedQr);
        setTimeout(() => {
            setIsQrScanned(false);
        }, 500);
    }

    return (
        <QrReader
            constraints={scannerConstraints}
            containerStyle={{ border: "solid 1px black", width: "100%" }}
            onResult={(result: any) => {
                if (result) {
                    handleQrScan(result.getText());
                }
            }}
            videoContainerStyle={isQrScanned && { opacity: 0.1 }}
            videoStyle={{ objectFit: "cover" }}
        />
    );
};

export default QrScanner;
