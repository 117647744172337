import { useEffect, useState, forwardRef, useImperativeHandle, type SetStateAction } from "react";
import { Controller } from "react-hook-form";
import { matchSorter } from "match-sorter";
import { TextField, Autocomplete, FormControl } from "@mui/material";
import { type AutocompleteParams } from "types/main";

const AutocompleteField = forwardRef<any, AutocompleteParams>(
    (
        {
            autoFocus,
            control,
            disabled,
            existantCriteria,
            id,
            index,
            isRequired,
            label,
            name,
            optionalOnChange,
            optionalOnInputChange,
            optionsList
        }: AutocompleteParams,
        ref
    ): JSX.Element => {
        const filterOptions = (options: any, { inputValue }: any): string[] =>
            matchSorter(options, inputValue, { keys: ["label"] });

        const [value, setValue] = useState(existantCriteria ?? null);
        const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);
        const [isLoading, setIsLoading] = useState<boolean>(false);

        const smallBrand = "Petite marque";
        const unknowBrand = "Sans marque";
        const minLengthForBrandInput = 2;

        useEffect(() => {
            setIsLoading(false);
        }, [optionsList]);

        return (
            <Controller
                key={id}
                control={control}
                defaultValue={existantCriteria != null ? existantCriteria : null}
                name={name}
                render={({ field: { onChange, value: fieldValue }, fieldState: { error } }) => {
                    useEffect(() => {
                        if (existantCriteria) {
                            setValue(existantCriteria);
                            onChange(existantCriteria);
                        }
                    }, [existantCriteria]);

                    useImperativeHandle(ref, () => ({
                        setValue(newValue: SetStateAction<any>) {
                            const option = optionsList.find((opt) => opt.label === newValue.label);

                            if (option) {
                                setValue(option);
                                onChange(option);
                            }
                        }
                    }));

                    return (
                        <FormControl fullWidth>
                            <Autocomplete
                                disabled={disabled}
                                filterOptions={filterOptions}
                                id={name}
                                isOptionEqualToValue={(option: any, value: any) =>
                                    option.label === value.label
                                }
                                loading={isLoading}
                                onChange={(_, data) => {
                                    setValue(data);
                                    if (optionalOnChange != null && data) {
                                        optionalOnChange(data, index);
                                    }
                                    onChange(data);
                                    return data;
                                }}
                                onInputChange={(event, data) => {
                                    if (
                                        data.length >= minLengthForBrandInput &&
                                        event?.type !== "click" &&
                                        optionalOnInputChange != null &&
                                        data !== unknowBrand &&
                                        data !== smallBrand
                                    ) {
                                        setIsLoading(true);

                                        if (debounceTimer) {
                                            clearTimeout(debounceTimer);
                                        }
                                        setDebounceTimer(
                                            setTimeout(() => {
                                                optionalOnInputChange(data);
                                            }, 500)
                                        );
                                    }
                                }}
                                options={optionsList}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        autoFocus={autoFocus}
                                        error={!!error}
                                        helperText={error ? "Ce champ est requis" : ""}
                                        label={`${String(label)}${isRequired ? ` *` : ""}`}
                                        sx={{
                                            "& .MuiInputLabel-root ": {
                                                color: isRequired ? "#D32F2F" : "#25402B"
                                            }
                                        }}
                                        variant="standard"
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                        {option.label}
                                    </li>
                                )}
                                value={fieldValue ?? value}
                            />
                        </FormControl>
                    );
                }}
                rules={{ required: isRequired }}
            />
        );
    }
);

AutocompleteField.displayName = "AutocompleteField";

export default AutocompleteField;
