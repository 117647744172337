import { useEffect, useState } from "react";

import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

import { getChainedCategoryLabels } from "helpers/criteria/getChainedCategoryLabels";
import { handlePhotosSorting } from "helpers/photos/handlePhotosSorting";

import productCardStyle from "./ProductCard.module.css";

const ProductCard = ({ product }: any): JSX.Element => {
    // use localHost for local build
    const photosBaseUrl: string = import.meta.env.VITE_APP_LOCAL_BASE_URL ?? "";

    // Get values from product
    const photoUrl = product.photos?.length ? handlePhotosSorting(product.photos)[0].photo_url : "";
    const productItemQr = product.item_qr;
    const productBrand = product.brand ? product.brand.label : undefined;

    const [productCategory, setProductCategory] = useState<string>("Catégorie non renseignée");

    useEffect(() => {
        // Get displayed category
        product.category?.parent &&
            setProductCategory(getChainedCategoryLabels(product.category, 2));
    }, []);

    return (
        <div className={productCardStyle.productCard__container}>
            <div className={productCardStyle.productCard__photo}>
                {photoUrl ? (
                    <img alt={productCategory} src={`${photosBaseUrl}${photoUrl}`} />
                ) : (
                    <ImageNotSupportedIcon color="secondary" sx={{ fontSize: 80 }} />
                )}
            </div>
            <div className={productCardStyle.productCard__text}>
                <h3>{productItemQr}</h3>
                <p>{productCategory}</p>
                {productBrand && <p>Marque : {productBrand}</p>}
            </div>
        </div>
    );
};

export default ProductCard;
