import { Typography } from "@mui/material";

interface Props {
    text?: string;
}

const ScreenTitle = ({ text }: Props): JSX.Element => (
    <Typography component="h1" variant="h5">
        {text}
    </Typography>
);

export default ScreenTitle;
