import { useNavigate, useParams } from "react-router-dom";

import { handlePickingSessionCreation } from "helpers/picking/handlePickingSessionCreation";
import { handlePickedProductCreation } from "helpers/picking/handlePickedProductCreation";
import { handlePickedProductUpdate } from "helpers/picking/handlePickedProductUpdate";

import { Box, IconButton, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectPicking } from "store/pickingSlice";
import { PICKED_PRODUCTS_PARAM, PRODUCTS_TO_PICK_PARAM } from "constants/operationsRules";

interface Props {
    pickedProductData: any;
}

const ScanSuccessButtonsGroup = ({ pickedProductData }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { picking_rail_id } = useParams();
    const { picked_product_id } = useParams();
    const { product_picking_status } = useParams();

    const productToPick = useAppSelector(selectPicking).productsToPick[0];
    const pickedProducts = useAppSelector(selectPicking).pickedProducts;
    let pickingSessionId = useAppSelector(selectPicking).id;

    // Next button Onclick: handle PickedProduct call :
    // - Create for product to pick
    // - Update for not found product
    // When it's first picked product and no picking session, it creates new picking session
    async function handleProductPicking(): Promise<void> {
        if (!pickedProducts.length && !pickingSessionId && picking_rail_id) {
            pickingSessionId = await handlePickingSessionCreation(dispatch, picking_rail_id);
        }

        if (pickingSessionId) {
            if (product_picking_status === PRODUCTS_TO_PICK_PARAM) {
                await handlePickedProductCreation(
                    dispatch,
                    pickedProductData,
                    pickingSessionId,
                    productToPick
                );
                navigate(-1);
            } else if (product_picking_status === PICKED_PRODUCTS_PARAM && picked_product_id) {
                await handlePickedProductUpdate(
                    dispatch,
                    pickedProductData,
                    picked_product_id,
                    pickingSessionId
                );
                navigate(`/warehouse/pickingrails/${String(picking_rail_id)}/overview`);
            }
        }
    }

    return (
        <Box
            sx={{
                alignItems: "flex-end",
                display: "flex",
                justifyContent: "center",
                width: "100%"
            }}
        >
            <Box
                sx={{
                    alignItems: "center",
                    backgroundColor: "#989E8F",
                    borderRadius: "50%",
                    display: "flex",
                    flexDirection: "column",
                    height: "60px",
                    justifyContent: "center",
                    padding: 3,
                    width: "60px"
                }}
            >
                <Typography sx={{ fontWeight: "bold" }}>Rondelle</Typography>
                {productToPick?.order_picking_letter ? (
                    <Typography mt={-1} variant="h3">
                        {productToPick.order_picking_letter.split(".")[2]}
                    </Typography>
                ) : (
                    <></>
                )}
            </Box>
            <IconButton
                aria-label="Next product"
                color="primary"
                onClick={handleProductPicking}
                size="large"
                sx={{ position: "absolute", right: 20 }}
            >
                <EastIcon sx={{ fontSize: "45px" }} />
            </IconButton>
        </Box>
    );
};

export default ScanSuccessButtonsGroup;
