import { useNavigate, useParams } from "react-router-dom";

import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PickedProductCard from "./PickedProductCard";

import type { PickedProduct } from "types/picking";
import { PICKED_PRODUCTS_PARAM } from "constants/operationsRules";

interface Props {
    defaultExpanded?: boolean;
    disabled?: boolean;
    products?: PickedProduct[];
    title: string;
}

const PickedAccordion = ({ defaultExpanded, disabled, products, title }: Props): JSX.Element => {
    const navigate = useNavigate();

    const { picking_rail_id } = useParams();

    return (
        <Accordion defaultExpanded={defaultExpanded} disabled={disabled} sx={{ width: "90%" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ fontWeight: "bold" }} variant="h5">
                    {`${title} : ${String(products?.length)}`}
                </Typography>
            </AccordionSummary>

            {products && (
                <AccordionDetails sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {products?.map((pickedProduct, i) => {
                        return (
                            <Button
                                key={i}
                                onClick={() => {
                                    if (title === "Introuvables") {
                                        navigate(
                                            `/warehouse/pickingrails/${String(
                                                picking_rail_id
                                            )}/${PICKED_PRODUCTS_PARAM}/${String(pickedProduct.id)}`
                                        );
                                    }
                                }}
                                sx={{ width: "calc((100% - 24px) / 4)" }}
                            >
                                <PickedProductCard pickedProduct={pickedProduct} />
                            </Button>
                        );
                    })}
                </AccordionDetails>
            )}
        </Accordion>
    );
};

export default PickedAccordion;
