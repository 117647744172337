import type { PickedProduct, OrderedProduct, RetrievedProduct } from "types/picking";

function handlePickedTargetProduct(
    pickedProduct: PickedProduct
): OrderedProduct | RetrievedProduct {
    const targetProduct = pickedProduct.is_retrieval
        ? pickedProduct.retrieved_product
        : pickedProduct.ordered_product;

    return targetProduct as OrderedProduct | RetrievedProduct;
}
export default handlePickedTargetProduct;
