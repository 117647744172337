import { axiosClient } from "helpers/axios";
import type { User } from "types/user";

interface Token {
    acess?: string;
    refresh?: string;
}
interface AuthenticationInfos {
    token?: Token;
    user: User;
}

async function authenticateUserAndGetToken(data: any): Promise<AuthenticationInfos> {
    const result = await axiosClient().post("users/login/", data);

    return result.data;
}

async function verifyTokenAndFetchUserInfo(token: string): Promise<AuthenticationInfos> {
    const result = await axiosClient().post("users/me/", { token });

    return result.data;
}

export { authenticateUserAndGetToken, verifyTokenAndFetchUserInfo };
