import { axiosClient } from "helpers/axios";

async function fetchAllStoringRailsLocation(): Promise<any> {
    const result = await axiosClient().get(`locations/?set=storing_rail`);

    return result.data;
}

async function fetchLocationFromBarcode(barcode: string): Promise<any> {
    const result = await axiosClient().get(`locations/${barcode}/?is_from_storing_tool=true`);

    return result.data;
}

async function patchProductsLocation(id: number, data: any): Promise<any> {
    const result = await axiosClient().patch(`locations/${id}/products/`, data);

    return result.data;
}

export { fetchAllStoringRailsLocation, fetchLocationFromBarcode, patchProductsLocation };
