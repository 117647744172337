import ChoiceButton from "components/buttons/ChoiceButton";
import { Box, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";

interface Props {
    isLocationErrorDialogOpen: boolean;
    isScannedProductToProcess: boolean;
    setIsLocationErrorDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsScannedProductToProcess: React.Dispatch<React.SetStateAction<boolean>>;
    setProductInputValue: React.Dispatch<React.SetStateAction<string>>;
}

const LocationErrorDialog = ({
    isLocationErrorDialogOpen,
    isScannedProductToProcess,
    setIsLocationErrorDialogOpen,
    setIsScannedProductToProcess,
    setProductInputValue
}: Props): JSX.Element => {
    const handleClose = (): void => {
        setIsLocationErrorDialogOpen(false);
        setProductInputValue("");
        isScannedProductToProcess && setIsScannedProductToProcess(false);
    };

    return (
        <Dialog
            aria-labelledby="location-error-dialog"
            fullWidth
            onClose={handleClose}
            open={isLocationErrorDialogOpen}
        >
            <DialogTitle
                bgcolor="secondary"
                color="error"
                id="location-error-dialog"
                sx={{
                    backgroundColor: "#94011a",
                    border: "solid #94011a 1px",
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center"
                }}
            >
                {isScannedProductToProcess ? "⚠️ Fiche produit inachevée" : "⚠️ Attention !"}
            </DialogTitle>
            <DialogContent
                sx={{
                    backgroundColor: "#94011a",
                    border: "solid #94011a 1px",
                    color: "white",
                    textAlign: "center"
                }}
            >
                <>
                    <Box m={3}>
                        {isScannedProductToProcess ? (
                            <>
                                <Typography variant="h6">Ne peut pas être rangé</Typography>
                                <Typography variant="h5">Processing à finaliser</Typography>
                            </>
                        ) : (
                            <Typography variant="h6">Max de produits à ranger atteint</Typography>
                        )}
                    </Box>
                    <ChoiceButton
                        onClick={() => {
                            handleClose();
                        }}
                        sx={{ backgroundColor: "lightgrey", color: "primary.main" }}
                        text={"OK"}
                        variant="contained"
                    />
                </>
            </DialogContent>
        </Dialog>
    );
};

export default LocationErrorDialog;
