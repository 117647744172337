import { useEffect, useRef, useState } from "react";

import { fetchAttractivitytags } from "services/criterias";
import { handleCallErrorWithLoader } from "services/exceptions";
import { useSpeechRecognition } from "react-speech-recognition";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FormField from "components/formFields/FormField";
import RatingScale from "components/formFields/RatingScale";

import { selectProduct } from "store/productSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import type { Control } from "react-hook-form";
import type { RatedCriteria } from "types/product";

interface Props {
    control: Control;
    isRequired?: boolean;
}

const numberMap: Record<string, number> = {
    un: 1,
    "1": 1,
    deux: 2,
    de: 2,
    "2": 2,
    trois: 3,
    "3": 3
};

const AttractivityTagRatingScale = ({ control, isRequired }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const product = useAppSelector(selectProduct);

    const [attractivitytagsList, setAttractivitytagsList] = useState<RatedCriteria[]>([]);
    const { resetTranscript } = useSpeechRecognition();
    const [currentAttractivity] = useState(product.attractivity_tag);
    const attractivityRef = useRef<any>(null);

    useEffect(() => {
        handleCallErrorWithLoader(dispatch, fetchAttractivitytags).then((result) => {
            setAttractivitytagsList(result);
        });
    }, []);

    const commands = [
        {
            command: /attractivités? (un|1|deux|de|2|trois|3)/i,
            callback: (attractivity: string) => {
                const attractivityId = numberMap[attractivity];
                const attractivityItem = attractivitytagsList.find(
                    (item) => item.id === attractivityId
                );

                attractivityRef.current.setValue(attractivityItem);

                resetTranscript();
            }
        }
    ];

    useSpeechRecognition({ commands });

    return (
        <FormField>
            <RatingScale
                ref={attractivityRef}
                control={control}
                defaultColor="#ff6d75"
                emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                existantCriteria={currentAttractivity}
                icon={<FavoriteIcon fontSize="inherit" />}
                isRequired={isRequired}
                label={"Attractivité 🎤: Attractivité XX (1,2 ou 3)"}
                name="attractivity_tag"
                optionsList={attractivitytagsList}
            />
        </FormField>
    );
};

export default AttractivityTagRatingScale;
