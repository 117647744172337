import axios, { type AxiosInstance } from "axios";

const baseURL = import.meta.env.VITE_APP_API_URL;

const axiosClient = (): AxiosInstance => {
    const token = localStorage.getItem("access-token");
    return axios.create({
        baseURL,
        headers: token
            ? {
                  Authorization: `Bearer ${token}`
              }
            : undefined,
        timeout: 60000, // 60 secondes
        transitional: { clarifyTimeoutError: true }
    });
};

export { axiosClient };
