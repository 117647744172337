import { type RootState } from ".";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type ErrorState } from "types/main";

const initialState: ErrorState = {
    message: undefined
};

export const errorSlice = createSlice({
    name: "error",
    initialState,
    reducers: {
        setErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.message = action.payload;
            return state;
        },
        clearErrorMessage: (state: ErrorState) => {
            state = initialState;
            return state;
        }
    }
});

export const { setErrorMessage, clearErrorMessage } = errorSlice.actions;
export const selectError = (state: RootState): ErrorState => state.error;

export default errorSlice.reducer;
