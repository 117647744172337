import { Box } from "@mui/material";

interface Props {
    children?: JSX.Element | JSX.Element[];
}

const ControlButtonsContainer = ({ children }: Props): JSX.Element => (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>{children}</Box>
);

export default ControlButtonsContainer;
