import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from ".";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { User } from "types/user";

interface AuthenticationInfos extends User {
    currentGroup?: string;
    isLoggedIn?: boolean;
}
const initialState: AuthenticationInfos = {
    currentGroup: undefined,
    groups: [],
    isLoggedIn: undefined,
    is_active: undefined,
    is_staff: undefined,
    is_superuser: undefined,
    last_login: undefined,
    username: undefined
};

export const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        clearAuthenticationInfos: (state: AuthenticationInfos) => {
            state = { ...initialState, isLoggedIn: false };
            return state;
        },
        setAuthenticationInfos: (state: AuthenticationInfos, action: PayloadAction<User>) => {
            state = { ...action.payload, isLoggedIn: true };
            return state;
        },
        setCurrentGroup: (state: AuthenticationInfos, action: PayloadAction<string>) => {
            state.currentGroup = action.payload;
            localStorage.setItem("currentGroup", action.payload);
            return state;
        }
    }
});

export const { clearAuthenticationInfos, setAuthenticationInfos, setCurrentGroup } =
    authenticationSlice.actions;
export const selectAuthentication = (state: RootState): AuthenticationInfos => state.authentication;

export default authenticationSlice.reducer;
