import { Box, Typography } from "@mui/material";

import type { ProductState } from "types/product";

interface Props {
    expectedProduct: ProductState;
}

const ExpectedProductCard = ({ expectedProduct }: Props): JSX.Element => {
    // use localHost for local build
    const photosBaseUrl: string = import.meta.env.VITE_APP_LOCAL_BASE_URL ?? "";

    return (
        <Box sx={{ alignItems: "center", display: "flex", gap: 2, height: "15vh" }}>
            {expectedProduct.photos?.length && (
                <img
                    alt={expectedProduct.item_qr}
                    height="100%"
                    src={`${photosBaseUrl}${String(expectedProduct.photos[0].photo_url)}`}
                    width="auto"
                />
            )}
            <Box>
                <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                    {expectedProduct.item_qr?.slice(0, -4)}
                    <Box component="span" sx={{ color: "#d32f2f" }}>
                        {expectedProduct?.item_qr?.slice(-4)}
                    </Box>
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>{expectedProduct.brand?.label}</Typography>
                <Typography sx={{ marginTop: "-4px" }}>
                    {expectedProduct.category?.label} - {expectedProduct.size?.label}
                </Typography>
                <Typography sx={{ marginTop: "-4px" }}>{expectedProduct.color?.label}</Typography>
            </Box>
        </Box>
    );
};

export default ExpectedProductCard;
