import { Box, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import ChoiceButton from "components/buttons/ChoiceButton";
import UndoButton from "components/buttons/UndoButton";

import type { ProductState } from "types/product";

interface Props {
    isProdcutCancelDialogOpen: boolean;
    item: ProductState;
    scannedProducts: ProductState[];
    setIsProdcutCancelDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setScannedProducts: React.Dispatch<React.SetStateAction<ProductState[]>>;
}

const ProductCancelDialog = ({
    isProdcutCancelDialogOpen,
    item,
    scannedProducts,
    setIsProdcutCancelDialogOpen,
    setScannedProducts
}: Props): JSX.Element => {
    // use localHost for local build
    const photosBaseUrl: string = import.meta.env.VITE_APP_LOCAL_BASE_URL ?? "";

    const handleClose = (): void => {
        setIsProdcutCancelDialogOpen(false);
    };

    // On dialog confirm : delete targeted product from scanned products list
    const handleProductCancel = (): void => {
        const updatedScannedProducts = scannedProducts.filter((product) => product !== item);

        setScannedProducts(updatedScannedProducts);
        localStorage.setItem("scannedProducts", JSON.stringify(updatedScannedProducts));

        handleClose();
    };

    return (
        <Dialog
            aria-labelledby="product-cancel-dialog"
            fullWidth
            onClose={handleClose}
            open={isProdcutCancelDialogOpen}
        >
            <DialogTitle
                bgcolor="secondary"
                color="error"
                id="product-cancel-dialog"
                sx={{
                    fontWeight: "bold",
                    textAlign: "center"
                }}
            >
                Supprimer le produit de la liste à ranger :
            </DialogTitle>
            <DialogContent sx={{ textAlign: "center" }}>
                <img
                    alt={item.item_qr}
                    height="auto"
                    src={`${photosBaseUrl}${String(item.photos?.[0]?.photo_url)}`}
                    width="80%"
                />
                <Typography
                    sx={{
                        fontSize: "12px",
                        fontWeight: "bold"
                    }}
                >
                    {item.item_qr}
                </Typography>
                <Typography
                    color={item.brand?.label ? "primary" : "error"}
                    sx={{
                        fontSize: "12px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}
                >
                    {item.brand?.label ?? "Sans marque"}
                </Typography>
                <Box mt={2} sx={{ display: "flex", justifyContent: "space-between" }}>
                    <UndoButton handleUndo={handleClose} size="large" />
                    <ChoiceButton
                        color="secondary"
                        onClick={() => {
                            handleProductCancel();
                        }}
                        text={"Confirmer"}
                        variant="contained"
                    />
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ProductCancelDialog;
