import { useState, useEffect } from "react";
import { InputAdornment, TextField } from "@mui/material";
import FormField from "components/formFields/FormField";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { useSpeechRecognition } from "react-speech-recognition";
import { selectPrice, setPriceToPost } from "store/priceSlice";
import { selectProduct } from "store/productSlice";

const BrandNewPriceTextField = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const product = useAppSelector(selectProduct);
    const price = useAppSelector(selectPrice);

    const [textFieldValue, setTextFieldValue] = useState<string>("");

    useEffect(() => {
        let initialValue = product.prices?.filter((p) => p.status === "BRAND_NEW").length
            ? product.prices.filter((p) => p.status === "BRAND_NEW")[0].value?.toString()
            : price.value?.toString();

        if (isNaN(Number(initialValue))) {
            initialValue = "";
        }

        setTextFieldValue(initialValue || "");
    }, [product, price]);

    /**
     * Price store saving
     */
    function handlePriceChange(data: string): void {
        setTextFieldValue(data);
        dispatch(
            setPriceToPost({
                product: product.sku,
                status: "LABEL",
                value: parseInt(data)
            })
        );
    }

    const commands = [
        {
            command: /prix\s?(\d+)/i,
            callback: (value: string) => {
                handlePriceChange(value);
            }
        }
    ];

    useSpeechRecognition({ commands });

    return (
        <FormField>
            <TextField
                color="primary"
                InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                }}
                label="Prix étiquette 🎤: Prix XX"
                onChange={(e) => {
                    handlePriceChange(e.target.value);
                }}
                sx={{ width: "100%" }}
                value={textFieldValue}
            />
        </FormField>
    );
};

export default BrandNewPriceTextField;
