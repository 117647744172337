import { type UsualValue } from "types/main";

// CSS gradient values for each color
// Used in each toggle button - Default value : white #000000
export const colorsValues: UsualValue[] = [
    {
        label: "Argenté",
        value: "#70757f, #dee3eb, #70757f"
    },
    {
        label: "Beige",
        value: "#ecc8a4, #ecc8a4"
    },
    {
        label: "Blanc",
        value: "#eeeeee, #eeeeee"
    },
    {
        label: "Bleu",
        value: "#3968e8, #3968e8"
    },
    {
        label: "Bleu clair",
        value: "#77bdf2, #77bdf2"
    },
    {
        label: "Bordeaux",
        value: "#a70000, #a70000"
    },
    {
        label: "Doré",
        value: "#a67c00, #e5c566, #a67c00"
    },
    {
        label: "Gris",
        value: "#999999, #999999"
    },
    {
        label: "Jaune",
        value: "#faeb36, #faeb36"
    },
    {
        label: "Kaki",
        value: "#798241,#798241"
    },
    {
        label: "Marron",
        value: "#a0522d, #a0522d"
    },
    {
        label: "Mauve",
        value: "#d791ff, #d791ff"
    },
    {
        label: "Multicolore",
        value: "#f728c7,#e81416,#faeb36,#79c314,#487de7,#70369d"
    },
    {
        label: "Noir",
        value: "#000000, #000000"
    },
    {
        label: "Orange",
        value: "#ff8900,#ff8900"
    },
    {
        label: "Rose",
        value: "#f728c7, #f728c7"
    },
    {
        label: "Rouge",
        value: "#e81416, #e81416"
    },
    {
        label: "Vert",
        value: "#79c314, #79c314"
    },
    {
        label: "Violet",
        value: "#70369d, #70369d"
    }
];
