import { type UsualValue } from "types/main";

export const refusalReasonsValues: UsualValue[] = [
    {
        label: "Produit taché / abîmé",
        value: "Produit taché / abîmé"
    },
    {
        label: "Marque refusée",
        value: "Marque refusée"
    },
    {
        label: "Sans marque",
        value: "Sans marque"
    },
    {
        label: "Catégorie refusée",
        value: "Catégorie refusée"
    }
];
