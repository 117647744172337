import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import QrScanner from "components/qrScanner/QrScanner";
import ScanErrorButtonsGroup from "./sections/ScanErrorButtonsGroup";
import ScanSuccessButtonsGroup from "./sections/ScanSuccessButtonsGroup";
import ExpectedProductCard from "./sections/ExpectedProductCard";
import handlePickedTargetProduct from "../../../../helpers/picking/handlePickedTargetProduct";

import { useAppSelector } from "store/hooks";
import { selectPicking } from "store/pickingSlice";

import type { OrderedProduct, PickedProduct } from "types/picking";
import { PICKED_PRODUCTS_PARAM, PRODUCTS_TO_PICK_PARAM } from "constants/operationsRules";

const ScanProductQr = (): JSX.Element => {
    const { picked_product_id } = useParams();
    const { product_picking_status } = useParams();

    const notFoundProducts = useAppSelector(selectPicking).notFoundProducts;
    const productToPick = useAppSelector(selectPicking).productsToPick[0];
    const pickingSessionId = useAppSelector(selectPicking).id;

    const [expectedProduct, setExpectedProduct] = useState<OrderedProduct>();
    const [isExpectedQrScanned, setIsExpectedQrScanned] = useState<boolean | undefined>(undefined);
    const [isProductPicked, setIsProductPicked] = useState<boolean>(false);
    const [pickedProductData, setPickedProductData] = useState<any>();
    const [scannedQr, setScannedQr] = useState<string>();

    // On render : set expected product state with productToPick or notFoundProduct
    useEffect(() => {
        if (product_picking_status === PRODUCTS_TO_PICK_PARAM) {
            setExpectedProduct(productToPick);
        } else if (product_picking_status === PICKED_PRODUCTS_PARAM) {
            const notFoundProduct = notFoundProducts.find((notfoundProduct) => {
                return String(notfoundProduct.id) === picked_product_id;
            });
            const targetProduct = handlePickedTargetProduct(notFoundProduct as PickedProduct);

            setExpectedProduct(targetProduct);
        }
    }, []);

    // When QR is scanned, it is compared with expected one
    useEffect(() => {
        if (scannedQr) {
            if (product_picking_status === PRODUCTS_TO_PICK_PARAM) {
                setIsExpectedQrScanned(scannedQr === productToPick.picking_rail_product.item_qr);
            } else if (product_picking_status === PICKED_PRODUCTS_PARAM) {
                const notFoundProduct = notFoundProducts.find((notfoundProduct) => {
                    return String(notfoundProduct.id) === picked_product_id;
                });
                const isRetrieval = Boolean(productToPick?.retrieval);
                const product = isRetrieval
                    ? notFoundProduct?.retrieved_product
                    : notFoundProduct?.ordered_product;

                setIsExpectedQrScanned(scannedQr === product?.picking_rail_product?.item_qr);
            }
        }
    }, [scannedQr]);

    // When scanned QR is expected one, set success data for PickingProduct call
    useEffect(() => {
        if (isExpectedQrScanned) {
            const isRetrieval = Boolean(productToPick?.retrieval);

            setPickedProductData({
                has_wrong_item_qr: false,
                is_picked: true,
                is_not_found: false,
                picking_session: pickingSessionId,
                is_retrieval: isRetrieval,
                ...(product_picking_status === PRODUCTS_TO_PICK_PARAM
                    ? isRetrieval
                        ? { retrieved_product_id: productToPick.id }
                        : { ordered_product_id: productToPick.id }
                    : {})
            });
        }
    }, [isExpectedQrScanned]);

    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginTop: "-1rem",
                height: "calc(90vh - 72px)"
            }}
        >
            {isExpectedQrScanned === true || isProductPicked ? (
                <Box></Box>
            ) : (
                <QrScanner setScannedQr={setScannedQr} />
            )}

            {isExpectedQrScanned === undefined && !isProductPicked && (
                <Box>
                    <CircularProgress color="warning" size={30} />
                    <Typography>Scan en cours</Typography>
                </Box>
            )}

            {isExpectedQrScanned === false && !isProductPicked && expectedProduct && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography color="error" variant="h4">
                        Mauvais QR
                    </Typography>
                    <Typography sx={{ fontWeight: "bold", marginBottom: 2 }}>
                        Rappel du produit attendu :
                    </Typography>
                    <ExpectedProductCard expectedProduct={expectedProduct.picking_rail_product} />
                </Box>
            )}

            {isExpectedQrScanned === true || isProductPicked ? (
                <>
                    <Box>
                        <Typography variant="h5">✅ OK</Typography>
                        <Typography variant="h6">A déposer sur le rail :</Typography>
                    </Box>
                    <ScanSuccessButtonsGroup pickedProductData={pickedProductData} />
                </>
            ) : (
                <ScanErrorButtonsGroup
                    setIsProductPicked={setIsProductPicked}
                    setPickedProductData={setPickedProductData}
                />
            )}
        </Box>
    );
};

export default ScanProductQr;
