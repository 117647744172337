import { type RootState } from ".";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { PickingSlice, OrderedProduct, PickedProduct } from "types/picking";

const initialState: PickingSlice = {
    id: undefined,
    notFoundProducts: [],
    pickedProducts: [],
    productsToPick: []
};

export const pickingSlice = createSlice({
    name: "picking",
    initialState,
    reducers: {
        addPickedProducts: (state: PickingSlice, action: PayloadAction<PickedProduct>) => {
            state.pickedProducts.length
                ? (state.pickedProducts = [action.payload, ...state.pickedProducts])
                : (state.pickedProducts = [action.payload]);
            return state;
        },
        addNotFoundProducts: (state: PickingSlice, action: PayloadAction<PickedProduct>) => {
            state.notFoundProducts
                ? (state.notFoundProducts = [action.payload, ...state.notFoundProducts])
                : (state.notFoundProducts = [action.payload]);
            return state;
        },
        clearPickingSession: (state: PickingSlice) => {
            state = initialState;
            return state;
        },
        removeNotFoundProduct: (state: PickingSlice, action: PayloadAction<number>) => {
            if (
                state.notFoundProducts.some((el) => {
                    return el.id === action.payload;
                })
            ) {
                state.notFoundProducts = state.notFoundProducts.filter((el) => {
                    return el.id !== action.payload;
                });
            }
            return state;
        },
        removeProductToPick: (state: PickingSlice, action: PayloadAction<number>) => {
            if (
                state.productsToPick.some((el) => {
                    return el.id === action.payload;
                })
            ) {
                state.productsToPick = state.productsToPick.filter((el) => {
                    return el.id !== action.payload;
                });
            }
            return state;
        },
        setPickingSessionId: (state: PickingSlice, action: PayloadAction<string>) => {
            state.id = action.payload;
            return state;
        },
        setProductsToPick: (state: PickingSlice, action: PayloadAction<OrderedProduct[]>) => {
            state.productsToPick = action.payload;
            return state;
        }
    }
});

export const {
    addPickedProducts,
    addNotFoundProducts,
    clearPickingSession,
    removeNotFoundProduct,
    removeProductToPick,
    setPickingSessionId,
    setProductsToPick
} = pickingSlice.actions;
export const selectPicking = (state: RootState): PickingSlice => state.picking;

export default pickingSlice.reducer;
