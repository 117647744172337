import { useParams } from "react-router-dom";

import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { Box, Typography } from "@mui/material";
import PickingCartFooter from "./sections/Footer";
import PickedAccordion from "./sections/ProductsAccordion";

import { useAppSelector } from "store/hooks";
import { selectPicking } from "store/pickingSlice";

import type { PickingSlice } from "types/picking";

const PickingCart = (): JSX.Element => {
    const { picking_rail_id } = useParams();

    const productsToPick: PickingSlice = useAppSelector(selectPicking);

    return (
        <Box
            py={2}
            sx={{
                alignItems: "center",
                backgroundColor: "#cfcfcf",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                minHeight: "80vh",
                justifyContent: "flex-start",
                marginTop: "-32px",
                marginLeft: "-16px",
                paddingBottom: "100px",
                width: "100vw"
            }}
        >
            <Box>
                <Typography component="h1" variant="h5">
                    <ShoppingCartCheckoutIcon sx={{ marginBottom: "-4px", marginRight: "4px" }} />
                    Aperçu du picking rail{" "}
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                        {picking_rail_id}
                    </Box>
                </Typography>

                {productsToPick.productsToPick.length > 0 && (
                    <Typography color="error" sx={{ fontWeight: "bold" }}>
                        {`Item restants : ${String(productsToPick.productsToPick.length)}`}
                    </Typography>
                )}
            </Box>

            <PickedAccordion
                disabled={!productsToPick.notFoundProducts.length}
                products={productsToPick.notFoundProducts}
                title="Introuvables"
            />

            <PickedAccordion
                defaultExpanded={!!productsToPick.pickedProducts.length}
                disabled={!productsToPick.pickedProducts.length}
                products={productsToPick.pickedProducts}
                title="Item picked"
            />

            <PickingCartFooter />
        </Box>
    );
};

export default PickingCart;
