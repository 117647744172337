import type { Dispatch } from "redux";
import type { NavigateFunction } from "react-router-dom";

import { clearAuthenticationInfos, setCurrentGroup } from "store/authenticationSlice";
import { setErrorMessage } from "store/errorSlice";

import { STUDIO_USER_GROUP, WAREHOUSE_USER_GROUP } from "constants/usersRules";

// Redirection based on User Group
// Used after login and each token verifications
export const handleGroupRedirect = (
    groups: string[] | undefined,
    dispatch: Dispatch,
    navigate: NavigateFunction,
    event?: string
): void => {
    if (groups) {
        if (groups.length > 1) {
            event === "Login" && navigate("/switch/");
        } else {
            dispatch(setCurrentGroup(groups[0]));

            if (groups[0] === STUDIO_USER_GROUP) {
                navigate("/studio");
            } else if (groups[0] === WAREHOUSE_USER_GROUP) {
                navigate("/warehouse");
            }
        }
    } else {
        dispatch(setErrorMessage("Pas de groupe trouvé pour ce user"));
        dispatch(clearAuthenticationInfos());
    }
};
