import { axiosClient } from "helpers/axios";
import { type ProductPhotoToPost, type ProductPriceToPost } from "types/product";

async function deleteProductPhoto(id: string): Promise<any> {
    const result = await axiosClient().delete(`photos/${id}/`);

    return result.data;
}

async function fetchFirstProductFromItemQr(item_qr: string): Promise<any> {
    const result = await axiosClient().get(`item_qr/${item_qr}/products`);

    return result.data[0];
}

async function fetchProductFromItemQr(item_qr: string): Promise<any> {
    const result = await axiosClient().get(`products/?item_qr=${item_qr}`);

    return result.data[0];
}

async function fetchProductsToControl(
    control: string,
    limit?: number,
    subcontrol_id?: number
): Promise<any> {
    const result = await axiosClient().get(`products/?control=${control}`, {
        params: {
            ...(limit ? { limit } : {}),
            ...(subcontrol_id ? { subcontrol_id } : {})
        }
    });

    return result.data;
}

async function fetchProductsToPrice(isDesigner: boolean): Promise<any> {
    const result = await axiosClient().get("products/priceless/", {
        params: { ...(isDesigner ? { is_designer: "True" } : {}) }
    });

    return result.data;
}

async function patchProductInfos(data: any, sku: string): Promise<any> {
    const result = await axiosClient().patch(`products/${sku}/`, data);

    return result.data;
}

async function postPrice(data: ProductPriceToPost): Promise<any> {
    const result = await axiosClient().post("prices/", data);

    return result.status;
}

async function postProductInfos(data: any): Promise<any> {
    const result = await axiosClient().post("products/", data);

    return result.data;
}

async function putProductPhotos(photos: ProductPhotoToPost, sku: string): Promise<any> {
    const result = await axiosClient().put(`products/${sku}/upload/`, photos);

    return result.data;
}

export {
    fetchFirstProductFromItemQr,
    deleteProductPhoto,
    fetchProductFromItemQr,
    fetchProductsToControl,
    fetchProductsToPrice,
    patchProductInfos,
    postPrice,
    postProductInfos,
    putProductPhotos
};
