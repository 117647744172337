import { useState } from "react";

import { handleCallError } from "services/exceptions";
import { patchProductInfos } from "services/products";

import { Box, TextField } from "@mui/material";
import ChoiceButton from "components/buttons/ChoiceButton";
// eslint-disable-next-line max-len
import SizeAutocompleteField from "pages/studio/product/ProductForm/sections/autocompleteFields/Size";

import { useAppDispatch } from "store/hooks";

import type { Control, FieldValues, UseFormSetValue } from "react-hook-form";
import type { Criteria, ProductState } from "types/product";

interface Props {
    control: Control;
    createControl: () => Promise<void>;
    isRowDisabled: boolean;
    product: ProductState;
    controlledProducts: string[];
    setIsRowLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setValue: UseFormSetValue<FieldValues>;
}

const SizeActions = ({
    control,
    createControl,
    isRowDisabled,
    product,
    setIsRowLoading,
    setValue
}: Props): JSX.Element => {
    const dispatch = useAppDispatch();

    const [selectedSize, setSelectedSize] = useState<Criteria>();
    const [publicNotes, setPublicNotes] = useState<string>();

    // Patch product (works for size & public_notes)
    async function updateProduct(): Promise<void> {
        setIsRowLoading(true);

        let dataToSend: { size: Criteria; public_notes?: string } | undefined;

        if (selectedSize) {
            dataToSend = { size: selectedSize };

            if (publicNotes) {
                dataToSend.public_notes = publicNotes;
            }

            const result = await handleCallError(
                dispatch,
                patchProductInfos,
                dataToSend,
                product.sku
            );

            result && createControl();
        }

        setIsRowLoading(false);
    }

    return (
        <Box sx={{ alignItems: "center", display: "flex", flexDirection: "column", gap: 2 }}>
            <SizeAutocompleteField
                categoryToPost={product.category}
                control={control}
                disabled={isRowDisabled}
                optionalSetSelectedSize={setSelectedSize}
                setValue={setValue}
            />
            <TextField
                defaultValue={product.public_notes ?? ""}
                disabled={isRowDisabled}
                fullWidth
                label="Notes publiques"
                multiline={true}
                onChange={(e) => {
                    setPublicNotes(e.target.value);
                }}
                variant="outlined"
            />
            <ChoiceButton
                color="primary"
                disabled={!selectedSize || isRowDisabled}
                onClick={() => {
                    updateProduct();
                }}
                sx={{ margin: "0 auto" }}
                text="Valider"
                variant="contained"
            />
        </Box>
    );
};

export default SizeActions;
