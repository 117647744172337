import { Typography } from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";

import type { PickingRail } from "types/picking";

interface Props {
    pickingRail: PickingRail;
}

const ActiveSessionInfos = ({ pickingRail }: Props): JSX.Element => {
    // Get the creation time from the picking session created_at field
    function getSessionCreatingTime(creationDate: string): string {
        const GMTdate = new Date(creationDate);
        const parisTime = GMTdate.toLocaleTimeString("default", {
            timeZone: "Europe/Paris",
            hour: "2-digit",
            minute: "2-digit"
        });

        const timeDelta = Math.round((GMTdate.getTime() - Date.now()) / (1000 * 3600 * 24));
        const relativeDayFormater = new Intl.RelativeTimeFormat("fr", { numeric: "auto" });
        const relativeDay = relativeDayFormater.format(timeDelta, "day");

        return `${relativeDay} à ${parisTime}`;
    }

    return (
        <>
            <UpdateIcon
                sx={{
                    position: "absolute",
                    top: "5px",
                    right: "5px"
                }}
            />
            <Typography
                sx={{
                    fontSize: "14px",
                    marginTop: "-6px",
                    textTransform: "none"
                }}
            >
                {pickingRail.active_picking_session[0].picked_by}
                {" - "}
                {getSessionCreatingTime(pickingRail.active_picking_session[0].created_at)}
            </Typography>
        </>
    );
};

export default ActiveSessionInfos;
