import { Controller } from "react-hook-form";
import { Box, InputAdornment, TextField } from "@mui/material";
import { useAppSelector } from "store/hooks";
import { selectLoader } from "store/loaderSlice";
import theme from "theme";
import { type ScanInputFieldParams } from "types/main";
import formStyle from "./formField.module.css";

const ScanInputField = ({
    control,
    autoFocus,
    disabled,
    endAdornment,
    existantValue,
    inputValue,
    isSpecCharReplaceNeeded,
    name,
    setInputValue,
    type
}: ScanInputFieldParams): JSX.Element => {
    const loader = useAppSelector(selectLoader);

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, ref } }) => (
                <Box m={theme.spacing(2)}>
                    <TextField
                        ref={ref}
                        autoFocus={autoFocus}
                        className={formStyle.ScanInputField}
                        defaultValue={existantValue}
                        disabled={disabled ?? loader.isLoading}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">{endAdornment}</InputAdornment>
                            )
                        }}
                        label={name}
                        onChange={
                            setInputValue != null
                                ? (e) => {
                                      const value = e.target.value;

                                      setInputValue(
                                          isSpecCharReplaceNeeded
                                              ? value.replace(/[$§]/g, "-")
                                              : value
                                      );
                                  }
                                : onChange
                        }
                        placeholder={"Commencer à taper ici"}
                        type={type ?? "number"}
                        value={inputValue}
                        variant="outlined"
                    />
                </Box>
            )}
        />
    );
};

export default ScanInputField;
