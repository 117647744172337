export const INVENTORY_STATE_CARDBOARD_STANDBY = "Cartons STANDBY";
export const INVENTORY_STATE_CARDBOARD_DON = "Cartons PAC/DON";
export const INVENTORY_STATE_TO_STORE_RETRIEVAL = "À RANGER - REPRISE";
export const INVENTORY_STATE_CARDBOARD_BERRY = "Cartons BERRY";
export const INVENTORY_STATE_SB_AUDE = "SB-Aude";
export const INVENTORY_STATE_SB_CELINE = "SB-Celine";
export const INVENTORY_STATE_SB_SAMIA = "SB-Samia";
export const INVENTORY_STATE_SB_CARTON_OMAJ = "SB-Carton_OMAJ";
export const INVENTORY_STATE_SB_LOUISE = "SB-Louise";
export const INVENTORY_STATE_SB_YSAORA = "SB-Ysaora";
export const INVENTORY_STATE_SB_MARION = "SB-Marion";

const stateColorMapping: Record<string, string> = {
    [INVENTORY_STATE_TO_STORE_RETRIEVAL]: "#ADD8E6",
    [INVENTORY_STATE_CARDBOARD_BERRY]: "#FF6666",
    [INVENTORY_STATE_CARDBOARD_DON]: "yellow",
    [INVENTORY_STATE_CARDBOARD_STANDBY]: "violet",
    [INVENTORY_STATE_SB_AUDE]: "blue",
    [INVENTORY_STATE_SB_CELINE]: "blue",
    [INVENTORY_STATE_SB_SAMIA]: "blue",
    [INVENTORY_STATE_SB_CARTON_OMAJ]: "blue",
    [INVENTORY_STATE_SB_LOUISE]: "blue",
    [INVENTORY_STATE_SB_YSAORA]: "blue",
    [INVENTORY_STATE_SB_MARION]: "blue"
};

const getInventoryStateColor = (state: string): string => {
    return stateColorMapping[state] || "blue";
};

export default getInventoryStateColor;
