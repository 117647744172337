import { Box, Typography } from "@mui/material";

interface Props {
    children?: JSX.Element | JSX.Element[];
    bgColor?: string;
    title?: string;
}

const ControlSection = ({ children, bgColor, title }: Props): JSX.Element => (
    <Box
        bgcolor={bgColor}
        sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            justifyContent: "center",
            padding: "16px 0",
            width: "100%"
        }}
    >
        {title && <Typography variant="h6">{title}</Typography>}
        <Box
            sx={{
                alignItems: "flex-start",
                display: "flex",
                gap: 2,
                justifyContent: "space-between",
                padding: "0 100px"
            }}
        >
            {children}
        </Box>
    </Box>
);

export default ControlSection;
