import ChoiceButton from "components/buttons/ChoiceButton";

import { useAppSelector } from "store/hooks";
import { selectLoader } from "store/loaderSlice";

interface ButtonParams {
    areRequiredFieldsFilledIn: boolean;
    onClick: any;
}
const StandbyButton = ({ areRequiredFieldsFilledIn, onClick }: ButtonParams): JSX.Element => {
    const loader = useAppSelector(selectLoader);

    return (
        <ChoiceButton
            color="secondary"
            disabled={loader.isLoading ?? !areRequiredFieldsFilledIn}
            onClick={onClick}
            sx={{ padding: "6px 10px" }}
            text="Standby"
            type="submit"
            variant="contained"
        />
    );
};

export default StandbyButton;
