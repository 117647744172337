import { type FC, useState } from "react";
import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import carouselStyle from "./carouselStyle.module.css";

const PhotoCarousel: FC<any> = ({ picture, isCarouselVisible, setIsCarouselVisible }) => {
    const [photoIndex, setPhotoIndex] = useState<number>(0);

    function changePhotoIndex(operator: number): void {
        setPhotoIndex(Math.abs(photoIndex + operator) % picture.length);
    }

    return (
        <Dialog open={isCarouselVisible}>
            <Box
                alignItems={"center"}
                display="flex"
                justifyContent={"space-between"}
                sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%"
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        changePhotoIndex(-1);
                    }}
                    sx={{ margin: 1 }}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        changePhotoIndex(+1);
                    }}
                    sx={{ margin: 1 }}
                >
                    <ChevronRightIcon />
                </IconButton>
            </Box>
            <Box>
                <IconButton
                    aria-label="close"
                    onClick={() => setIsCarouselVisible(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent
                sx={{
                    width: 450,
                    height: 550,
                    pt: 6,
                    px: 5
                }}
            >
                <img
                    alt={picture[photoIndex].id}
                    className={carouselStyle.carousel__picture}
                    src={picture[photoIndex].photo_url}
                />
            </DialogContent>
        </Dialog>
    );
};

export default PhotoCarousel;
