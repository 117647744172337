import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import FormField from "components/formFields/FormField";

import { flawsValues } from "./flawsValues";

import type { UsualValue } from "types/main";
import { Controller } from "react-hook-form";

interface Props {
    control: any;
    name?: string;
    productCondition?: string;
    selectedFlaws?: UsualValue[];
    setSelectedFlaws: React.Dispatch<React.SetStateAction<UsualValue[]>>;
}

const FlawsToggle = ({
    control,
    name,
    productCondition,
    selectedFlaws,
    setSelectedFlaws
}: Props): JSX.Element => {
    return (
        <Controller
            control={control}
            name={name ?? ""}
            render={({ field: { onChange, ref, value } }) => (
                <FormField sx={{ display: "flex", flexWrap: "wrap" }}>
                    <ToggleButtonGroup
                        ref={ref}
                        color="primary"
                        onChange={(event: React.MouseEvent<HTMLElement>, newValues: any[]) => {
                            onChange(newValues);
                            setSelectedFlaws(newValues);
                        }}
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "8px",
                            marginBottom: "10px"
                        }}
                        value={selectedFlaws}
                    >
                        {flawsValues
                            .filter((option) => {
                                return (
                                    productCondition && option.condition?.includes(productCondition)
                                );
                            })
                            .map((option, i) => {
                                return (
                                    <ToggleButton
                                        key={i}
                                        sx={{
                                            border: "1px solid #25402B !important",
                                            borderRadius: "4px !important",
                                            lineHeight: "1",
                                            margin: "0 !important",
                                            textTransform: "none"
                                        }}
                                        value={option}
                                    >
                                        {option.label}
                                    </ToggleButton>
                                );
                            })}
                    </ToggleButtonGroup>
                </FormField>
            )}
        />
    );
};

export default FlawsToggle;
