import { useNavigate } from "react-router-dom";

import { handleCallErrorWithLoader } from "services/exceptions";
import {
    fetchPickedProductsFromPickingSession,
    fetchProductsToPickFromPickingRail
} from "services/picking";
import { handlePhotosFiltering } from "helpers/photos/handlePhotosFiltering";
import { handlePhotosSorting } from "helpers/photos/handlePhotosSorting";

import { Button, Typography } from "@mui/material";
import ActiveSessionInfos from "./ActiveSessionInfos";

import { useAppDispatch } from "store/hooks";
import {
    addNotFoundProducts,
    addPickedProducts,
    setPickingSessionId,
    setProductsToPick
} from "store/pickingSlice";

import { PICKING_PHOTO_FORMAT_DISPLAYED, PRODUCTS_TO_PICK_PARAM } from "constants/operationsRules";
import type { OrderedProduct, PickingRail } from "types/picking";

interface Props {
    pickingRail: PickingRail;
}

const PickingRailButton = ({ pickingRail }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const photosBaseUrl: string = import.meta.env.VITE_APP_LOCAL_BASE_URL ?? "";

    // When a picking rail is selected :
    // - Fetch products to pick from picking rail & store them
    // - When session already exists : fetch picked products & store them
    function handlePickingRailChoice(pickingRail: PickingRail): void {
        if (pickingRail.active_picking_session.length) {
            dispatch(setPickingSessionId(String(pickingRail.active_picking_session[0].id)));
            getPickedProducts(pickingRail.active_picking_session[0].id);
        }
        getProductsToPick(pickingRail);
    }

    async function getPickedProducts(pickingSession: number): Promise<void> {
        const pickedProducts = await handleCallErrorWithLoader(
            dispatch,
            fetchPickedProductsFromPickingSession,
            pickingSession
        );
        if (pickedProducts.length > 0) {
            for (const product of pickedProducts) {
                // Store products with only needed photos
                product.ordered_product = filterPhotos(product.ordered_product);

                if (product.is_not_found) {
                    dispatch(addNotFoundProducts(product));
                } else {
                    dispatch(addPickedProducts(product));
                }
            }
        }
    }

    async function getProductsToPick(pickingRail: PickingRail): Promise<void> {
        const productsToPick = await handleCallErrorWithLoader(
            dispatch,
            fetchProductsToPickFromPickingRail,
            pickingRail.id
        );
        if (productsToPick.length > 0) {
            for (let product of productsToPick) {
                // Store products with only needed photos
                product = filterPhotos(product);
                handlePhotosPreload(product);
            }
            dispatch(setProductsToPick(productsToPick));

            navigate(`${String(pickingRail.label)}/${PRODUCTS_TO_PICK_PARAM}`);
        }
    }

    // Filter photos to display & display cover photo first
    function filterPhotos(product: OrderedProduct): OrderedProduct {
        if (product?.picking_rail_product?.photos) {
            product.picking_rail_product.photos = handlePhotosSorting(
                handlePhotosFiltering(
                    PICKING_PHOTO_FORMAT_DISPLAYED,
                    product.picking_rail_product.photos
                )
            );
        }
        return product;
    }

    function handlePhotosPreload(product: OrderedProduct): Array<Promise<HTMLImageElement>> {
        const preloadedPhotos = [];

        if (product?.picking_rail_product?.photos) {
            for (const photo of product?.picking_rail_product?.photos) {
                const prelaodedPhoto = preloadImage(photo.photo_url);
                prelaodedPhoto && preloadedPhotos.push(prelaodedPhoto);
            }
        }
        return preloadedPhotos;
    }

    async function preloadImage(src: string): Promise<HTMLImageElement> {
        return await new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = function () {
                resolve(img);
            };
            img.onerror = img.onabort = function () {
                reject(src);
            };
            img.src = `${photosBaseUrl}${src}`;
        });
    }

    return (
        <Button
            color={pickingRail.active_picking_session.length ? "secondary" : "light"}
            onClick={() => {
                handlePickingRailChoice(pickingRail);
            }}
            sx={{
                display: "flex",
                flexDirection: "column",
                fontSize: "18px",
                padding: "6px 20px",
                minWidth: "120px",
                width: "100%"
            }}
            variant="contained"
        >
            <Typography
                sx={{
                    alignItems: "center",
                    color: pickingRail.is_late ? "#ff0000" : "primary",
                    display: "flex"
                }}
            >
                {pickingRail.label}
            </Typography>
            {pickingRail.active_picking_session.length ? (
                <ActiveSessionInfos pickingRail={pickingRail} />
            ) : null}
        </Button>
    );
};

export default PickingRailButton;
