import { useNavigate, useParams } from "react-router-dom";

import UndoIcon from "@mui/icons-material/Undo";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Box, Button, IconButton, Paper } from "@mui/material";
import { PRODUCTS_TO_PICK_PARAM } from "constants/operationsRules";

const PickingCartFooter = (): JSX.Element => {
    const navigate = useNavigate();

    const { picking_rail_id } = useParams();

    function goBackToPickingRails(): void {
        navigate("/warehouse/pickingrails");
    }

    return (
        <Paper
            elevation={3}
            sx={{
                backgroundColor: "#e5e5e5",
                borderRadius: 0,
                boxShadow: "none",
                bottom: 0,
                display: "flex",
                justifyContent: "space-between",
                left: 0,
                position: "fixed",
                right: 0,
                width: "100vw"
            }}
        >
            <Box color="secondary" sx={{ margin: "10px" }}>
                <IconButton
                    aria-label="Undo"
                    color="error"
                    onClick={() => {
                        navigate(
                            `/warehouse/pickingrails/${String(
                                picking_rail_id
                            )}/${PRODUCTS_TO_PICK_PARAM}`
                        );
                    }}
                    size="large"
                >
                    <UndoIcon sx={{ fontSize: "45px" }} />
                </IconButton>
            </Box>

            <Button
                color="primary"
                onClick={goBackToPickingRails}
                size="large"
                sx={{ fontWeight: "bold", margin: 2 }}
                variant="outlined"
            >
                <ExitToAppIcon sx={{ marginRight: 1 }} />
                STOP PICKING
            </Button>
        </Paper>
    );
};

export default PickingCartFooter;
