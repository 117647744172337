import ChoiceButton from "components/buttons/ChoiceButton";

interface Props {
    isRowDisabled: boolean;
    updateProductUniverseToOtherGender: (gender: string) => Promise<void>;
}

const UniverseActions = ({
    isRowDisabled,
    updateProductUniverseToOtherGender
}: Props): JSX.Element => {
    function updateProductUniverseToMen(): void {
        updateProductUniverseToOtherGender("Homme");
    }

    return (
        <ChoiceButton
            disabled={isRowDisabled}
            onClick={() => {
                updateProductUniverseToMen();
            }}
            sx={{ marginBottom: "-10px", textTransform: "none", width: "100%" }}
            text="🙋‍♂️ Homme"
            variant="outlined"
        />
    );
};

export default UniverseActions;
