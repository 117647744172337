import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { fetchAttractivitytags, fetchBrands } from "services/criterias";
import { handleCallErrorWithLoader } from "services/exceptions";
import { fetchProductsToControl } from "services/products";

import {
    Box,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import ControlDialog from "./sections/Dialog";
import ControlFooter from "./sections/Footer";
import ControlViewRow from "./sections/Row";
import Loader from "components/loader/Loader";
import ScreenTitle from "components/screenTitle";

import { clearErrorMessage } from "store/errorSlice";
import { selectLoader } from "store/loaderSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import {
    CONTROL_ATTRACTIVITY_TAGS,
    CONTROL_BRANDS,
    CONTROL_CATEGORIES
} from "constants/usersRules";
import { MAX_NUMBER_OF_PRODUCTS_IN_CONTROL_BATCH } from "constants/operationsRules";
import type { Criteria, ProductState, RatedCriteria } from "types/product";

interface ProductsToControl extends ProductState {
    isFirstInCategory?: boolean;
}
const ControlView = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const loader = useAppSelector(selectLoader);

    const { taskName } = useParams();
    const { attractivityTagId } = useParams();

    const [attractivitytagsList, setAttractivitytagsList] = useState<RatedCriteria[]>([]);
    const [controlledProducts, setControlledProducts] = useState<string[]>([]);
    const [currentControlTitle, setCurrentControlTitle] = useState<string>(taskName ?? "");
    const [isControlDialogOpen, setIsControlDialogOpen] = useState<boolean>(false);
    const [smallBrand, setSmallBrand] = useState<Criteria | undefined>();
    const [productsToControl, setProductsToControl] = useState<ProductsToControl[]>([]);
    const [totalControls, setTotalControls] = useState<number>(0);
    const [vintageBrand, setVintageBrand] = useState<Criteria | undefined>();

    const remainingControls = productsToControl.length - controlledProducts.length;

    // On page render : fetch products to control & specific task data
    useEffect(() => {
        dispatch(clearErrorMessage());
        getProductsToControl();
        if (taskName?.toUpperCase() === CONTROL_BRANDS) {
            getSpecificBrand("Petite marque");
            getSpecificBrand("Vintage");
        }
        if (taskName?.toUpperCase() === CONTROL_ATTRACTIVITY_TAGS) {
            handleCallErrorWithLoader(dispatch, fetchAttractivitytags).then((result) => {
                setAttractivitytagsList(result.reverse());
            });
        }
    }, []);

    // When attractivitytaglist is set, modify control title
    useEffect(() => {
        if (taskName?.toUpperCase() === CONTROL_ATTRACTIVITY_TAGS) {
            const currentAttractivityTag = attractivitytagsList.find((attractivityTag) => {
                return String(attractivityTag.id) === attractivityTagId;
            });
            if (
                currentAttractivityTag &&
                !currentControlTitle.includes(currentAttractivityTag.label)
            ) {
                setCurrentControlTitle(currentControlTitle + " " + currentAttractivityTag.label);
            }
        }
    }, [attractivitytagsList]);

    // When brand control task : get specific brands used in choice buttons
    // Coded here to make only one call
    async function getSpecificBrand(label: string): Promise<void> {
        let specificBrands = await handleCallErrorWithLoader(dispatch, fetchBrands, label);
        if (specificBrands) {
            specificBrands = specificBrands.filter((brand: Criteria) => brand.label === label);
            label === "Petite marque" && setSmallBrand(specificBrands[0]);
            label === "Vintage" && setVintageBrand(specificBrands[0]);
        }
    }

    // Fetch products to control
    async function getProductsToControl(): Promise<void> {
        const result = await handleCallErrorWithLoader(
            dispatch,
            fetchProductsToControl,
            taskName,
            MAX_NUMBER_OF_PRODUCTS_IN_CONTROL_BATCH,
            attractivityTagId
        );
        if (result) {
            if (taskName?.toUpperCase() === CONTROL_CATEGORIES) {
                result.results = markFirstProductCategory(result.results);
            }
            setProductsToControl(result.results);
            setTotalControls(result.count);
        }
    }

    // Mark first product of a category - used in Category controls view
    function markFirstProductCategory(products: ProductsToControl[]): ProductsToControl[] {
        products[0].isFirstInCategory = true;

        for (let i = 1; i < products.length; i++) {
            if (products[i].category?.label !== products[i - 1].category?.label) {
                products[i].isFirstInCategory = true;
            }
        }
        return products;
    }

    // On modal choice : get products to control for a new session
    function handleNextControlSession(): void {
        setIsControlDialogOpen(false);
        getProductsToControl();
        setControlledProducts([]);
    }

    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                marginBottom: "100px",
                gap: 1
            }}
        >
            {currentControlTitle && <ScreenTitle text={`Contrôle : ${currentControlTitle}`} />}

            {!productsToControl.length && !loader.isLoading && <Loader />}

            <TableContainer>
                <Table sx={{ tableLayout: "auto", margin: "0 auto", width: "auto" }}>
                    <TableBody>
                        {productsToControl.map((product) => {
                            return (
                                <>
                                    {product.isFirstInCategory && (
                                        <TableRow>
                                            <TableCell
                                                colSpan={6}
                                                sx={{ border: "None", padding: "5px 0 0" }}
                                            >
                                                <Typography sx={{ fontWeight: "bold" }}>
                                                    {product.category?.label}
                                                </Typography>
                                                <Divider />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <ControlViewRow
                                        key={product.sku}
                                        attractivitytagsList={attractivitytagsList}
                                        controlledProducts={controlledProducts}
                                        product={product}
                                        setControlledProducts={setControlledProducts}
                                        smallBrand={smallBrand}
                                        taskName={taskName}
                                        vintageBrand={vintageBrand}
                                    />
                                </>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {productsToControl.length > 0 && (
                <ControlFooter
                    currentControlTitle={currentControlTitle}
                    onClick={() => {
                        setIsControlDialogOpen(true);
                    }}
                    remainingControls={remainingControls}
                    taskName={taskName}
                />
            )}

            <ControlDialog
                controlledProducts={controlledProducts}
                handleNextControlSession={handleNextControlSession}
                isControlDialogOpen={isControlDialogOpen}
                productsToControl={productsToControl}
                remainingControls={remainingControls}
                setIsControlDialogOpen={setIsControlDialogOpen}
                taskName={taskName}
                totalControls={totalControls}
            />
        </Box>
    );
};

export default ControlView;
