import { useEffect } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

import { IconButton, Paper } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";

const Microphone = (): JSX.Element => {
    const { listening, interimTranscript } = useSpeechRecognition();

    useEffect(() => {
        SpeechRecognition.startListening({ continuous: true, language: "fr-FR" });
    }, []);

    const handleMicrophone = (): void => {
        listening ? SpeechRecognition.stopListening() : SpeechRecognition.startListening();
    };
    useEffect(() => {
        // TODO : keeping it for debug in PROD
        console.log(interimTranscript);
    }, [interimTranscript]);

    return (
        <Paper
            elevation={6}
            sx={{ position: "absolute", top: "50px", right: "10px", borderRadius: "50%" }}
        >
            <IconButton color="inherit" onClick={handleMicrophone} size="large">
                {listening ? <MicIcon /> : <MicOffIcon color="error" />}
            </IconButton>
        </Paper>
    );
};

export default Microphone;
