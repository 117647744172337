import { useEffect, useState } from "react";
import type { Control } from "react-hook-form";

import { fetchCategoriesByLabelAndUniverse } from "services/criterias";
import { handleCallError } from "services/exceptions";
import { patchProductInfos } from "services/products";

import { getChainedCategoryLabels } from "helpers/criteria/getChainedCategoryLabels";

import { Box } from "@mui/material";
import AutocompleteField from "components/formFields/AutocompleteField";

import { useAppDispatch } from "store/hooks";

import type { Criteria, NestedCriteria, ProductState } from "types/product";

interface Props {
    control: Control;
    controlledProducts: string[];
    isRowDisabled: boolean;
    product: ProductState;
    setControlledProducts: React.Dispatch<React.SetStateAction<string[]>>;
    setIsRowDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    setIsRowLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const CategoryActions = ({
    control,
    controlledProducts,
    isRowDisabled,
    product,
    setControlledProducts,
    setIsRowDisabled,
    setIsRowLoading
}: Props): JSX.Element => {
    const dispatch = useAppDispatch();

    const [categoriesList, setCategorysList] = useState<Criteria[]>([]);
    const [categoryUniverse, setCategoryUniverse] = useState<string>("Femme");

    // On page render : set universe state
    useEffect(() => {
        product.category?.parent?.parent?.label &&
            setCategoryUniverse(product.category?.parent?.parent?.label);
    }, []);

    async function setCategoriesListInAutocomplete(inputCategory: string): Promise<void> {
        const result = await fetchCategoriesByLabelAndUniverse(inputCategory, categoryUniverse);
        result.forEach((category: NestedCriteria) => {
            category.label = getChainedCategoryLabels(category, 2);
        });
        setCategorysList(result);
    }

    // Patch product category
    async function updateProductCategory(data: Criteria): Promise<void> {
        setIsRowLoading(true);
        const result = await handleCallError(
            dispatch,
            patchProductInfos,
            { category: data },
            product.sku
        );
        if (result) {
            setIsRowDisabled(true);
            if (product.sku && !controlledProducts.includes(product.sku)) {
                setControlledProducts([...controlledProducts, product.sku]);
            }
        }
        setIsRowLoading(false);
    }

    return (
        <Box sx={{ width: "370px" }}>
            <AutocompleteField
                control={control}
                disabled={isRowDisabled}
                label="Catégorie"
                name={product.sku ?? ""}
                optionalOnChange={updateProductCategory}
                optionalOnInputChange={setCategoriesListInAutocomplete}
                optionsList={categoriesList}
            />
        </Box>
    );
};

export default CategoryActions;
