import ChoiceButton from "components/buttons/ChoiceButton";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";

import type { ProductState } from "types/product";

interface Props {
    scannedProducts: ProductState[];
    setIsLocationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const LocationFooter = ({ scannedProducts, setIsLocationDialogOpen }: Props): JSX.Element => {
    return (
        <AppBar
            color="secondary"
            position="fixed"
            sx={{
                alignItems: "center",
                backgroundColor: "#e5e5e5",
                bottom: "0",
                left: "0",
                top: "auto",
                width: "100vw"
            }}
        >
            <Toolbar sx={{ padding: 0 }}>
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                        {scannedProducts.length} produits à ranger
                    </Typography>
                </Box>
                <ChoiceButton
                    color="primary"
                    disabled={scannedProducts.length === 0}
                    onClick={() => {
                        setIsLocationDialogOpen(true);
                    }}
                    sx={{ fontSize: "12px" }}
                    text="Ranger les produits"
                    variant="contained"
                />
            </Toolbar>
        </AppBar>
    );
};

export default LocationFooter;
