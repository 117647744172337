import { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { handleCallErrorWithLoader } from "services/exceptions";
import { fetchFirstProductFromItemQr } from "services/products";

import ScanInputField from "components/formFields/ScanInputField";
import ScreenTitle from "components/screenTitle";
import { Grid, Button } from "@mui/material/";

import LocationPickDialog from "./sections/LocationPickDialog";
import LocationErrorDialog from "./sections/LocationErrorDialog";
import LocationFooter from "./sections/LocationFooter";
import ProductGridItem from "./sections/ProductGridItem";

import { useAppDispatch } from "store/hooks";
import { clearErrorMessage, setErrorMessage } from "store/errorSlice";

import { MAX_NUMBER_OF_PRODUCTS_ON_STORING_RAIL } from "constants/operationsRules";
import type { ProductState } from "types/product";

import sounds from "assets/sounds";

const PickLocation = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const bellSound = new Audio(sounds.bellSound);
    const errorSound = new Audio(sounds.errorSound);

    const { control } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange"
    });

    const [isInventoryModeActive, setIsInventoryModeActive] = useState<boolean>(false);
    const [isLocationErrorDialogOpen, setIsLocationErrorDialogOpen] = useState<boolean>(false);
    const [isLocationDialogOpen, setIsLocationDialogOpen] = useState<boolean>(false);
    const [isMaxProductsReached, setIsMaxProductsReached] = useState<boolean>(false);
    const [isScannedProductToProcess, setIsScannedProductToProcess] = useState<boolean>(false);
    const [productInputValue, setProductInputValue] = useState<string>("");
    const [scannedProducts, setScannedProducts] = useState<ProductState[]>([]);

    // Disable scan field if 20 products have been scanned
    // Except when inventory mode is active
    useEffect(() => {
        if (
            scannedProducts.length >= MAX_NUMBER_OF_PRODUCTS_ON_STORING_RAIL &&
            !isInventoryModeActive
        ) {
            setIsMaxProductsReached(true);
        }
    }, [scannedProducts]);

    // Sync scanned products with products on localstorage
    useEffect(() => {
        const loadedProducts = localStorage.getItem("scannedProducts");
        if (loadedProducts) {
            setScannedProducts(JSON.parse(loadedProducts));
        }
    }, []);

    // When product is successfully scanned, it plays bell sound
    // useLayoutEffect prevent NotAllowedError when Safari browser is used
    useLayoutEffect(() => {
        if (scannedProducts.length >= 1) {
            playSound(bellSound);
        }
    }, [scannedProducts]);

    // When max number of products have been scanned OR scanned product is not fully processed
    // then it opens error dialog and plays buzz sound
    useLayoutEffect(() => {
        if (isMaxProductsReached || isScannedProductToProcess) {
            setIsLocationErrorDialogOpen(true);
            playSound(errorSound);
        }
    }, [isMaxProductsReached, isScannedProductToProcess]);

    const playSound = (sound: HTMLAudioElement): void => {
        sound.play().catch((e) => {
            console.log(e);
        });
    };

    // Check if product exists, hasn't been already scanned, and is fully processed
    const handleProductScan = async (event: { preventDefault: () => void }): Promise<void> => {
        event.preventDefault();
        dispatch(clearErrorMessage());

        const scannedProduct: ProductState = await handleCallErrorWithLoader(
            dispatch,
            fetchFirstProductFromItemQr,
            productInputValue
        );

        if (!scannedProduct?.sku) {
            dispatch(setErrorMessage(`Le produit ${String(productInputValue)} n'existe pas`));
        } else if (scannedProducts.some((sp) => sp.sku === scannedProduct.sku)) {
            dispatch(setErrorMessage(`Produit déjà scanné`));
        } else if (!scannedProduct.attractivity_tag) {
            setIsScannedProductToProcess(true);
        } else {
            setScannedProducts([scannedProduct, ...scannedProducts]);
            localStorage.setItem(
                "scannedProducts",
                JSON.stringify([scannedProduct, ...scannedProducts])
            );

            // When scanned product already has a location, enable inventory mode
            if (!isInventoryModeActive && scannedProduct.location?.barcode) {
                setIsInventoryModeActive(true);
            }
        }
        setProductInputValue("");
    };

    return (
        <div>
            <ScreenTitle text={`Rangement des produits`} />
            <form onSubmit={handleProductScan}>
                <ScanInputField
                    autoFocus
                    color="primary"
                    control={control}
                    disabled={isMaxProductsReached}
                    inputValue={productInputValue}
                    name="Scanner les produits"
                    setInputValue={setProductInputValue}
                    type="string"
                />
            </form>

            <Button
                color="secondary"
                onClick={() => {
                    localStorage.removeItem("scannedProducts");
                    setScannedProducts([]);
                }}
                style={{ position: "absolute", top: 50, right: 20 }}
                variant="contained"
            >
                Vider les produits du cache
            </Button>

            <Grid container mb={12} mt={2} spacing={2}>
                {scannedProducts?.map((item) => {
                    return (
                        <ProductGridItem
                            key={item.sku}
                            item={item}
                            scannedProducts={scannedProducts}
                            setScannedProducts={setScannedProducts}
                        />
                    );
                })}
            </Grid>

            <LocationErrorDialog
                isLocationErrorDialogOpen={isLocationErrorDialogOpen}
                isScannedProductToProcess={isScannedProductToProcess}
                setIsLocationErrorDialogOpen={setIsLocationErrorDialogOpen}
                setIsScannedProductToProcess={setIsScannedProductToProcess}
                setProductInputValue={setProductInputValue}
            />

            <LocationPickDialog
                isLocationDialogOpen={isLocationDialogOpen}
                scannedProducts={scannedProducts}
                setIsLocationDialogOpen={setIsLocationDialogOpen}
                setScannedProducts={setScannedProducts}
            />

            <LocationFooter
                scannedProducts={scannedProducts}
                setIsLocationDialogOpen={setIsLocationDialogOpen}
            />
        </div>
    );
};

export default PickLocation;
