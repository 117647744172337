import { type UsualValue } from "types/main";

export const flawsValues: UsualValue[] = [
    {
        condition: ["Refusé"],
        label: "Sans marque",
        value: "Sans marque"
    },
    {
        condition: ["Refusé"],
        label: "Rétréci au lavage",
        value: "Rétréci au lavage"
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Tache",
        value: "Tache"
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Trou",
        value: "Trou"
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Déchirure",
        value: "Déchirure "
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Col jauni",
        value: "Col jauni "
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Auréoles",
        value: "Légères auréoles "
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Décoloration",
        value: "Décoloration "
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Bouloches",
        value: "Bouloches"
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Bouton manq.",
        value: "Bouton manquant "
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Pression manq.",
        value: "Pression manquante "
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Tissu détendu",
        value: "Tissu détendu "
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Accroc",
        value: "Accroc "
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Fils tirés",
        value: "Fils tirés "
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Usure",
        value: "Traces d’usure"
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Ceinture manq.",
        value: "Ceinture manquante"
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Passant de ceint. craqué",
        value: "Passant de ceinture craqué"
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Etiq. décousue",
        value: "Etiquette décousue"
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Ferm. éclair défaillante",
        value: "Fermeture éclair défaillante"
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Ourlet décousu",
        value: "Ourlet décousu"
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Ourlet recousu",
        value: "Ourlet recousu"
    },
    {
        condition: ["Refusé", "Petits défauts"],
        label: "Cordon manq.",
        value: "Cordon manquant"
    },
    {
        condition: ["Bon état"],
        label: "Petite tache lavable",
        value: "Petite tache lavable"
    },
    {
        condition: ["Bon état"],
        label: "Couleur un peu passée",
        value: "Couleur un peu passée"
    },
    {
        condition: ["Bon état"],
        label: "Tissu frotté",
        value: "Tissu frotté"
    },
    {
        condition: ["Bon état"],
        label: "Fils tirés",
        value: "Fils tirés"
    },
    {
        condition: ["Bon état"],
        label: "Qq sequins abîmés",
        value: "Quelques sequins abîmés"
    }
];
