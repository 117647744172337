import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import ChoiceButton from "components/buttons/ChoiceButton";

interface Props {
    message?: string;
    currentProductStatus: string;
    setCurrentProductStatus: React.Dispatch<React.SetStateAction<string>>;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const StandbyConfirm = ({
    message,
    currentProductStatus,
    setCurrentProductStatus,
    onClick
}: Props): JSX.Element => {
    return (
        <Dialog
            aria-labelledby="product-standby-confirm-dialog"
            open={currentProductStatus === "STANDBY"}
        >
            <DialogTitle id="product-standby-confirm-dialog">
                {"Produit à mettre en standby"}
            </DialogTitle>
            <DialogContent>
                {message ? (
                    <Typography>{`Raison : ${message}`}</Typography>
                ) : (
                    <Typography color="error">
                        {"⚠️ Renseigner la question dans les notes privées"}
                    </Typography>
                )}
                <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                    <ChoiceButton
                        color="error"
                        onClick={() => {
                            setCurrentProductStatus("ACCEPTED");
                        }}
                        text="Retour"
                        variant="outlined"
                    />
                    {message && (
                        <ChoiceButton
                            color="primary"
                            onClick={onClick}
                            text="Confirmer"
                            variant="contained"
                        />
                    )}
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default StandbyConfirm;
