import { Controller } from "react-hook-form";
import { FormControl, TextField } from "@mui/material";
import { type TextInputFieldParams } from "types/main";
import { useState, useImperativeHandle, type SetStateAction } from "react";

const TextInputField = ({
    control,
    error,
    existantNote,
    inputProps,
    InputProps,
    isRequired,
    label,
    minRows,
    multiline,
    name,
    type,
    onOptionalInputChange,
    inputRef
}: TextInputFieldParams & { inputRef?: React.Ref<HTMLInputElement> }): JSX.Element => {
    const [value, setValue] = useState("");

    return (
        <Controller
            control={control}
            defaultValue={existantNote ?? ""}
            name={name}
            render={({ field: { onChange, ref, value: fieldValue } }) => {
                useImperativeHandle(inputRef, () => ({
                    setValue(newValue: SetStateAction<any>) {
                        setValue(newValue);
                        onChange(newValue)
                    }
                }));

                return (
                    <FormControl fullWidth>
                        <TextField
                            error={error}
                            fullWidth
                            inputProps={inputProps}
                            inputRef={ref}
                            label={`${String(label)}${isRequired ? ` *` : ""}`}
                            minRows={minRows}
                            multiline={multiline}
                            onChange={(e) => {
                                let newValue = e.target.value;
                                if (onOptionalInputChange) {
                                    newValue = onOptionalInputChange(newValue);
                                    setValue(newValue);
                                }
                                setValue(newValue);
                                onChange(newValue);
                            }}
                            sx={{
                                "& .MuiInputLabel-root.Mui-error ": {
                                    color: "#78a72e"
                                },
                                "& .MuiInputLabel-root ": {
                                    color: isRequired ? "#D32F2F" : "#25402B"
                                },
                                "& .Mui-error .MuiOutlinedInput-notchedOutline": {
                                    border: "2px solid #78a72e !important"
                                }
                            }}
                            type={type}
                            value={fieldValue ?? value}
                            variant="outlined"
                        />
                    </FormControl>
                );
            }}
        />
    );
};

export default TextInputField;
