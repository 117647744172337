import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import FormField from "components/formFields/FormField";

import { refusalReasonsValues } from "./refusalReasonsValues";

import type { UsualValue } from "types/main";

interface Props {
    control: any;
    selectedFlaws?: UsualValue[];
    setSelectedFlaws: React.Dispatch<React.SetStateAction<UsualValue[]>>;
}

const RefusalReasonsToggle = ({ control, selectedFlaws, setSelectedFlaws }: Props): JSX.Element => {
    return (
        <FormField sx={{ display: "flex", flexWrap: "wrap" }}>
            <ToggleButtonGroup
                color="primary"
                onChange={(event: React.MouseEvent<HTMLElement>, newValues: any[]) => {
                    setSelectedFlaws(newValues);
                }}
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                    marginBottom: "10px"
                }}
                value={selectedFlaws}
            >
                {refusalReasonsValues.map((option, i) => {
                    return (
                        <ToggleButton
                            key={i}
                            sx={{
                                textTransform: "none",
                                margin: "0 !important",
                                borderRadius: "4px !important",
                                border: "1px solid #25402B !important",
                                lineHeight: "1"
                            }}
                            value={option}
                        >
                            {option.label}
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
        </FormField>
    );
};

export default RefusalReasonsToggle;
