/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Resizer from "react-image-file-resizer";
import { S3Client, PutObjectCommand, ObjectCannedACL } from "@aws-sdk/client-s3";

import {
    Button,
    Box,
    Avatar,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    CircularProgress
} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ChoiceButton from "components/buttons/ChoiceButton";

import { deleteProductPhoto } from "services/products";
import { handleCallErrorWithLoader } from "services/exceptions";
import { clearProductPhoto } from "store/productSlice";
import { useAppDispatch } from "store/hooks";

import type { UploadButtonParams } from "types/main";
import theme from "theme";

const UploadButton = ({
    color,
    control,
    existantPhoto,
    isRequired,
    name,
    text,
    variant,
    capturedPhoto
}: UploadButtonParams): JSX.Element => {
    const dispatch = useAppDispatch();
    const [imageUrl, setImageUrl] = useState<string>("");
    const [isImageDialogOpen, setIsImageDialogOpen] = useState<boolean>(false);
    const [isImageResizing, setIsImageResizing] = useState<boolean>(false);
    const [isUploadInProgress, setIsUploadInProgress] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [validateDelete, setValidateDelete] = useState<boolean>(false);

    const [uploadErrorMessage, setUploadErrorMessage] = useState<string>("");

    useEffect(() => {
        existantPhoto != null && setImageUrl(existantPhoto.photo_url);
    }, []);

    useEffect(() => {
        if (selectedImage) {
            setImageUrl(URL.createObjectURL(selectedImage));
            setIsImageResizing(false);
        }
    }, [selectedImage]);

    const handleDeletePhoto = (): void => {
        setValidateDelete(false);
        if (existantPhoto != null) {
            handleCallErrorWithLoader(dispatch, deleteProductPhoto, existantPhoto.id).then(() => {
                dispatch(clearProductPhoto(existantPhoto.id));
                clearPhoto();
            });
        } else {
            clearPhoto();
        }
    };

    const resizeAndUploadPhoto = async (file: File, onChange: any): Promise<void> => {
        setIsImageResizing(true);

        try {
            Resizer.imageFileResizer(
                file,
                2500,
                2500,
                "JPEG",
                80,
                0,
                async (uri) => {
                    setIsImageResizing(false);
                    const uploadedImage = await uploadFile(uri as File);

                    // Verify if image URL is valid before setting it in form data
                    fetch(uploadedImage)
                        .then((response) => {
                            const headerContentLength = response.headers.get("Content-Length");

                            if (
                                response.status === 200 &&
                                headerContentLength &&
                                parseInt(headerContentLength) > 0
                            ) {
                                setImageUrl(uploadedImage);
                                onChange(uploadedImage);
                            } else {
                                setUploadErrorMessage("Échec de l'upload");
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                },
                "file"
            );
        } catch (error) {
            setIsImageResizing(false);
            setIsUploadInProgress(false);
            setUploadErrorMessage("Échec de l'upload");
        }
    };

    const clearPhoto = (): void => {
        setImageUrl("");
        setSelectedImage(null);
        setUploadErrorMessage("");
        setIsImageDialogOpen(false);
    };

    const cancelDeletePhotos = (): void => {
        setValidateDelete(false);
        setIsImageDialogOpen(false);
    };

    const isFieldDisabled = (): boolean => {
        return isUploadInProgress || isImageResizing || !(existantPhoto == null);
    };

    const uploadFile = async (file: File): Promise<string> => {
        const s3 = new S3Client({
            credentials: {
                accessKeyId: import.meta.env.VITE_APP_AWS_ACCESS_KEY_ID,
                secretAccessKey: import.meta.env.VITE_APP_AWS_SECRET_ACCESS_KEY
            },
            region: import.meta.env.VITE_APP_AWS_S3_REGION_NAME
        });

        const params = {
            ACL: ObjectCannedACL.public_read,
            Body: file,
            Bucket: String(import.meta.env.VITE_APP_AWS_STORAGE_BUCKET_NAME),
            Key: `processing/${+new Date()}_${file.name}`
        };

        try {
            setIsUploadInProgress(true);
            setUploadErrorMessage("");

            const command = new PutObjectCommand(params);
            const region = await s3.config.region();

            await s3.send(command);

            setIsUploadInProgress(false);

            const uploadedObjectUrl =
                `https://${params.Bucket}.s3.${region}.amazonaws.com/` + `${params.Key}`;

            return uploadedObjectUrl;
        } catch (error: any) {
            setIsUploadInProgress(false);
            setUploadErrorMessage(error.message);
        }

        return "";
    };

    return (
        <Controller
            control={control}
            defaultValue={existantPhoto?.photo_url}
            name={name ?? ""}
            render={({ field: { onChange, ref, value } }) => {
                useEffect(() => {
                    if (capturedPhoto) {
                        const file = new File([capturedPhoto], "photo.jpg", {
                            type: capturedPhoto.type
                        });
                        resizeAndUploadPhoto(file, onChange);
                    }
                }, [capturedPhoto, onChange]);

                return (
                    <Box sx={{ display: "flex" }}>
                        <Button
                            color={imageUrl ? "success" : color ?? "secondary"}
                            component="label"
                            disabled={isFieldDisabled()}
                            fullWidth
                            startIcon={<AddAPhotoIcon />}
                            sx={{ color: isRequired ? "#D32F2F" : "#25402B" }}
                            variant={variant}
                        >
                            {text}
                            {isRequired && ` *`}
                            {isUploadInProgress && (
                                <CircularProgress color="secondary" size={20} sx={{ mx: 3 }} />
                            )}
                            <input
                                ref={ref}
                                accept="image/jpeg"
                                capture="environment"
                                hidden
                                onChange={async (e) => {
                                    if (e.target.files != null) {
                                        resizeAndUploadPhoto(e.target.files[0], onChange);
                                    } else {
                                        onChange(null);
                                    }
                                }}
                                type="file"
                            />
                        </Button>
                        {imageUrl && (
                            <Box ml={theme.spacing(2)}>
                                <Button
                                    onClick={() => {
                                        setIsImageDialogOpen(true);
                                    }}
                                    variant="outlined"
                                >
                                    <Avatar alt="Thumbnail" src={imageUrl} variant="rounded" />
                                </Button>
                            </Box>
                        )}
                        {uploadErrorMessage && (
                            <Box
                                ml={theme.spacing(2)}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <Typography color="error" textAlign="center">
                                    {uploadErrorMessage}
                                </Typography>
                            </Box>
                        )}
                        <Dialog
                            aria-labelledby="image-dialog"
                            onClose={() => {
                                setIsImageDialogOpen(false);
                            }}
                            open={isImageDialogOpen}
                        >
                            <DialogTitle id="image-dialog" textAlign="center">
                                {text}
                            </DialogTitle>
                            <DialogContent
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center"
                                }}
                            >
                                <img alt={name} src={imageUrl} />
                                {existantPhoto != null && (
                                    <>
                                        {validateDelete ? (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column"
                                                }}
                                            >
                                                <Typography align="center" color="error" mt="16px">
                                                    Attention, la photo sera définitivement
                                                    supprimée de la base
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    <ChoiceButton
                                                        color="error"
                                                        onClick={() => {
                                                            cancelDeletePhotos();
                                                        }}
                                                        text="Annuler"
                                                        variant="outlined"
                                                    />
                                                    <ChoiceButton
                                                        color="error"
                                                        onClick={(e) => {
                                                            handleDeletePhoto();
                                                        }}
                                                        text="Valider"
                                                        variant="contained"
                                                    />
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                <ChoiceButton
                                                    color="error"
                                                    onClick={() => {
                                                        setValidateDelete(true);
                                                    }}
                                                    text="Supprimer la photo"
                                                    variant="contained"
                                                />
                                            </Box>
                                        )}
                                    </>
                                )}
                            </DialogContent>
                        </Dialog>
                    </Box>
                );
            }}
        />
    );
};

export default UploadButton;
