import { axiosClient } from "helpers/axios";

import type {
    GroupedPickingRails,
    PickedProduct,
    PickingSession,
    OrderedProduct
} from "types/picking";

async function createNewPickingSession(picking_rail: any): Promise<PickingSession> {
    const result = await axiosClient().post("/picking_sessions/", { picking_rail });

    return result.data;
}

async function endPickingSession(id: number): Promise<PickingSession> {
    const now = new Date();
    const formattedDate = now.toISOString();
    const result = await axiosClient().patch(`/picking_sessions/${id}/`, {
        ended_at: formattedDate
    });

    return result.data;
}

async function fetchPickedProductsFromPickingSession(id: number): Promise<PickedProduct[]> {
    const result = await axiosClient().get(`/picking_sessions/${id}/picked_products/`);

    return result.data;
}

async function fetchPickingRails(): Promise<GroupedPickingRails> {
    const result = await axiosClient().get("/picking_rails/");

    return result.data;
}

async function fetchProductsToPickFromPickingRail(id: string): Promise<OrderedProduct[]> {
    const result = await axiosClient().get(`/picking_rails/${id}/products_to_pick`);

    return result.data;
}

async function patchPickedProduct(id: string, data: any): Promise<PickedProduct> {
    const result = await axiosClient().patch(`/picked_products/${id}/`, data);

    return result.data;
}

async function postPickedProduct(data: any): Promise<PickedProduct> {
    const result = await axiosClient().post("/picked_products/", data);

    return result.data;
}

async function patchPickedRetrievedProduct(id: string, data: any): Promise<PickedProduct> {
    const result = await axiosClient().patch(`/picked_retrieved_products/${id}/`, data);

    return result.data;
}

async function postPickedRetrievedProduct(data: any): Promise<PickedProduct> {
    const result = await axiosClient().post("/picked_retrieved_products/", data);

    return result.data;
}

export {
    createNewPickingSession,
    endPickingSession,
    fetchPickedProductsFromPickingSession,
    fetchPickingRails,
    fetchProductsToPickFromPickingRail,
    patchPickedProduct,
    patchPickedRetrievedProduct,
    postPickedProduct,
    postPickedRetrievedProduct
};
