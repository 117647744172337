import { handleCallErrorWithLoaderCached } from "services/exceptions";
import { postPickedProduct, postPickedRetrievedProduct } from "services/picking";

import { handlePickedProductStorage } from "./handlePickedProductStorage";

import type { Dispatch } from "redux";
import type { OrderedProduct } from "types/picking";

export async function handlePickedProductCreation(
    dispatch: Dispatch,
    pickedProductData: any,
    picking_session: string,
    productToPick: OrderedProduct
): Promise<void> {
    pickedProductData = { ...pickedProductData, picking_session };

    const postPickedItem = pickedProductData.is_retrieval
        ? postPickedRetrievedProduct
        : postPickedProduct;

    const result = await handleCallErrorWithLoaderCached(
        dispatch,
        postPickedItem,
        pickedProductData
    );

    result && handlePickedProductStorage(dispatch, result, productToPick.id);
}
