import { handleCallErrorWithLoaderCached } from "services/exceptions";
import { createNewPickingSession } from "services/picking";

import { setPickingSessionId } from "store/pickingSlice";

import type { Dispatch } from "redux";

export async function handlePickingSessionCreation(
    dispatch: Dispatch,
    pickingRailLabel: string
): Promise<string> {
    const result = await handleCallErrorWithLoaderCached(
        dispatch,
        createNewPickingSession,
        pickingRailLabel
    );

    if (result) {
        dispatch(setPickingSessionId(result.id));
    }

    return result.id;
}
