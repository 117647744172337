import { handleCallErrorWithLoaderCached } from "services/exceptions";
import { patchPickedProduct, patchPickedRetrievedProduct } from "services/picking";

import type { Dispatch } from "redux";
import { handlePickedProductStorage } from "./handlePickedProductStorage";

export async function handlePickedProductUpdate(
    dispatch: Dispatch,
    pickedProductData: any,
    pickedProductId: string,
    picking_session: string
): Promise<void> {
    pickedProductData = { ...pickedProductData, picking_session };

    const patchPickedItem = pickedProductData.is_retrieval
        ? patchPickedRetrievedProduct
        : patchPickedProduct;

    const result = await handleCallErrorWithLoaderCached(
        dispatch,
        patchPickedItem,
        pickedProductId,
        pickedProductData
    );

    result && handlePickedProductStorage(dispatch, result);
}
