import { useEffect, useState } from "react";

import { handleCallErrorWithLoader } from "services/exceptions";
import { fetchPickingRails } from "services/picking";

import { Box } from "@mui/material";
import ScreenTitle from "components/screenTitle";

import { clearErrorMessage } from "store/errorSlice";
import { useAppDispatch } from "store/hooks";
import { clearPickingSession } from "store/pickingSlice";

import type { GroupedPickingRails } from "types/picking";
import PickingRailButton from "./sections/PickingRailButton";

const PickingRails = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const [availablePickingRails, setAvailablePickingRails] = useState<GroupedPickingRails[]>([]);

    useEffect(() => {
        dispatch(clearErrorMessage());
        dispatch(clearPickingSession());
        getAvailablePickingRails();
    }, []);

    // Fetch available picking rails to pick
    async function getAvailablePickingRails(): Promise<void> {
        const result = await handleCallErrorWithLoader(dispatch, fetchPickingRails);
        result && setAvailablePickingRails(result);
    }

    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                gap: 1
            }}
        >
            <ScreenTitle text={`⛏️ Picking`} />

            <Box
                sx={{
                    display: "flex",
                    gap: 2,
                    flexWrap: "wrap",
                    marginTop: 3,
                    width: "100%"
                }}
            >
                {availablePickingRails.map((orderGroup, i) => {
                    return (
                        <Box
                            key={i}
                            sx={{
                                alignItems: "center",
                                backgroundColor: "#cfcfcf",
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                                justifyContent: "flex-start",
                                padding: 2,
                                width: "calc(50% - 40px)"
                            }}
                        >
                            {orderGroup.picking_rails.map((pr, j) => {
                                return <PickingRailButton key={j} pickingRail={pr} />;
                            })}
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

export default PickingRails;
