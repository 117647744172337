import { useCallback, useEffect, useRef, useState } from "react";
import { BrowserMultiFormatReader, NotFoundException } from "@zxing/library";
import Webcam from "react-webcam";

import { Box } from "@mui/material";
import Scanningframe from "./ScanningFrame";

interface Props {
    setScannedQr: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const BarcodeQrScanner = ({ setScannedQr }: Props): any => {
    const scannerConstraints: MediaTrackConstraints = {
        width: 720,
        height: 720,
        facingMode: "environment"
    };

    const [code, setCode] = useState<any>();
    const [isCameraRunning, setIsCameraRunning] = useState<boolean>(false);
    const [isQrScanned, setIsQrScanned] = useState<boolean>(false);

    const webcamRef: any = useRef(null);

    const capture = useCallback(() => {
        if (webcamRef) {
            const codeReader = new BrowserMultiFormatReader();
            const imageSrc = webcamRef?.current?.getScreenshot();
            if (imageSrc) {
                codeReader
                    .decodeFromImage(undefined, imageSrc)
                    .then((result) => {
                        setIsQrScanned(true);

                        setCode(result);

                        setTimeout(() => {
                            setIsQrScanned(false);
                        }, 500);
                    })
                    .catch((err) => {
                        if (!(err instanceof NotFoundException)) {
                            console.log(err);
                        }
                    });
            }
        }
    }, [webcamRef]);

    useEffect(() => {
        const interval = setInterval(capture, 200);
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (code) {
            setScannedQr(code.text);
        }
    }, [code]);

    function handleScanningFramesDisplay(): void {
        setIsCameraRunning(true);
    }

    return (
        <Box sx={{ position: "relative", opacity: isQrScanned ? 0.1 : 1 }}>
            <Webcam
                ref={webcamRef}
                audio={false}
                height="100%"
                onUserMedia={handleScanningFramesDisplay}
                screenshotFormat="image/jpeg"
                screenshotQuality={1}
                videoConstraints={scannerConstraints}
                width="100%"
            />
            {isCameraRunning && (
                <>
                    <Scanningframe height={80} width={80} />
                    <Scanningframe height={40} width={80} />
                </>
            )}
        </Box>
    );
};

export default BarcodeQrScanner;
