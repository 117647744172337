import ChoiceButton from "components/buttons/ChoiceButton";

import { useAppDispatch } from "store/hooks";
import { handleCallError } from "services/exceptions";
import { deleteProductPhoto } from "services/products";
import type { Control } from "react-hook-form";

import type { ProductState } from "types/product";

interface Props {
    isRowDisabled: boolean;
    createControl: () => Promise<void>;
    product: ProductState;
    controlledProducts: string[];
    setIsRowLoading: React.Dispatch<React.SetStateAction<boolean>>;
    control: Control;
}

const WornPhotoActions = ({
    control,
    isRowDisabled,
    product,
    setIsRowLoading,
    createControl
}: Props): JSX.Element => {
    const dispatch = useAppDispatch();

    async function updateProduct(): Promise<void> {
        setIsRowLoading(true);

        const photoToDelete = product.photos?.find(
            (photo) => photo.format === "WHITE_BACKGROUND_WORN"
        );

        if (photoToDelete) {
            handleCallError(dispatch, deleteProductPhoto, photoToDelete.id).then(() => {
                createControl();
            });
        }

        setIsRowLoading(false);
    }

    return (
        <ChoiceButton
            disabled={isRowDisabled}
            onClick={() => {
                updateProduct();
            }}
            sx={{ marginBottom: "-10px", textTransform: "none", width: "100%" }}
            text="Supprimer la photo ❌"
            variant="outlined"
        />
    );
};

export default WornPhotoActions;
