import CircularProgress from "@mui/material/CircularProgress";
import { useAppSelector } from "store/hooks";
import { selectLoader } from "store/loaderSlice";
import loaderStyle from "./Loader.module.css";

const Loader = (): JSX.Element => {
    const loader = useAppSelector(selectLoader);

    return (
        <div className={loaderStyle.loader__container}>
            <div className={loaderStyle.loader__spinner}>
                <h2>{loader.message ? loader.message : "Chargement..."}</h2>
                <CircularProgress color="warning" size={80} />
            </div>
        </div>
    );
};

export default Loader;
