import { useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import ChoiceButton from "components/buttons/ChoiceButton";
import ProductCard from "components/productCard/ProductCard";
import UndoButton from "components/buttons/UndoButton";

import { useAppDispatch } from "store/hooks";
import { clearProduct } from "store/productSlice";

import { useStudioAccessRights } from "hooks/rolesRights";

import { type ProductState } from "types/product";

/**
 * Component props
 */
interface Props {
    isOpen: boolean;
    product: ProductState;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Product confirm modal
 * Displayed after submitting Product form
 */
const ProductConfirm = ({ isOpen, product, setIsOpen }: Props): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    /**
     * Finish product flow
     * - Clear state
     * - Navigate to redirect
     */
    const handleFinishProductFlow = (redirectTo: string): void => {
        dispatch(clearProduct());
        navigate(redirectTo);
    };

    const handleClose = (): void => {
        setIsOpen(false);
    };

    return (
        <Dialog aria-labelledby="product-confirm-dialog" open={isOpen}>
            <DialogTitle id="product-confirm-dialog" sx={{ textAlign: "center" }}>
                Produit correctement enregistré
            </DialogTitle>

            <DialogContent>
                <ProductCard product={product} />
            </DialogContent>

            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                {useStudioAccessRights("BACK_TO_PRODUCT_FORM_BUTTON") && (
                    <UndoButton handleUndo={handleClose} size="large" />
                )}

                {useStudioAccessRights("BACK_TO_HOME_BUTTON") && (
                    <ChoiceButton
                        color="secondary"
                        onClick={() => {
                            handleFinishProductFlow("/studio");
                        }}
                        text="Confirmer"
                        variant="contained"
                    />
                )}

                {useStudioAccessRights("NEXT_PRODUCT_CREATION_BUTTON") && (
                    <ChoiceButton
                        color="primary"
                        onClick={() => {
                            handleFinishProductFlow("/studio/product/open");
                        }}
                        text="Nouveau produit"
                        variant="contained"
                    />
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ProductConfirm;
