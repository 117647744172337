import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { fetchSizeTypes } from "services/criterias";
import { handleCallErrorWithLoader } from "services/exceptions";

import ChoiceButton from "components/buttons/ChoiceButton";
import ControlPage from "./sections/ControlPage";

import { selectAuthentication } from "store/authenticationSlice";
import { clearErrorMessage } from "store/errorSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { enableCloseStock, selectStock } from "store/stockSlice";
import { selectSettings } from "store/settingsSlice";

import { useStudioAccessRights } from "hooks/rolesRights";

import { ROLE_1, ROLE_2, ROLE_LOCATION, STUDIO_USER_GROUP } from "constants/usersRules";

import mainStyles from "./StudioHome.module.css";

const StudioHome = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const authenticationInfos = useAppSelector(selectAuthentication);
    const settings = useAppSelector(selectSettings);
    const stock = useAppSelector(selectStock);

    useEffect(() => {
        dispatch(enableCloseStock(true));
        dispatch(clearErrorMessage());

        // Fetch sizes data and set them in localStorage if not already set
        const sizesData = localStorage.getItem("sizes");
        if (sizesData === null) {
            setSizesDataInLocalStorage();
        }

        // When stock is open and role 1 it redirects to /product/open page
        // When role is 2 or 3 : it redirects to studio/product/scan page
        // When role is Location, it redirects to studio/location page
        // When no role is set, it redirects to settings page
        if (settings.role) {
            if (stock.barcode && settings.role === ROLE_1) {
                navigate("/studio/product/open");
            } else if ([ROLE_2].includes(settings.role)) {
                navigate("/studio/product/scan");
            } else if (settings.role === ROLE_LOCATION) {
                navigate("/studio/location");
            }
        } else if (authenticationInfos.currentGroup === STUDIO_USER_GROUP) {
            navigate("/studio/settings");
        }
    }, []);

    async function setSizesDataInLocalStorage(): Promise<void> {
        const sizes = await handleCallErrorWithLoader(dispatch, fetchSizeTypes);

        localStorage.setItem("sizes", JSON.stringify(sizes));
    }

    return (
        <div className={mainStyles.home__container}>
            <div className={mainStyles.buttons__container}>
                {useStudioAccessRights("HOME_BUTTON_STOCK") && !stock.barcode && (
                    <ChoiceButton
                        onClick={() => {
                            navigate("/studio/stock/open");
                        }}
                        sx={{
                            minHeight: 50,
                            minWidth: 220
                        }}
                        text="Ouvrir un stock"
                        variant="contained"
                    />
                )}

                {useStudioAccessRights("HOME_BUTTON_PRODUCT_CREATION") && stock.barcode && (
                    <ChoiceButton
                        color="secondary"
                        onClick={() => {
                            navigate("/studio/product/open");
                        }}
                        sx={{
                            minHeight: 50,
                            minWidth: 220
                        }}
                        text="Scanner un produit"
                        variant="contained"
                    />
                )}

                {useStudioAccessRights("HOME_BUTTON_PRODUCT_UPDATE") && (
                    <ChoiceButton
                        color="secondary"
                        onClick={() => {
                            navigate("/studio/product/scan");
                        }}
                        sx={{
                            minHeight: 50,
                            minWidth: 220
                        }}
                        text="Éditer un produit"
                        variant="contained"
                    />
                )}

                {useStudioAccessRights("HOME_BUTTONS_CONTROL") && <ControlPage />}

                {useStudioAccessRights("HOME_BUTTON_PRICING") && (
                    <ChoiceButton
                        color="secondary"
                        onClick={() => {
                            navigate("/studio/product/pricing");
                        }}
                        sx={{
                            minHeight: 50,
                            minWidth: 220
                        }}
                        text="Outil de pricing"
                        variant="contained"
                    />
                )}
            </div>
        </div>
    );
};

export default StudioHome;
