import FormField from "components/formFields/FormField";
import UploadButton from "components/formFields/UploadButton";
import { Box } from "@mui/material";
import PhotoCapture from "pages/studio/product/PhotoCapture";
import { useSpeechRecognition } from "react-speech-recognition";

import { useRef, useState } from "react";
import type { Control } from "react-hook-form";
import type { ProductPhoto } from "types/product";

interface Props {
    control: Control;
    existantPhoto: ProductPhoto | undefined;
}

const PhotoMaterialButton = ({ control, existantPhoto }: Props): JSX.Element => {
    const [capturedPhoto, setCapturedPhoto] = useState<Blob | null>(null);
    const photoCaptureRef = useRef<HTMLButtonElement>(null);
    const { resetTranscript } = useSpeechRecognition();


    const handlePhotoTaken = (blob: Blob): void => {
        setCapturedPhoto(blob);
    };

    const commands = [
        {
            command: /compositions?|compo|compisition/i,
            callback: () => {
                photoCaptureRef.current?.click();
                resetTranscript();
            }
        }
    ];

    useSpeechRecognition({ commands });

    return (
        <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
        >
            <Box flex="6">
                <FormField>
                    <UploadButton
                        capturedPhoto={capturedPhoto}
                        control={control}
                        existantPhoto={existantPhoto}
                        name="photo_MATERIAL"
                        text="Photo composition  🎤:compo"
                        variant="contained"
                    />
                </FormField>
            </Box>
            <Box flex="1">
                <PhotoCapture ref={photoCaptureRef} onPhotoTaken={handlePhotoTaken} />
            </Box>
        </Box>
    );
};

export default PhotoMaterialButton;
