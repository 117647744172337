import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { handleCallErrorWithLoader } from "services/exceptions";
import { fetchProductsToControl } from "services/products";

import ChoiceButton from "components/buttons/ChoiceButton";
import { Box, Typography } from "@mui/material";

import { useAppDispatch } from "store/hooks";

import { MAX_NUMBER_OF_PRODUCTS_IN_CONTROL_BATCH } from "constants/operationsRules";

interface Props {
    color: any;
    controlName: string;
    subcontrolId?: number;
    text: string;
}

const ControlButton = ({ color, controlName, subcontrolId, text }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [controlUrl, setControlUrl] = useState<string>(controlName);
    const [controlsInFirstBatch, setControlsInFirstBatch] = useState<number>(
        MAX_NUMBER_OF_PRODUCTS_IN_CONTROL_BATCH
    );
    const [totalControls, setTotalControls] = useState<number>(0);

    useEffect(() => {
        getNumberOfProductsToControl();
        subcontrolId && setControlUrl(`${controlName}/${subcontrolId}`);
    }, []);

    // Fetch products to control with a limit of 1 :
    // Get products count - not products objects
    async function getNumberOfProductsToControl(): Promise<void> {
        const result = await handleCallErrorWithLoader(
            dispatch,
            fetchProductsToControl,
            controlName,
            1,
            subcontrolId
        );

        result && setTotalControls(result.count);
    }

    // When total products to control count is less than classic batch count,
    // batch count is decreased
    useEffect(() => {
        setControlsInFirstBatch(Math.min(MAX_NUMBER_OF_PRODUCTS_IN_CONTROL_BATCH, totalControls));
    }, [totalControls]);

    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <ChoiceButton
                color={color}
                disabled={!totalControls}
                onClick={() => {
                    navigate(`/studio/control/${controlUrl}`);
                }}
                sx={{ padding: "6px 10px", minWidth: 200 }}
                text={text}
                variant="contained"
            />
            <Box
                sx={{
                    alignItems: "flex-start",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                    {controlsInFirstBatch} à contrôler
                </Typography>
                {totalControls > 0 && (
                    <Typography
                        sx={{ fontSize: "12px" }}
                    >{`(${totalControls} au total)`}</Typography>
                )}
            </Box>
        </Box>
    );
};

export default ControlButton;
