import { Box, Typography } from "@mui/material";
import type { PickedProduct } from "types/picking";

interface Props {
    pickedProduct: PickedProduct;
}

const PickedProductCard = ({ pickedProduct }: Props): JSX.Element => {
    // use localHost for local build
    const photosBaseUrl: string = import.meta.env.VITE_APP_LOCAL_BASE_URL ?? "";
    const product = pickedProduct.retrieved_product
        ? pickedProduct.retrieved_product
        : pickedProduct.ordered_product;

    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column"
            }}
        >
            {product?.picking_rail_product.photos?.length && (
                <img
                    alt={product.picking_rail_product.item_qr}
                    height="auto"
                    src={`${photosBaseUrl}${String(
                        product.picking_rail_product.photos[0].photo_url
                    )}`}
                    width="100%"
                />
            )}
            <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                {product?.picking_rail_product.item_qr}
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>{product?.order_picking_letter}</Typography>
        </Box>
    );
};

export default PickedProductCard;
