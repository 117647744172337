import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { handleCallErrorWithLoader } from "services/exceptions";
import { fetchStockFromBarcode, updateStock } from "services/stocks";
import { handleStockFormatting } from "helpers/stocks/handleStockFormatting";

import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import ChoiceButton from "components/buttons/ChoiceButton";
import ScanInputField from "components/formFields/ScanInputField";
import ScanningBlock from "components/qrScanner/ScanningBlock";
import ScreenTitle from "components/screenTitle";

import { clearErrorMessage } from "store/errorSlice";
import { useAppDispatch } from "store/hooks";
import { enableCloseStock, addStockDetails } from "store/stockSlice";

import { useStudioAccessRights } from "hooks/rolesRights";

import type { LocalStockState } from "types/stock";

const OpenStock = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { control } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange"
    });

    const [inputValue, setInputValue] = useState("");
    const [isInputFieldVisible, setIsInputFieldVisible] = useState<boolean>(false);
    const [isStockFormSubmitted, setIsStockFormSubmitted] = useState(false);
    const [isStockOnError, setIsStockOnError] = useState(false);
    const [scannedQr, setScannedQr] = useState<string>();
    const [selectedStock, setSelectedStock] = useState<LocalStockState>();

    useEffect(() => {
        dispatch(enableCloseStock(true));
        dispatch(clearErrorMessage());
    }, []);

    // When QR is scanned with camera, it retrieves selected stock
    useEffect(() => {
        if (scannedQr) {
            getStockFromBarcode(scannedQr);
        }
    }, [scannedQr]);

    // When QR is scanned with scanner, it retrieves selected stock
    const handleStockSubmit = (event: { preventDefault: () => void }): void => {
        void (async () => {
            if (inputValue) {
                event.preventDefault();
                getStockFromBarcode(inputValue);
            }
        })();
    };

    async function getStockFromBarcode(barcode: string): Promise<void> {
        setIsStockOnError(false);
        const result = await handleCallErrorWithLoader(dispatch, fetchStockFromBarcode, barcode);
        if (result?.barcode) {
            setSelectedStock(handleStockFormatting(result));
            setIsStockFormSubmitted(true);
        } else {
            setIsStockOnError(true);
        }
    }

    /**
     * Confirm stock
     * - Update stock API
     * - Dispatch event
     */
    const confirmStock = (): void => {
        void (async () => {
            if (selectedStock != null) {
                // @todo Use constants instead
                const data = {
                    status: "ONGOING"
                };
                await handleCallErrorWithLoader(dispatch, updateStock, data, selectedStock.barcode);

                dispatch(addStockDetails(selectedStock));
                localStorage.setItem("stock", JSON.stringify(selectedStock));
                navigate("/studio");
            }
        })();
    };

    // Reinitialize states on cancel
    const cancelStock = (): void => {
        setIsStockFormSubmitted(false);
        setInputValue("");
        setScannedQr(undefined);
    };

    return (
        <>
            <ScreenTitle text={"Ouvrir un colis"} />

            {useStudioAccessRights("CAMERA_CODE_SCANNER") && !isInputFieldVisible && (
                <ScanningBlock
                    codetype="code"
                    item="colis"
                    setIsInputFieldVisible={setIsInputFieldVisible}
                    setScannedQr={setScannedQr}
                />
            )}

            {(useStudioAccessRights("EXTERNAL_CODE_SCANNER") || isInputFieldVisible) && (
                <form onSubmit={handleStockSubmit}>
                    <ScanInputField
                        autoFocus
                        color="primary"
                        control={control}
                        inputValue={inputValue}
                        name="Code barre"
                        setInputValue={setInputValue}
                    />
                </form>
            )}
            {isStockOnError && <p>{"Identifiant inconnu. Veuillez ré-essayer."}</p>}

            <Dialog aria-labelledby="stock-confirm-dialog" open={isStockFormSubmitted}>
                <DialogTitle id="stock-confirm-dialog">{"Stock sélectionné"}</DialogTitle>

                <DialogContent>
                    <p>
                        {"Nombre de pièces déclarées : "}
                        {selectedStock?.numberOfDeclaredItems}
                    </p>
                    {selectedStock?.numberOfProcessedProducts === 0 ? (
                        <p>{"Aucune pièce n'a encore été processée"}</p>
                    ) : (
                        <p>
                            {"Dont "}
                            {selectedStock?.numberOfProcessedProducts}
                            {" déjà processée(s)"}
                        </p>
                    )}
                </DialogContent>

                <DialogActions>
                    <ChoiceButton
                        color="secondary"
                        onClick={cancelStock}
                        text="Annuler"
                        variant="contained"
                    />
                    <ChoiceButton
                        color="primary"
                        onClick={confirmStock}
                        text="Confirmer"
                        variant="contained"
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default OpenStock;
