import { IconButton } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";

import type { ButtonProps } from "@mui/material/Button";

interface Props extends ButtonProps {
    handleUndo: any;
}

const UndoButton = ({ handleUndo, size, sx }: Props): JSX.Element => {
    return (
        <IconButton
            aria-label="Undo"
            color="error"
            onClick={() => {
                handleUndo();
            }}
            size={size ?? "medium"}
            sx={sx}
        >
            <UndoIcon />
        </IconButton>
    );
};

export default UndoButton;
