import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { handleCallErrorWithLoader } from "services/exceptions";
import { fetchProductFromItemQr } from "services/products";

import ScanningBlock from "components/qrScanner/ScanningBlock";
import ScanInputField from "components/formFields/ScanInputField";
import ScreenTitle from "components/screenTitle";

import { clearErrorMessage, setErrorMessage } from "store/errorSlice";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { selectLoader } from "store/loaderSlice";
import { updateProduct, addItemQr } from "store/productSlice";
import { selectSettings } from "store/settingsSlice";
import { enableCloseStock, selectStock } from "store/stockSlice";

import { useStudioAccessRights } from "hooks/rolesRights";

import { ROLE_1, ROLE_2 } from "constants/usersRules";

const ScanProduct = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const loader = useAppSelector(selectLoader);
    const settings = useAppSelector(selectSettings);
    const stock = useAppSelector(selectStock);

    const [inputValue, setInputValue] = useState("");
    const [isInputFieldVisible, setIsInputFieldVisible] = useState<boolean>(false);
    const [scannedQr, setScannedQr] = useState<string>();

    const { control } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange"
    });

    const errorMessages = {
        emptyField: "Veuillez entrer un item QR",
        missingRole1: "Rôle 1 à faire",
        notAllowedUpdate: "Ce QR code est déjà rattaché à un produit",
        notValidQr: "Ce QR code n'est rattaché à aucun produit en stock",
        openStock: "Vous devez ouvrir un stock avant de processer un nouveau produit",
        qrLength: "L'item QR doit comporter 9 chiffres"
    };

    useEffect(() => {
        dispatch(enableCloseStock(true));
        dispatch(clearErrorMessage());
    }, []);

    // When QR is scanned with camera
    useEffect(() => {
        if (scannedQr) {
            handleItemQr(scannedQr);
        }
    }, [scannedQr]);

    // When QR is scanned with scanner
    const handleProductSubmit = async (event: { preventDefault: () => void }): Promise<void> => {
        void (async () => {
            event.preventDefault();
            if (inputValue) {
                handleItemQr(inputValue);
            } else {
                dispatch(setErrorMessage(errorMessages.emptyField));
            }
        })();
    };

    // Verify:
    // - if itemQR counts 9 characters
    // - if itemQR is linked to a product
    // - if products had been processed by role 2 if user is role 3
    // - and if not : if user has right to process a new product
    async function handleItemQr(itemQr: string): Promise<void> {
        if (itemQr.length === 9) {
            const result = await handleCallErrorWithLoader(
                dispatch,
                fetchProductFromItemQr,
                itemQr
            );
            if (result) {
                if (settings.role === ROLE_1) {
                    dispatch(updateProduct(result));
                    dispatch(addItemQr(itemQr));
                    navigate("/studio/product/accept");
                } else if (settings.role === ROLE_2 && !result.condition) {
                    handleErrorMessage(errorMessages.missingRole1);
                } else {
                    dispatch(updateProduct(result));
                    navigate("/studio/product/overview");
                }
            } else if (settings.role && [ROLE_1].includes(settings.role)) {
                if (!stock.barcode) {
                    handleErrorMessage(errorMessages.openStock);
                } else {
                    dispatch(addItemQr(itemQr));
                    navigate("/studio/product/accept");
                }
            } else {
                handleErrorMessage(errorMessages.notValidQr);
            }
        } else {
            handleErrorMessage(errorMessages.qrLength);
        }
    }

    function handleErrorMessage(message: string): void {
        dispatch(setErrorMessage(message));
        setInputValue("");
    }

    return (
        <div>
            <ScreenTitle text={"Scanner le produit"} />

            {useStudioAccessRights("CAMERA_CODE_SCANNER") && !isInputFieldVisible && (
                <ScanningBlock
                    codetype="QR code"
                    item="vêtement"
                    setIsInputFieldVisible={setIsInputFieldVisible}
                    setScannedQr={setScannedQr}
                />
            )}

            {(useStudioAccessRights("EXTERNAL_CODE_SCANNER") || isInputFieldVisible) && (
                <form onSubmit={handleProductSubmit}>
                    <ScanInputField
                        autoFocus
                        color="primary"
                        control={control}
                        disabled={loader.isLoading}
                        inputValue={inputValue}
                        name="Item QR"
                        setInputValue={setInputValue}
                    />
                </form>
            )}
        </div>
    );
};

export default ScanProduct;
