import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box } from "@mui/material";
import PickingInProgress from "../currentPicking/sections/PickingInProgress";

import { useAppSelector } from "store/hooks";
import { selectPicking } from "store/pickingSlice";

import type { PickedProduct } from "types/picking";

const CurrentPickedProduct = (): JSX.Element => {
    const { picked_product_id } = useParams();

    const notFoundProducts = useAppSelector(selectPicking).notFoundProducts;

    const [pickedProduct, setPickedProduct] = useState<PickedProduct>();

    // On page render : Get notFoundProduct infos from pickingSlice
    useEffect(() => {
        setPickedProduct(
            notFoundProducts.find((notfoundProduct) => {
                return String(notfoundProduct.id) === picked_product_id;
            })
        );
    }, []);

    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                gap: 1,
                marginTop: "-1rem",
                height: "calc(90vh - 72px)",
                width: "100%"
            }}
        >
            {pickedProduct ? (
                <PickingInProgress productToPick={pickedProduct.ordered_product} />
            ) : (
                <></>
            )}
        </Box>
    );
};

export default CurrentPickedProduct;
