import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ChoiceButton from "components/buttons/ChoiceButton";
import ProductCard from "components/productCard/ProductCard";
import ScreenTitle from "components/screenTitle";

import { clearErrorMessage } from "store/errorSlice";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { clearProduct, selectProduct } from "store/productSlice";
import { enableCloseStock } from "store/stockSlice";

const ProductOverview = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const product = useAppSelector(selectProduct);

    useEffect(() => {
        dispatch(enableCloseStock(true));
        dispatch(clearErrorMessage());

        // Redirects if no product found
        if (!product.sku && !product.item_qr) {
            navigate("/studio/product/open");
        }
    }, []);

    /**
     * Cancel product edition
     */
    function handleCancel(): void {
        dispatch(clearProduct());
        navigate(-1);
    }

    return (
        <div>
            <ScreenTitle text={"Produit sélectionné"} />
            {product && <ProductCard product={product} />}
            <div>
                <ChoiceButton
                    color="error"
                    onClick={handleCancel}
                    text="Annuler"
                    variant="outlined"
                />
                <ChoiceButton
                    onClick={() => {
                        navigate("/studio/product/accept");
                    }}
                    text="Editer"
                    variant="contained"
                />
            </div>
        </div>
    );
};

export default ProductOverview;
