import { type RootState } from ".";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type ProductState, type ProductPhoto, type RatedCriteria } from "types/product";

const initialState: ProductState = {
    sku: undefined,
    stock: undefined,
    item_qr: undefined,
    photos: undefined,
    prices: undefined,
    brand: undefined,
    material: undefined,
    category: undefined,
    condition: undefined,
    color: undefined,
    size: undefined,
    attractivity_tag: undefined,
    public_notes: undefined,
    internal_notes: undefined,
    admin_status: "DRAFT",
    stock_status: "ACCEPTED"
};

export const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        addItemQr: (state: ProductState, action: PayloadAction<string>) => {
            state.item_qr = action.payload;
            return state;
        },
        updateProduct: (state: ProductState, action: PayloadAction<ProductState>) => {
            state = action.payload;
            return state;
        },
        addProductCondition: (state: ProductState, action: PayloadAction<RatedCriteria>) => {
            state.condition = action.payload;
            return state;
        },
        addProductPhotos: (state: ProductState, action: PayloadAction<ProductPhoto>) => {
            state.photos
                ? (state.photos = [...state.photos, action.payload])
                : (state.photos = [action.payload]);
            return state;
        },
        clearProductPhoto: (state: ProductState, action: PayloadAction<number>) => {
            state.photos = state.photos?.filter((p) => p.id !== action.payload);
            return state;
        },
        clearProduct: (state: ProductState) => {
            state = initialState;
            return state;
        }
    }
});

export const {
    addItemQr,
    updateProduct,
    addProductCondition,
    addProductPhotos,
    clearProductPhoto,
    clearProduct
} = productSlice.actions;
export const selectProduct = (state: RootState): ProductState => state.product;

export default productSlice.reducer;
