import {
    CONTROL_ATTRACTIVITY_TAGS,
    CONTROL_BRANDS,
    CONTROL_CATEGORIES,
    CONTROL_UNIVERSES,
    CONTROL_SIZES,
    CONTROL_WORN_PHOTOS
} from "constants/usersRules";

// Define what blocks to display for each control task
const useControlTasksDisplay = (key: string, currentTask: string): boolean | undefined => {
    const taskElements: Array<{ key: string; task: string[] }> = [
        {
            key: "SKU_BLOCK",
            task: [
                CONTROL_ATTRACTIVITY_TAGS,
                CONTROL_BRANDS,
                CONTROL_CATEGORIES,
                CONTROL_UNIVERSES,
                CONTROL_WORN_PHOTOS,
                CONTROL_SIZES
            ]
        },
        // Photos to display
        {
            key: "PHOTO_COVER",
            task: [
                CONTROL_ATTRACTIVITY_TAGS,
                CONTROL_BRANDS,
                CONTROL_CATEGORIES,
                CONTROL_UNIVERSES,
                CONTROL_WORN_PHOTOS,
                CONTROL_SIZES
            ]
        },
        { key: "PHOTO_LABEL", task: [CONTROL_BRANDS, CONTROL_UNIVERSES, CONTROL_SIZES] },
        { key: "PHOTO_BRAND", task: [CONTROL_BRANDS, CONTROL_UNIVERSES, CONTROL_SIZES] },
        { key: "PHOTO_WHITE_BACKGROUND_WORN", task: [CONTROL_WORN_PHOTOS] },
        // Products description
        { key: "BRAND_BLOCK", task: [CONTROL_ATTRACTIVITY_TAGS] },
        { key: "CAT3_SIZE_BLOCK", task: [CONTROL_CATEGORIES] },
        { key: "CAT3_MATERIAL_BLOCK", task: [CONTROL_ATTRACTIVITY_TAGS] },
        { key: "CONDITION_BLOCK", task: [CONTROL_ATTRACTIVITY_TAGS] },
        { key: "LAST_UPDATED_BLOCK", task: [CONTROL_ATTRACTIVITY_TAGS] },
        // Form field
        { key: "ATTRACTIVITY_TAGS_FIELDS", task: [CONTROL_ATTRACTIVITY_TAGS] },
        { key: "BRAND_FIELDS", task: [CONTROL_BRANDS] },
        { key: "UNIVERSE_FIELDS", task: [CONTROL_UNIVERSES] },
        { key: "CATEGORY_FIELDS", task: [CONTROL_CATEGORIES] },
        { key: "SIZE_FIELDS", task: [CONTROL_SIZES] },
        // Options
        {
            key: "EDIT_BUTTON",
            task: [CONTROL_ATTRACTIVITY_TAGS, CONTROL_BRANDS, CONTROL_CATEGORIES, CONTROL_SIZES]
        },
        { key: "UNDO_BUTTON", task: [CONTROL_UNIVERSES] },
        { key: "DELETE_PHOTO_BUTTON", task: [CONTROL_WORN_PHOTOS] }
    ];

    const item = taskElements.find((element) => {
        return element.key === key;
    });
    return item?.task.includes(currentTask);
};

export { useControlTasksDisplay };
