import { type Dispatch, type FC, useEffect, useState } from "react";

import { type PricelessProduct } from "types/product";

import PhotoCarousel from "./PhotoCarousel";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import SearchIcon from "@mui/icons-material/Search";
import DiamondIcon from "@mui/icons-material/Diamond";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { clearPrice, selectPrice, setPriceToPost } from "store/priceSlice";

import formStyle from "components/formFields/formField.module.css";
import { handleCallErrorWithLoader } from "services/exceptions";
import { postPrice } from "services/products";

interface Props {
    product: PricelessProduct;
    productIsDesigner: boolean;
    setProcessedProduct: Dispatch<string | undefined>;
}

const PricingRow: FC<Props> = ({ product, productIsDesigner, setProcessedProduct }) => {
    const dispatch = useAppDispatch();
    const price = useAppSelector(selectPrice);

    const [isCarouselVisible, setIsCarouselVisible] = useState<boolean>(false);
    const [searchUrl, setSearchUrl] = useState<string>();
    const [fieldLabel, setFieldLabel] = useState<string>("Prix Luxe (TBE)");

    // Define Search URL link for each product
    useEffect(() => {
        if (productIsDesigner) {
            setSearchUrl(buildUrlVC(product));
        } else {
            setSearchUrl(buildUrlGoogle(product));
        }
    });

    const buildUrlVC = (product: PricelessProduct): string => {
        const slugBrand = product.brand ? encodeURIComponent(product.brand.label) : "";
        const slugCategory = product.category ? encodeURIComponent(product.category.label) : "";
        const slugMaterial = product.material ? encodeURIComponent(product.material.label) : "";

        return (
            `https://fr.vestiairecollective.com/search/` +
            `?q=${slugCategory}%20${slugBrand}%20${slugMaterial}#sellerBadge=Expert-Recommandé`
        );
    };

    const buildUrlGoogle = (product: PricelessProduct): string => {
        const slugCategory = product.category ? encodeURIComponent(product.category.label) : "";
        const slugBrand = product.brand ? encodeURIComponent(product.brand.label) : "";

        return product.category && product.brand
            ? "https://www.google.fr/search?q=" + encodeURIComponent(`${slugCategory} ${slugBrand}`)
            : "";
    };

    // Price choice on button clic : 'neuf' or 'seconde main' - Set price type on priceStore
    const handlePriceType = (type: string): void => {
        dispatch(setPriceToPost({ product: product.sku }));
        if (type === "NEW") {
            setFieldLabel("Prix neuf");
        } else if (type === "DIRECT") {
            setFieldLabel("Prix seconde main");
        }
    };

    // On focus : set price product on priceStore
    const handleFocus = (): void => {
        if (!price.status) {
            dispatch(setPriceToPost({ status: "DESIGNER", product: product.sku }));
        } else {
            dispatch(setPriceToPost({ ...price, product: product.sku, value: undefined }));
        }
    };

    // Set evaluated price value on priceStore
    const handlePriceChange = (value: number): void => {
        dispatch(setPriceToPost({ ...price, value }));
    };

    // function on price validation - POST api call & removing row from table
    const handlePriceValidation = async (): Promise<void> => {
        const result = await handleCallErrorWithLoader(dispatch, postPrice, price);
        dispatch(clearPrice());
        if (result === 201) {
            setProcessedProduct(product.sku);
        }
    };

    return (
        <>
            {isCarouselVisible ? (
                <PhotoCarousel
                    isCarouselVisible={isCarouselVisible}
                    picture={product.photos}
                    setIsCarouselVisible={setIsCarouselVisible}
                />
            ) : null}
            <tr>
                <td>
                    {product.photos && (
                        <img
                            alt={product.sku}
                            onClick={() => {
                                setIsCarouselVisible(true);
                            }}
                            src={product.photos[0]?.photo_url}
                        />
                    )}
                </td>
                <td>
                    {product.brand?.label}
                    {product.brand?.is_top_criteria ? (
                        <DiamondIcon color="secondary" sx={{ mb: -1, ml: 0.5 }} />
                    ) : null}
                </td>
                <td>{product.category?.label}</td>
                <td>
                    <a href={searchUrl} rel="noopener noreferrer" target="_blank">
                        <IconButton aria-label="Recherche" edge="end">
                            <SearchIcon />
                        </IconButton>
                    </a>
                </td>
                <td>
                    {(fieldLabel !== "Prix Luxe (TBE)" && product.sku === price.product) ||
                    productIsDesigner ? (
                        <TextField
                            className={formStyle.ScanInputField}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        <InputAdornment position="end">€</InputAdornment>
                                        <IconButton
                                            aria-label="Envoi"
                                            disabled={!price.value}
                                            edge="end"
                                            onClick={handlePriceValidation}
                                        >
                                            <DoneIcon />
                                        </IconButton>
                                    </>
                                )
                            }}
                            label={fieldLabel}
                            onChange={(e) => {
                                handlePriceChange(parseInt(e.target.value));
                            }}
                            onFocus={handleFocus}
                            type="number"
                            value={product.sku === price.product ? price.value : ""}
                            variant="outlined"
                        />
                    ) : (
                        <>
                            <Button
                                color="primary"
                                onClick={(e) => {
                                    handlePriceType("NEW");
                                }}
                                size="small"
                                sx={{ mx: 1 }}
                                variant="outlined"
                            >
                                Neuf
                            </Button>
                            <Button
                                color="primary"
                                onClick={(e) => {
                                    handlePriceType("DIRECT");
                                }}
                                size="small"
                                sx={{ mx: 1 }}
                                variant="outlined"
                            >
                                Seconde main
                            </Button>
                        </>
                    )}
                </td>
            </tr>
        </>
    );
};

export default PricingRow;
