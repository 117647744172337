import { useState } from "react";

import { Box, Button, Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

import type { ProductPhoto } from "types/product";

interface Props {
    photo: ProductPhoto;
}

const ControlPhoto = ({ photo }: Props): JSX.Element => {
    const [isPhotoZoomedVisible, setIsPhotoZoomedVisible] = useState<boolean>(false);

    // use localHost for local build
    const photosBaseUrl: string = import.meta.env.VITE_APP_LOCAL_BASE_URL ?? "";

    return (
        <Box>
            {photo && (
                <Button
                    onClick={() => {
                        setIsPhotoZoomedVisible(true);
                    }}
                    sx={{ width: "200px" }}
                >
                    <img
                        alt={photo.id.toString()}
                        height="auto"
                        src={`${photosBaseUrl}${photo.photo_url}`}
                        width="100%"
                    />
                    <ZoomInIcon
                        color="secondary"
                        sx={{ position: "absolute", right: "8px", top: "10px" }}
                    />
                </Button>
            )}
            <Dialog
                onClose={() => {
                    setIsPhotoZoomedVisible(false);
                }}
                open={isPhotoZoomedVisible}
            >
                <Box>
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setIsPhotoZoomedVisible(false);
                        }}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: 600,
                        justifyContent: "center",
                        pt: 6,
                        px: 5,
                        width: 450
                    }}
                >
                    <img
                        alt={photo.id.toString()}
                        src={`${photosBaseUrl}${photo.photo_url}`}
                        style={{
                            maxHeight: "100%",
                            objectFit: "contain",
                            width: "100%"
                        }}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default ControlPhoto;
