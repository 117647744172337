import { createTheme, alpha } from "@mui/material/styles";

// A custom theme for this app
let theme = createTheme({
    spacing: 8,
    palette: {
        primary: {
            main: "#25402B"
        },
        secondary: {
            main: "#989E8F"
        },
        warning: {
            main: "#d5a021"
        },
        info: {
            main: "#b6c1f1"
        },
        divider: "#25402B"
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    zIndex: 100
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    zIndex: 50
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                dense: {
                    height: 40,
                    minHeight: 40
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    minHeight: 35.5
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    "&.MuiToggleButton-root.Mui-selected": {
                        backgroundColor: "#c1c9b6"
                    }
                }
            }
        }
    }
});

// Add custom colors
theme = createTheme(theme, {
    palette: {
        green: theme.palette.augmentColor({
            color: {
                main: alpha("#93C39E", 0.6)
            },
            name: "green"
        }),
        purple: theme.palette.augmentColor({
            color: {
                main: alpha("#7E88DE", 0.6)
            },
            name: "purple"
        }),
        yellow: theme.palette.augmentColor({
            color: {
                main: alpha("#D5A02199", 0.6)
            },
            name: "yellow"
        }),
        light: theme.palette.augmentColor({
            color: {
                main: alpha("#ffffff", 1)
            },
            name: "light"
        })
    }
});

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        green: true;
        purple: true;
        yellow: true;
        light: true;
    }
}

export default theme;
