import { useState } from "react";

import { handlePhotosSorting } from "helpers/photos/handlePhotosSorting";
import getInventoryStateColor from "helpers/studio/getInventoryStateColor";

import { Box, Button, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ProductCancelDialog from "./ProductCancelDialog";

import type { ProductState } from "types/product";

interface Props {
    item: ProductState;
    setScannedProducts: React.Dispatch<React.SetStateAction<ProductState[]>>;
    scannedProducts: ProductState[];
}
const ProductGridItem = ({ item, setScannedProducts, scannedProducts }: Props): JSX.Element => {
    // use localHost for local build
    const photosBaseUrl: string = import.meta.env.VITE_APP_LOCAL_BASE_URL ?? "";

    const [isProdcutCancelDialogOpen, setIsProdcutCancelDialogOpen] = useState<boolean>(false);

    const photoUrl = item.photos?.length ? handlePhotosSorting(item.photos)[0].photo_url : "";

    return (
        <Grid item xs={3}>
            {item.photos?.[0]?.photo_url && (
                <>
                    <Typography
                        color="error"
                        sx={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            mb: "20px"
                        }}
                    >
                        {item.inventory_product_state}
                    </Typography>
                    <Box
                        sx={{
                            aspectRatio: 0.7,
                            backgroundImage: `url(${photosBaseUrl}${String(photoUrl)})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            boxShadow: item.inventory_product_state
                                ? `0 0 0 10px ${getInventoryStateColor(
                                      item.inventory_product_state
                                  )}`
                                : "none",
                            boxSizing: "border-box",
                            padding: item.inventory_product_state ? 2 : 0,
                            position: "relative",
                            width: "100%"
                        }}
                    >
                        <Button
                            onClick={() => {
                                setIsProdcutCancelDialogOpen(true);
                            }}
                            sx={{
                                alignItems: "flex-start",
                                display: "flex",
                                height: "100%",
                                justifyContent: "flex-end",
                                padding: "0",
                                position: "relative",
                                width: "100%"
                            }}
                        >
                            <CloseIcon
                                color="error"
                                sx={{
                                    padding: "0",
                                    position: "absolute",
                                    right: "0",
                                    top: "0"
                                }}
                            />
                        </Button>
                    </Box>
                </>
            )}

            <Box
                sx={{
                    mt: 1,
                    textAlign: "center"
                }}
            >
                <Typography
                    sx={{
                        fontSize: "12px",
                        fontWeight: "bold"
                    }}
                >
                    {item.item_qr}
                </Typography>
                <Typography
                    color={item.brand?.label ? "primary" : "error"}
                    sx={{
                        fontSize: "12px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}
                >
                    {item.brand?.label ?? "Sans marque"}
                </Typography>
                {item.inventory === 0 && (
                    <Typography
                        color="error"
                        sx={{
                            fontSize: "12px",
                            fontWeight: "bold"
                        }}
                    >
                        ⚠️ Hors stock
                    </Typography>
                )}
            </Box>

            <ProductCancelDialog
                isProdcutCancelDialogOpen={isProdcutCancelDialogOpen}
                item={item}
                scannedProducts={scannedProducts}
                setIsProdcutCancelDialogOpen={setIsProdcutCancelDialogOpen}
                setScannedProducts={setScannedProducts}
            />
        </Grid>
    );
};

export default ProductGridItem;
