import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import ChoiceButton from "components/buttons/ChoiceButton";
import ScreenTitle from "components/screenTitle";

import { setCurrentGroup } from "store/authenticationSlice";
import { useAppDispatch } from "store/hooks";

import { STUDIO_USER_GROUP, WAREHOUSE_USER_GROUP } from "constants/usersRules";

const FeatureSwitch = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    function handleGroupChoice(group: string, path: string): void {
        dispatch(setCurrentGroup(group));
        navigate(path);
    }

    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                gap: 1
            }}
        >
            <ScreenTitle text={`Super user : Choix de groupe`} />
            <ChoiceButton
                onClick={() => {
                    handleGroupChoice(STUDIO_USER_GROUP, "/studio");
                }}
                sx={{
                    minHeight: 50,
                    minWidth: 220
                }}
                text={STUDIO_USER_GROUP}
                variant="contained"
            />
            <ChoiceButton
                onClick={() => {
                    handleGroupChoice(WAREHOUSE_USER_GROUP, "/warehouse");
                }}
                sx={{
                    minHeight: 50,
                    minWidth: 220
                }}
                text={WAREHOUSE_USER_GROUP}
                variant="contained"
            />
        </Box>
    );
};

export default FeatureSwitch;
