import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { fetchColors } from "services/criterias";
import { handleCallErrorWithLoader } from "services/exceptions";

import { Box, FormControl, ToggleButton, Typography } from "@mui/material";
import FormField from "components/formFields/FormField";

import { colorsValues } from "./colorsValues";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectProduct } from "store/productSlice";

import type { Control } from "react-hook-form";
import type { Criteria } from "types/product";

interface ColorsToggleParams {
    control: Control;
}

const ColorsToggle = ({ control }: ColorsToggleParams): JSX.Element => {
    const dispatch = useAppDispatch();
    const product = useAppSelector(selectProduct);

    const [colorsList, setColorsList] = useState<Criteria[]>([]);
    const [selectedColor, setSelectedColor] = useState<Criteria>();
    const [displayedColors, setDisplayedColors] = useState<string[]>([]);

    useEffect(() => {
        handleCallErrorWithLoader(dispatch, fetchColors).then((result) => {
            setColorsList(result);
        });
    }, []);

    useEffect(() => {
        if (product.color != null) {
            setSelectedColor(product.color);
        }
    }, [product.color]);

    /**
     * Create displayed colors array with css gradient var for color buttons
     */
    useEffect(() => {
        for (let i = 0; i < colorsList.length; i++) {
            const color = colorsValues.find((code) => code.label === colorsList[i].label);
            const colorCode = color != null ? color.value : "#000000,#000000";
            setDisplayedColors((displayedColors) => [...displayedColors, colorCode]);
        }
    }, [colorsList]);

    return (
        <Controller
            control={control}
            defaultValue={product.color != null ? product.color : undefined}
            name={"color"}
            render={({ field: { onChange, value, ref } }) => (
                <FormControl fullWidth>
                    <FormField>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center"
                            }}
                        >
                            <Typography
                                component="legend"
                                sx={{
                                    width: "100%",
                                    textAlign: "start",
                                    color: "#233126c4"
                                }}
                            >
                                {"Couleur"}
                                {selectedColor != null && <span> : {selectedColor.label}</span>}
                            </Typography>

                            {colorsList?.map((color, id) => (
                                <ToggleButton
                                    key={id}
                                    ref={ref}
                                    name={color.label}
                                    onChange={(event, newValue) => {
                                        setSelectedColor(newValue);
                                        onChange(newValue);
                                        return newValue;
                                    }}
                                    selected={selectedColor?.label === color.label}
                                    sx={{
                                        width: "55px",
                                        height: "55px",
                                        margin: 0.5,
                                        background: `linear-gradient(-45deg, 
                                            ${displayedColors[id]})`,
                                        "&.Mui-selected, &:hover, &:active": {
                                            border: "3px solid #25402B",
                                            background:
                                                `linear-gradient` +
                                                `(-45deg, ${displayedColors[id]})`,
                                            boxShadow: "inset 1px 1px 10px white"
                                        }
                                    }}
                                    value={color}
                                />
                            ))}
                        </Box>
                    </FormField>
                </FormControl>
            )}
        />
    );
};

export default ColorsToggle;
