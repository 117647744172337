import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { type Control, Controller } from "react-hook-form";

import { Box, FormControl, Rating, Typography } from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { type RatedCriteria } from "types/product";

interface RatingParams {
    colorsList?: string[];
    control: Control;
    defaultColor?: string;
    emptyIcon?: JSX.Element;
    existantCriteria?: RatedCriteria;
    icon?: JSX.Element;
    isRequired?: boolean;
    label: string;
    name: string;
    optionalOnChange?: (value: RatedCriteria, index?: number) => void;
    optionsList: RatedCriteria[];
}

const RatingScale = forwardRef<any, RatingParams>(
    (
        {
            colorsList,
            control,
            defaultColor,
            emptyIcon,
            existantCriteria,
            icon,
            isRequired,
            label,
            name,
            optionalOnChange,
            optionsList
        }: RatingParams,
        ref
    ): JSX.Element => {
        const [selectedCriteria, setSelectedCriteria] = useState<RatedCriteria>();

        const getColorValue: any = () => {
            return defaultColor ?? colorsList?.[selectedCriteria?.rating ?? 0] ?? "#000000";
        };

        useEffect(() => {
            if (existantCriteria) {
                setSelectedCriteria(existantCriteria);
            }
        }, [existantCriteria]);

        return (
            <FormControl fullWidth>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center"
                    }}
                >
                    <Typography
                        component="legend"
                        sx={{
                            width: "100%",
                            textAlign: "start",
                            color: isRequired ? "#D32F2F" : "#233126c4"
                        }}
                    >
                        {label}
                        {isRequired && ` *`}
                    </Typography>
                    <Controller
                        control={control}
                        defaultValue={existantCriteria ?? null}
                        name={name}
                        render={({ field: { onChange, value } }) => {
                            useImperativeHandle(ref, () => ({
                                setValue(newValue: any) {
                                    if (newValue) {
                                        setSelectedCriteria(newValue);
                                        optionalOnChange && newValue && optionalOnChange(newValue);
                                        onChange(newValue);
                                    }
                                }
                            }));

                            return (
                                <Rating
                                    emptyIcon={emptyIcon ?? <StarBorderIcon fontSize="inherit" />}
                                    icon={icon && icon}
                                    max={optionsList.length}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            const crit = optionsList.find(
                                                (option) => option.rating === newValue
                                            );
                                            setSelectedCriteria(crit);
                                            optionalOnChange && crit && optionalOnChange(crit);
                                            onChange(crit);
                                        }
                                    }}
                                    sx={{
                                        fontSize: "42px",
                                        "& .MuiRating-iconFilled": {
                                            color: getColorValue()
                                        }
                                    }}
                                    value={
                                        selectedCriteria?.rating ??
                                        existantCriteria?.rating ??
                                        value
                                    }
                                />
                            );
                        }}
                    />
                    {selectedCriteria && <Box sx={{ ml: 2 }}>{selectedCriteria.label}</Box>}
                </Box>
            </FormControl>
        );
    }
);

RatingScale.displayName = "RatingScale";

export default RatingScale;
