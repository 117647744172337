import { useEffect, useState } from "react";

import FormField from "components/formFields/FormField";
import TextInputField from "components/formFields/TextInputField";

import { selectProduct } from "store/productSlice";
import { useAppSelector } from "store/hooks";

import type { Control, UseFormSetValue, FieldValues } from "react-hook-form";
import type { UsualValue } from "types/main";

interface Props {
    control: Control;
    existantNote?: string;
    formatNotes: (notesList: UsualValue[]) => string;
    isRequired?: boolean;
    noteType: "flaws_notes" | "public_notes";
    selectedUsualValues: UsualValue[];
    setValue: UseFormSetValue<FieldValues>;
}

const NotesInputField = ({
    control,
    existantNote,
    formatNotes,
    isRequired,
    noteType,
    selectedUsualValues,
    setValue
}: Props): JSX.Element => {
    const product = useAppSelector(selectProduct);
    const noteTypesTranslations = {
        flaws_notes: "Défauts",
        public_notes: "Notes publiques"
    };

    const [isNoteReminderVisible, setIsNoteReminderVisible] = useState<boolean>(false);

    /**
     * Fill note field with selected usual value(s)
     */
    useEffect(() => {
        selectedUsualValues.length
            ? setIsNoteReminderVisible(true)
            : setIsNoteReminderVisible(false);

        let formatedNotes = formatNotes(selectedUsualValues);

        if (existantNote) {
            if (formatedNotes.length) {
                formatedNotes += "\r\n";
            }
            formatedNotes += String(existantNote);
        }

        setValue(noteType, formatedNotes);
    }, [product, selectedUsualValues]);

    return (
        <FormField>
            <TextInputField
                control={control}
                error={isNoteReminderVisible}
                existantNote={product.public_notes}
                isRequired={isRequired}
                label={
                    isNoteReminderVisible
                        ? `${noteTypesTranslations[noteType]} : ne pas oublier de compléter`
                        : `${noteTypesTranslations[noteType]} (affichées sur le site)`
                }
                minRows={3}
                multiline
                name={noteType}
            />
        </FormField>
    );
};

export default NotesInputField;
