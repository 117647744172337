import { useEffect, useRef, useState } from "react";

import { fetchConditions } from "services/criterias";
import { handleCallErrorWithLoader } from "services/exceptions";
import { useSpeechRecognition } from "react-speech-recognition";

import FormField from "components/formFields/FormField";
import RatingScale from "components/formFields/RatingScale";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { addProductCondition, selectProduct } from "store/productSlice";

import type { Control } from "react-hook-form";
import type { RatedCriteria } from "types/product";

interface Props {
    control: Control;
    isRequired?: boolean;
}

const numberMap: Record<string, number> = {
    un: 4,
    "1": 4,
    deux: 3,
    de: 3,
    "2": 3,
    trois: 2,
    "3": 2,
    quatre: 1,
    "4": 1
};

const ConditionRatingScale = ({ control, isRequired }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const product = useAppSelector(selectProduct);
    const { resetTranscript } = useSpeechRecognition();

    const [conditionsList, setConditionsList] = useState<RatedCriteria[]>([]);
    const [currentCondition] = useState(product.condition);
    const conditionRef = useRef<any>(null);

    useEffect(() => {
        handleCallErrorWithLoader(dispatch, fetchConditions).then((result) => {
            setConditionsList(result);
        });
    }, []);

    /**
     * Condition store saving
     */
    function onConditionChange(data: RatedCriteria): void {
        dispatch(addProductCondition(data));
    }

    const commands = [
        {
            command: /conditions? (un|1|deux|de|2|trois|3|quatre|4)/i,
            callback: (condition: string) => {
                const conditionIndex = numberMap[condition.toString()];
                const conditionItem = conditionsList.find((item) => item.id === conditionIndex);
                conditionRef.current.setValue(conditionItem);

                resetTranscript();
            }
        }
    ];

    useSpeechRecognition({ commands });

    return (
        <FormField>
            <RatingScale
                ref={conditionRef}
                colorsList={["#25402B", "#d32f2f", "#D5A021", "#7E8BD8", "#93C39E"]}
                control={control}
                existantCriteria={currentCondition}
                isRequired={isRequired}
                label={"Etat du produit 🎤: Condition XX (1,2,3 ou 4)"}
                name="condition"
                optionalOnChange={onConditionChange}
                optionsList={conditionsList}
            />
        </FormField>
    );
};

export default ConditionRatingScale;
