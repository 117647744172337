import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { type SelectChangeEvent } from "@mui/material/Select";

import { useAppDispatch } from "store/hooks";
import { submitStudio } from "store/settingsSlice";
import { clearStock } from "store/stockSlice";

import { ROLE_1, ROLE_2, ROLE_LOCATION, ROLE_CONTROL } from "constants/usersRules";

const Settings = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const rolesList = [
        [ROLE_1, "Check + Fiche"],
        [ROLE_2, "Photos"],
        [ROLE_LOCATION, "Rangement"],
        [ROLE_CONTROL, "Contrôle - Studio"]
    ];

    const [selectedRole, setSelectedRole] = useState<string>("");

    // Get global settings
    useEffect(() => {
        const currentRole = localStorage.getItem("settings.role");
        if (currentRole) {
            setSelectedRole(currentRole);
            dispatch(submitStudio(currentRole));
        }
    }, []);

    // Save role when changed
    // Clear current stock from local storage and store
    // Navigate to Home
    const handleStudioChange = (event: SelectChangeEvent): void => {
        setSelectedRole(event.target.value);
        dispatch(submitStudio(event.target.value));
        dispatch(clearStock());
        localStorage.setItem("stock", "");
        navigate("/studio");
    };

    return (
        <Container maxWidth="sm">
            <Fragment>
                <Typography component="h1" variant="h5">
                    Settings
                </Typography>

                <FormControl fullWidth>
                    <InputLabel id="settings-role-label">Rôle</InputLabel>
                    <Select
                        id="settings-role"
                        label="Studio"
                        labelId="settings-role-label"
                        onChange={handleStudioChange}
                        value={selectedRole}
                    >
                        {rolesList.map((role) => {
                            return (
                                <MenuItem key={role[0]} value={role[0]}>
                                    {role[1]}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Fragment>
        </Container>
    );
};

export default Settings;
