// Users groups
export const STUDIO_USER_GROUP = "Studio";
export const WAREHOUSE_USER_GROUP = "Entrepôt";

// Studio Roles
export const ROLE_1 = "ROLE_1";
export const ROLE_2 = "ROLE_2";
export const ROLE_CONTROL = "ROLE_CONTROL";
export const ROLE_LOCATION = "ROLE_LOCATION";

// Control tasks in Controls studio role
export const CONTROL_ATTRACTIVITY_TAGS = "ATTRACTIVITY_TAGS";
export const CONTROL_BRANDS = "BRANDS";
export const CONTROL_CATEGORIES = "CATEGORIES";
export const CONTROL_UNIVERSES = "UNIVERSES";
export const CONTROL_SIZES = "SIZES";
export const CONTROL_WORN_PHOTOS = "WORN_PHOTOS";
