import { Box } from "@mui/material";

interface Props {
    height: number;
    width: number;
}

const Scanningframe = ({ height, width }: Props): any => {
    return (
        <Box
            sx={{
                border: "solid 1px red",
                height: `${height}%`,
                left: `${(100 - width) / 2}%`,
                position: "absolute",
                top: `${(100 - height) / 2}%`,
                width: `${width}%`
            }}
        />
    );
};

export default Scanningframe;
