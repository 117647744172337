import { Box, Typography } from "@mui/material";
import BarcodeQrScanner from "./BarcodeQrScanner";
import ChoiceButton from "components/buttons/ChoiceButton";

interface Props {
    codetype: string;
    item: string;
    setIsInputFieldVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setScannedQr: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ScanningBlock = ({ codetype, item, setIsInputFieldVisible, setScannedQr }: Props): any => {
    return (
        <Box>
            <BarcodeQrScanner setScannedQr={setScannedQr} />
            <Typography>{`☝️ Scanner le ${codetype} du ${item} ☝️`}</Typography>
            <Box sx={{ marginTop: 8 }}>
                <ChoiceButton
                    color="secondary"
                    onClick={() => {
                        setIsInputFieldVisible(true);
                    }}
                    text="Entrer le code à la main"
                    variant="contained"
                />
            </Box>
        </Box>
    );
};

export default ScanningBlock;
