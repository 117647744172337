import { useNavigate, useParams } from "react-router-dom";

import { IconButton } from "@mui/material";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";

const CartButton = (): JSX.Element => {
    const navigate = useNavigate();
    const { picking_rail_id } = useParams();

    return (
        <>
            <IconButton
                aria-label="Overview"
                color="inherit"
                onClick={() => {
                    navigate(`/warehouse/pickingrails/${String(picking_rail_id)}/overview`);
                }}
                sx={{ backgroundColor: "#cfcfcf", border: "solid 1px #989E8F" }}
            >
                <ShoppingCartCheckoutIcon sx={{ fontSize: "45px" }} />
            </IconButton>
        </>
    );
};

export default CartButton;
