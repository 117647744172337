import type { LocalStockState } from "types/stock";

export function handleStockFormatting(stock: any): LocalStockState {
    return {
        barcode: stock.barcode,
        enableCloseStock: true,
        numberOfDeclaredItems: stock.number_of_declared_items,
        numberOfProcessedProducts: stock.number_of_processed_products
    };
}
