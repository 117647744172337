import { type compose } from "redux";
import { configureStore, type ThunkAction, type Action } from "@reduxjs/toolkit";
import authenticationReducer from "./authenticationSlice";
import errorReducer from "./errorSlice";
import loaderReducer from "./loaderSlice";
import pickingReducer from "./pickingSlice";
import productReducer from "./productSlice";
import priceReducer from "./priceSlice";
import settingsReducer from "./settingsSlice";
import stockReducer from "./stockSlice";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

export const store = configureStore({
    reducer: {
        error: errorReducer,
        loader: loaderReducer,
        picking: pickingReducer,
        product: productReducer,
        price: priceReducer,
        settings: settingsReducer,
        stock: stockReducer,
        authentication: authenticationReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
