import { useEffect, useRef, useState } from "react";

import { getWorthSize } from "services/criterias";

import { Typography } from "@mui/material";
import AutocompleteField from "components/formFields/AutocompleteField";
import FormField from "components/formFields/FormField";
import { useSpeechRecognition } from "react-speech-recognition";

import { selectProduct } from "store/productSlice";
import { useAppSelector } from "store/hooks";

import type { Criteria, NestedCriteria, SizeTypeCriteria } from "types/product";
import type { Control, UseFormSetValue, FieldValues } from "react-hook-form";

import formStyles from "../../ProductForm.module.css";

interface Props {
    control: Control;
    categoryToPost?: NestedCriteria;
    disabled?: boolean;
    isRequired?: boolean;
    optionalSetSelectedSize?: React.Dispatch<React.SetStateAction<Criteria | undefined>>;
    setValue: UseFormSetValue<FieldValues>;
}

interface AutocompleteFieldRef {
    setValue: (newValue: any) => void;
}

const SizeAutocompleteField = ({
    control,
    categoryToPost,
    disabled,
    isRequired,
    optionalSetSelectedSize,
    setValue
}: Props): JSX.Element => {
    const product = useAppSelector(selectProduct);

    const sizesData = localStorage.getItem("sizes");
    const defaultSizeType = { id: 1, label: "EU" };

    const [isSizesDataAvailable, setIsSizesDataAvailable] = useState<boolean>(true);
    const [sizesList, setSizesList] = useState<Criteria[]>([]);
    const [sizeTypesList, setSizeTypesList] = useState<SizeTypeCriteria[]>([]);
    const [currentSizeType, setCurrentSizeType] = useState<Criteria>();
    const [currentSize, setCurrentSize] = useState<Criteria>();
    const [currentWorthSize, setCurrentWorthSize] = useState<string>();

    useEffect(() => {
        try {
            sizesData && setSizeTypesList(JSON.parse(sizesData));
            setCurrentSizeType(defaultSizeType);
        } catch (e) {
            setIsSizesDataAvailable(false);
        }
        !sizesData && setIsSizesDataAvailable(false);
    }, []);

    // Get sizes list from local storage for a type
    function getSizesFromStoredTypes(sizeType: Criteria): Criteria[] {
        const sizeTypeData = sizeTypesList?.find((type) => type.label === sizeType.label);
        return sizeTypeData?.sizes ?? [];
    }

    // When size type is changed, clear size field and worth size info
    function onSizeTypeChange(value: Criteria): void {
        setCurrentWorthSize("");
        setCurrentSizeType(value);
        setValue("size", null);
    }

    // When size type is changed, display related sizes options
    useEffect(() => {
        currentSizeType && setSizesList(getSizesFromStoredTypes(currentSizeType));
    }, [currentSizeType]);

    function onSizeLabelChange(value: Criteria): void {
        setCurrentSize(value);
        if (optionalSetSelectedSize) {
            optionalSetSelectedSize(value);
        }
    }

    const sizesRef = useRef<AutocompleteFieldRef>(null);
    const sizeTypesRef = useRef<AutocompleteFieldRef>(null);
    const commands = [
        {
            command: "format :condition",
            callback: (format: string) => {
                if (sizeTypesRef.current) {
                    sizeTypesRef.current.setValue({
                        id: "new-id",
                        label: `${format.toUpperCase()}`
                    });
                }
            }
        },
        {
            command: /taille(s)? (\w+)/i,
            callback: (_match: string, sizeWord: string) => {
                if (sizesRef.current) {
                    sizesRef.current.setValue({
                        id: "new-id",
                        label: `${sizeWord.toUpperCase()}`
                    });
                }
            }
        }
    ];
    useSpeechRecognition({ commands });

    // When size or category is changed, get worth size
    useEffect(() => {
        if (currentSize && categoryToPost) {
            getWorthSize(categoryToPost.id, currentSize.id).then((result) => {
                result
                    ? setCurrentWorthSize(result)
                    : setCurrentWorthSize("⚠️ Pas d'équivalence trouvée");
            });
        }
    }, [categoryToPost, currentSize]);

    return (
        <div className={formStyles.productForm__multifieldContainer}>
            {isSizesDataAvailable ? (
                <>
                    <FormField>
                        <AutocompleteField
                            ref={sizeTypesRef}
                            control={control}
                            disabled={disabled}
                            existantCriteria={product.size?.type ? undefined : currentSizeType}
                            label={"Format taille  🎤: Format XX"}
                            name="sizeType"
                            optionalOnChange={onSizeTypeChange}
                            optionsList={sizeTypesList}
                        />
                    </FormField>
                    <FormField>
                        <AutocompleteField
                            ref={sizesRef}
                            control={control}
                            disabled={disabled}
                            existantCriteria={product.size}
                            isRequired={isRequired}
                            label={"Taille  🎤: Taille XX"}
                            name="size"
                            optionalOnChange={onSizeLabelChange}
                            optionsList={sizesList}
                        />
                    </FormField>
                    {currentWorthSize && (
                        <p className={formStyles.productForm__catTitle}>
                            Equivalence taille : {currentWorthSize}
                        </p>
                    )}
                </>
            ) : (
                <Typography
                    color="error"
                    m={4}
                    sx={{
                        fontSize: "16px",
                        fontWeight: "bold"
                    }}
                >
                    ⚠️ Erreur champ taille : vider le cache du téléphone et retourner à
                    l&apos;accueil du PIM
                </Typography>
            )}
        </div>
    );
};

export default SizeAutocompleteField;
