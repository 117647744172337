import { useEffect, useState } from "react";

import { useControlTasksDisplay } from "hooks/controlTasksDisplay";

import { fetchCategoriesByLabel } from "services/criterias";
import { setErrorMessage } from "store/errorSlice";
import { handleCallError } from "services/exceptions";
import { patchProductInfos } from "services/products";
import { handlePhotosSorting } from "helpers/photos/handlePhotosSorting";

import ControlActions from "./Actions";
import ControlPhoto from "./Photo";
import { Box, IconButton, Link, TableCell, TableRow, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import UndoButton from "components/buttons/UndoButton";

import { useAppDispatch } from "store/hooks";

import { CONTROL_UNIVERSES } from "constants/usersRules";
import type {
    ProductState,
    ProductPhoto,
    Criteria,
    NestedCriteria,
    RatedCriteria
} from "types/product";

interface Props {
    attractivitytagsList: RatedCriteria[];
    controlledProducts: string[];
    product: ProductState;
    setControlledProducts: React.Dispatch<React.SetStateAction<string[]>>;
    smallBrand?: Criteria;
    taskName?: string;
    vintageBrand?: Criteria;
}

const ControlViewRow = ({
    attractivitytagsList,
    controlledProducts,
    product,
    setControlledProducts,
    smallBrand,
    taskName,
    vintageBrand
}: Props): JSX.Element => {
    const dispatch = useAppDispatch();

    const [photoList, setPhotoList] = useState<ProductPhoto[]>([]);
    const [isRowDisabled, setIsRowDisabled] = useState<boolean>(false);
    const [isRowLoading, setIsRowLoading] = useState<boolean>(false);

    useEffect(() => {
        product.photos && sortPhotos(product.photos);
    }, []);

    // Filter photos to display for selected control task
    // Display cover photo first
    function sortPhotos(photos: ProductPhoto[]): void {
        const photosToUse = photos.filter((photo: ProductPhoto) =>
            useControlTasksDisplay(`PHOTO_${photo.format}`, taskName?.toUpperCase() ?? "")
        );

        const sortedPhotos = handlePhotosSorting(photosToUse);

        setPhotoList(sortedPhotos);
    }

    // Returns correct row color for disabled / loading / normal row state
    function handleRowColor(): string {
        if (isRowDisabled) {
            return "#d7d4d4";
        } else if (isRowLoading) {
            return "#ede8e8";
        } else {
            return "";
        }
    }

    function handleUndo(): void {
        if (taskName?.toUpperCase() === CONTROL_UNIVERSES) {
            updateProductUniverseToOtherGender("Femme");
        }
    }

    // Change product category :
    // Fetch categories by label and select other gender category
    // Patch product to change universe to other gender
    async function updateProductUniverseToOtherGender(gender: string): Promise<void> {
        setIsRowLoading(true);
        const categories = await handleCallError(
            dispatch,
            fetchCategoriesByLabel,
            product.category?.label
        );
        if (categories.length > 0) {
            const otherGenderCategory: NestedCriteria[] = categories.find(
                (cat: NestedCriteria) => cat.parent?.parent?.label === gender
            );
            if (otherGenderCategory) {
                const result = await handleCallError(
                    dispatch,
                    patchProductInfos,
                    { category: otherGenderCategory },
                    product.sku
                );
                if (result) {
                    setIsRowDisabled(!isRowDisabled);
                    if (product.sku && !controlledProducts.includes(product.sku)) {
                        setControlledProducts([...controlledProducts, product.sku]);
                    }
                }
            } else {
                dispatch(setErrorMessage(`Aucune catégorie équivalente trouvée en ${gender}`));
            }
        } else {
            dispatch(setErrorMessage(`Aucune catégorie trouvée`));
        }
        setIsRowLoading(false);
    }

    return (
        <TableRow sx={{ backgroundColor: handleRowColor }}>
            {useControlTasksDisplay("SKU_BLOCK", taskName?.toUpperCase() ?? "") && (
                <TableCell>
                    <Link
                        href={`${String(import.meta.env.VITE_APP_ADMIN_URL)}core/product/${String(
                            product.sku
                        )}`}
                        rel="noreferrer"
                        sx={{ fontSize: "12px", fontWeight: "bold" }}
                        target="_blank"
                    >
                        {product.sku}
                    </Link>
                </TableCell>
            )}

            <TableCell>
                <Box sx={{ display: "flex", gap: 1 }}>
                    {photoList.map((photo) => {
                        return <ControlPhoto key={photo.id} photo={photo} />;
                    })}
                </Box>
            </TableCell>

            {useControlTasksDisplay("BRAND_BLOCK", taskName?.toUpperCase() ?? "") && (
                <TableCell sx={{ textAlign: "center" }}>
                    <Typography>{product.brand?.label}</Typography>
                </TableCell>
            )}

            {useControlTasksDisplay("CAT3_SIZE_BLOCK", taskName?.toUpperCase() ?? "") && (
                <TableCell sx={{ textAlign: "center" }}>
                    <Typography sx={{ fontWeight: "bold" }}>{product.category?.label}</Typography>
                    <Typography>{product.size?.label}</Typography>
                </TableCell>
            )}

            {useControlTasksDisplay("CAT3_MATERIAL_BLOCK", taskName?.toUpperCase() ?? "") && (
                <TableCell sx={{ textAlign: "center" }}>
                    <Typography sx={{ fontWeight: "bold" }}>{product.category?.label}</Typography>
                    <Typography>{product.material?.label}</Typography>
                </TableCell>
            )}

            {useControlTasksDisplay("CONDITION_BLOCK", taskName?.toUpperCase() ?? "") && (
                <TableCell sx={{ textAlign: "center" }}>
                    <Typography>{product.condition?.label}</Typography>
                </TableCell>
            )}

            <TableCell>
                <ControlActions
                    attractivitytagsList={attractivitytagsList}
                    controlledProducts={controlledProducts}
                    isRowDisabled={isRowDisabled}
                    product={product}
                    setControlledProducts={setControlledProducts}
                    setIsRowDisabled={setIsRowDisabled}
                    setIsRowLoading={setIsRowLoading}
                    smallBrand={smallBrand}
                    taskName={taskName}
                    updateProductUniverseToOtherGender={updateProductUniverseToOtherGender}
                    vintageBrand={vintageBrand}
                />
            </TableCell>

            {useControlTasksDisplay("LAST_UPDATED_BLOCK", taskName?.toUpperCase() ?? "") && (
                <TableCell sx={{ textAlign: "center" }}>
                    <Typography sx={{ fontSize: "12px", fontStyle: "italic" }}>
                        Dernière modif. par : {product.last_updated_by?.username}
                    </Typography>
                </TableCell>
            )}

            <TableCell>
                <Box
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                        gap: 1
                    }}
                >
                    {useControlTasksDisplay("EDIT_BUTTON", taskName?.toUpperCase() ?? "") &&
                        isRowDisabled && (
                            <IconButton
                                aria-label="edit"
                                color="inherit"
                                onClick={() => {
                                    setIsRowDisabled(false);
                                }}
                                sx={{ margin: 1 }}
                            >
                                <EditIcon />
                            </IconButton>
                        )}

                    {useControlTasksDisplay("UNDO_BUTTON", taskName?.toUpperCase() ?? "") &&
                        isRowDisabled && <UndoButton handleUndo={handleUndo} sx={{ margin: 1 }} />}
                    {isRowLoading && <CircularProgress color="warning" size={30} />}
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default ControlViewRow;
