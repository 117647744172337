import { type RootState } from ".";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type LoaderState } from "types/main";

const initialState: LoaderState = {
    message: undefined,
    isLoading: undefined
};

export const loaderSlice = createSlice({
    name: "loader",
    initialState,
    reducers: {
        setIsLoading: (state: LoaderState, action: PayloadAction<LoaderState>) => {
            state.message = action.payload.message;
            state.isLoading = action.payload.isLoading;
            return state;
        },
        clearIsLoading: (state: LoaderState) => {
            state = initialState;
            return state;
        }
    }
});

export const { setIsLoading, clearIsLoading } = loaderSlice.actions;
export const selectLoader = (state: RootState): LoaderState => state.loader;

export default loaderSlice.reducer;
