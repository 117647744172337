import { useEffect, useState } from "react";

import { fetchCategories, fetchCategoriesByParent } from "services/criterias";
import { handleCallErrorWithLoader } from "services/exceptions";

import { getChainedCategoryLabels } from "helpers/criteria/getChainedCategoryLabels";

import AutocompleteField from "components/formFields/AutocompleteField";
import ChoiceButton from "components/buttons/ChoiceButton";
import FormField from "components/formFields/FormField";

import { selectProduct } from "store/productSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import type { Control } from "react-hook-form";
import type { NestedCriteria, Criteria } from "types/product";

import formStyles from "../../ProductForm.module.css";

interface Props {
    control: Control;
    categoryToPost?: NestedCriteria;
    setCategoryToPost: React.Dispatch<React.SetStateAction<NestedCriteria | undefined>>;
}

const CategoryAutocompleteField = ({
    control,
    categoryToPost,
    setCategoryToPost
}: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const product = useAppSelector(selectProduct);

    const [categoriesList, setCategoriesList] = useState<Criteria[][]>([]);

    useEffect(() => {
        handleCallErrorWithLoader(dispatch, fetchCategories).then((result) => {
            setCategoriesList([result]);
        });
    }, []);

    /**
     * Category management
     * - Set category select visible if needed
     * - Display flatten categories
     * - Handle category change and nested selects
     * - Display flattened categories if existant
     */
    const [isCatButtonVisible, setIsCatButtonVisible] = useState<boolean>(false);
    const [isCatFieldVisible, setIsCatFieldVisible] = useState<boolean>(true);
    function handleCatButtonClic(): void {
        setIsCatFieldVisible(true);
        setIsCatButtonVisible(false);
    }

    const [flattenedCat, setFlattenedCat] = useState<string>();

    useEffect(() => {
        if (categoryToPost) {
            fetchCategoriesByParent(categoryToPost.id).then((result) => {
                if (result.length > 0) {
                    setCategoriesList([...categoriesList, result]);
                }
            });
        }
    }, [categoryToPost]);

    function onCategoryChange(value: Criteria, index?: number): void {
        if (index !== undefined) {
            if (categoriesList.length > index + 1) {
                setCategoriesList(categoriesList.slice(0, index + 1));
            }
            if (index === 0) {
                setCategoryToPost(value);
            } else {
                categoryToPost && setCategoryToPost({ ...value, parent: categoryToPost });
            }
        }
    }

    useEffect(() => {
        if (product.sku) {
            setIsCatFieldVisible(false);
            setIsCatButtonVisible(true);
            product.category && setFlattenedCat(getChainedCategoryLabels(product.category, 3));
        }
    }, [product]);

    return (
        <div className={formStyles.productForm__multifieldContainer}>
            {flattenedCat && (
                <div>
                    <p className={formStyles.productForm__catTitle}>{"Catégorie enregistrée :"}</p>
                    <p className={formStyles.productForm__catText}>{flattenedCat}</p>
                    <>
                        {isCatButtonVisible && (
                            <ChoiceButton
                                color="secondary"
                                onClick={() => {
                                    handleCatButtonClic();
                                }}
                                text="Modifier les catégories"
                                variant="contained"
                            />
                        )}
                    </>
                </div>
            )}
            {(!product.category || isCatFieldVisible) && (
                <div>
                    {categoriesList.map((list, i: number) => {
                        return (
                            <FormField key={i}>
                                <AutocompleteField
                                    control={control}
                                    index={i}
                                    label={i === 0 ? "Catégorie" : "Sous-catégorie"}
                                    name={`categories${i}`}
                                    optionalOnChange={onCategoryChange}
                                    optionsList={list}
                                />
                            </FormField>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default CategoryAutocompleteField;
