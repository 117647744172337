import { type FC } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import theme from "theme";
import { type ChoiceButtonParams } from "types/main";

const ChoiceButton: FC<ChoiceButtonParams> = ({
    variant,
    text,
    color,
    fullWidth,
    onClick,
    disabled,
    sx
}) => {
    return (
        <Box m={theme.spacing(1)}>
            <Button
                color={color}
                disabled={disabled}
                fullWidth={fullWidth}
                onClick={onClick}
                sx={sx}
                variant={variant}
            >
                {text}
            </Button>
        </Box>
    );
};

export default ChoiceButton;
