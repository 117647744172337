import { InputAdornment } from "@mui/material";
import FormField from "components/formFields/FormField";
import type { Control } from "react-hook-form";
import TextInputField from "components/formFields/TextInputField";
import type { ProductMeasure } from "types/product";
import { useRef } from "react";
import { useSpeechRecognition } from "react-speech-recognition";

interface Props {
    control: Control;
    existingMeasure: ProductMeasure | undefined;
    label: string;
    name: string;
    recognition_label: string;
}

const MAX_MEASUREMENT_LIMIT = 200;

const filterOutLetters = (input: string): string => {
    const filteredValue = input.replace(/[a-zA-Z]/g, "");
    let numericValue = parseFloat(filteredValue);

    if (isNaN(numericValue)) {
        numericValue = 0;
    } else if (numericValue < 0) {
        numericValue = 0;
    } else if (numericValue > MAX_MEASUREMENT_LIMIT) {
        numericValue = MAX_MEASUREMENT_LIMIT;
    }

    return numericValue.toString();
};

const MeasureTextField = ({
    control,
    label,
    name,
    existingMeasure,
    recognition_label
}: Props): JSX.Element => {
    const inputRef = useRef<any>(null);
    const commands = [
        {
            command: new RegExp(`${recognition_label}(s)? ?(\\d+)`, "i"),
            callback: (_match: any, value: string) => {
                if (inputRef.current) {
                    inputRef.current.setValue(filterOutLetters(value));
                }
            }
        }
    ];

    useSpeechRecognition({ commands });
    return (
        <FormField>
            <TextInputField
                color="primary"
                control={control}
                existantNote={existingMeasure?.value}
                InputProps={{
                    endAdornment: <InputAdornment position="end">cm</InputAdornment>
                }}
                inputRef={inputRef}
                label={label}
                name={name}
                onOptionalInputChange={filterOutLetters}
                sx={{ width: "100%" }}
            />
        </FormField>
    );
};

export default MeasureTextField;
