import { AxiosError } from "axios";
import { type Dispatch } from "redux";

import { setErrorMessage } from "store/errorSlice";
import { setIsLoading, clearIsLoading } from "store/loaderSlice";
import { clearAuthenticationInfos } from "store/authenticationSlice";

interface ErrorHandlingOptions {
    dispatch: Dispatch;
    callback: (...args: any) => Promise<any>;
    loader?: boolean;
}

async function handleError({
    dispatch,
    callback,
    loader = false,
    args
}: ErrorHandlingOptions & { args: any }): Promise<any> {
    loader && dispatch(setIsLoading({ isLoading: true }));

    return await callback(...args)
        .then((res) => {
            loader && dispatch(clearIsLoading());
            return res;
        })
        .catch(async (error) => {
            loader && dispatch(clearIsLoading());

            let errorMessage = "";

            if (error instanceof AxiosError) {
                if (error.response?.status === 401) {
                    errorMessage = "Problème d'authentification";

                    dispatch(clearAuthenticationInfos());
                } else if (error.code === AxiosError.ETIMEDOUT) {
                    errorMessage = "🔌 Echec - problème de réseau";
                } else {
                    errorMessage = "Problème call API";
                }
                console.error(error);
            } else {
                errorMessage = "Unexpected error";
                console.error("Unexpected error: ", error);
            }

            dispatch(setErrorMessage(`Erreur : ${String(errorMessage)}`));
        });
}

async function handleCallError(
    dispatch: Dispatch,
    callback: (...args: any) => Promise<any>,
    ...args: any
): Promise<any> {
    return await handleError({ dispatch, callback, args });
}

async function handleCallErrorWithLoader(
    dispatch: Dispatch,
    callback: (...args: any) => Promise<any>,
    ...args: any
): Promise<any> {
    return await handleError({ dispatch, callback, loader: true, args });
}

async function handleErrorCached({
    dispatch,
    callback,
    loader = false,
    args
}: ErrorHandlingOptions & { args: any }): Promise<any> {
    loader && dispatch(setIsLoading({ isLoading: true }));

    return await callback(...args)
        .then((res) => {
            loader && dispatch(clearIsLoading());
            return res;
        })
        .catch(async (error) => {
            loader && dispatch(clearIsLoading());

            let errorMessage = "";

            if (error instanceof AxiosError) {
                if (error.response?.status === 401) {
                    errorMessage = "Problème d'authentification";

                    dispatch(clearAuthenticationInfos());
                }
                if (error.message === "Network Error") {
                    return "CACHED";
                }

                console.error(error);
            } else {
                errorMessage = "Unexpected error";
                console.error("Unexpected error: ", error);
            }

            dispatch(setErrorMessage(`Erreur : ${String(errorMessage)}`));
        });
}

async function handleCallErrorCached(
    dispatch: Dispatch,
    callback: (...args: any) => Promise<any>,
    ...args: any
): Promise<any> {
    return await handleErrorCached({ dispatch, callback, args });
}

async function handleCallErrorWithLoaderCached(
    dispatch: Dispatch,
    callback: (...args: any) => Promise<any>,
    ...args: any
): Promise<any> {
    return await handleErrorCached({ dispatch, callback, loader: true, args });
}

export {
    handleCallError,
    handleCallErrorWithLoader,
    handleCallErrorCached,
    handleCallErrorWithLoaderCached
};
