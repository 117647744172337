import { type RootState } from ".";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type LocalStockState } from "types/stock";

const initialState: LocalStockState = {
    barcode: undefined,
    numberOfDeclaredItems: undefined,
    enableCloseStock: false,
    numberOfProcessedProducts: undefined
};

export const stockSlice = createSlice({
    name: "stock",
    initialState,
    reducers: {
        addStockBarcode: (state: LocalStockState, action: PayloadAction<string>) => {
            state.barcode = action.payload;
            return state;
        },
        addStockDetails: (state: LocalStockState, action: PayloadAction<LocalStockState>) => {
            state = action.payload;
            return state;
        },
        setNumberOfProcessedProducts: (state: LocalStockState, action: PayloadAction<number>) => {
            state.numberOfProcessedProducts = action.payload;
            return state;
        },
        enableCloseStock: (state: LocalStockState, action: PayloadAction<boolean>) => {
            state.enableCloseStock = action.payload;
            return state;
        },
        clearStock: (state: LocalStockState) => {
            state = initialState;
            return state;
        }
    }
});

export const {
    addStockBarcode,
    addStockDetails,
    setNumberOfProcessedProducts,
    enableCloseStock,
    clearStock
} = stockSlice.actions;
export const selectStock = (state: RootState): LocalStockState => state.stock;

export default stockSlice.reducer;
