import { type RootState } from ".";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { LocalSettingsState } from "types/settings";

const initialState: LocalSettingsState = {
    role: undefined
};

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        submitStudio: (state: LocalSettingsState, action: PayloadAction<string>) => {
            state.role = action.payload;
            localStorage.setItem("settings.role", action.payload);
            return state;
        },
        clearSettings: (state: LocalSettingsState) => {
            state = initialState;
            localStorage.setItem("settings.role", "");
            return state;
        }
    }
});

export const { submitStudio, clearSettings } = settingsSlice.actions;
export const selectSettings = (state: RootState): LocalSettingsState => state.settings;

export default settingsSlice.reducer;
