import type { ProductPhoto } from "types/product";

export function handlePhotosSorting(photos: ProductPhoto[]): ProductPhoto[] {
    return photos.reduce((acc: ProductPhoto[], photo: ProductPhoto): ProductPhoto[] => {
        if (photo.format === "COVER") {
            return [photo, ...acc];
        }
        return [...acc, photo];
    }, []);
}
