import type { NestedCriteria } from "types/product";

/**  This helper chains category label with parents labels. 
Param level determines how many labels are rendered */
export function getChainedCategoryLabels(category: NestedCriteria, level: number): string {
    if (category.parent && level > 1) {
        return `${getChainedCategoryLabels(category.parent, level - 1)} > ${category.label}`;
    } else {
        return `${String(category.label)}`;
    }
}
