import { useAppSelector } from "store/hooks";
import { selectSettings } from "store/settingsSlice";
import { ROLE_1, ROLE_2, ROLE_CONTROL } from "constants/usersRules";

const useStudioAccessRights = (key: string): boolean | undefined => {
    // @todo useMemo
    // @todo config file
    const RIGHTS: Array<{ key: string; role: string[] }> = [
        // Home buttons
        { key: "HOME_BUTTON_PRICING", role: [] },
        { key: "HOME_BUTTON_PRODUCT_CREATION", role: [ROLE_1] },
        { key: "HOME_BUTTON_PRODUCT_UPDATE", role: [ROLE_2, ROLE_1] },
        { key: "HOME_BUTTON_STOCK", role: [ROLE_1] },
        { key: "HOME_BUTTONS_CONTROL", role: [ROLE_CONTROL] },

        // Scanners
        { key: "CAMERA_CODE_SCANNER", role: [] },
        { key: "EXTERNAL_CODE_SCANNER", role: [ROLE_1, ROLE_2] },

        // Product form fields
        { key: "PRODUCT_FORM_FIELD_ATTRACTIVITY", role: [ROLE_1] },
        { key: "PRODUCT_FORM_FIELD_BRAND", role: [] },
        { key: "PRODUCT_FORM_FIELD_CATEGORY", role: [] },
        { key: "PRODUCT_FORM_FIELD_COLOR", role: [] },
        { key: "PRODUCT_FORM_FIELD_CONDITION", role: [ROLE_1, ROLE_2] },
        { key: "PRODUCT_FORM_FIELD_FLAWS", role: [ROLE_2, ROLE_1] },
        { key: "PRODUCT_FORM_FIELD_MATERIAL", role: [] },
        { key: "PRODUCT_FORM_FIELD_MEASUREMENT", role: [ROLE_1] },
        { key: "PRODUCT_FORM_FIELD_PHOTO_BRAND", role: [ROLE_1] },
        { key: "PRODUCT_FORM_FIELD_PHOTO_COVER", role: [ROLE_2] },
        { key: "PRODUCT_FORM_FIELD_PHOTO_FLAW", role: [ROLE_2, ROLE_1] },
        { key: "PRODUCT_FORM_FIELD_PHOTO_LABEL", role: [ROLE_1] },
        { key: "PRODUCT_FORM_FIELD_PHOTO_MATERIAL", role: [ROLE_1] },
        { key: "PRODUCT_FORM_FIELD_PHOTO_OTHER", role: [] },
        { key: "PRODUCT_FORM_FIELD_PHOTO_OTHER2", role: [ROLE_1] },
        { key: "PRODUCT_FORM_FIELD_PHOTO_OTHER3", role: [ROLE_1] },
        { key: "PRODUCT_FORM_FIELD_PHOTO_PRICE_LABEL", role: [ROLE_1] },
        { key: "PRODUCT_FORM_FIELD_PHOTO_REVERSE", role: [ROLE_2] },
        { key: "PRODUCT_FORM_FIELD_PRICE", role: [ROLE_1] },
        { key: "PRODUCT_FORM_FIELD_PRIVATE_NOTES", role: [ROLE_2, ROLE_1] },
        { key: "PRODUCT_FORM_FIELD_PUBLIC_NOTES", role: [ROLE_1, ROLE_2] },
        {
            key: "PRODUCT_FORM_FIELD_REFUSED_FLAWS",
            role: [ROLE_1, ROLE_2, ROLE_1]
        },
        { key: "PRODUCT_FORM_FIELD_SIZE", role: [ROLE_1] },
        { key: "PRODUCT_FORM_FIELD_USUAL_COMMENTS", role: [ROLE_1, ROLE_2] },
        { key: "PRODUCT_FORM_FIELD_USUAL_FLAWS", role: [ROLE_1, ROLE_2, ROLE_1] },

        // Product confirm modal buttons
        { key: "BACK_TO_PRODUCT_FORM_BUTTON", role: [ROLE_2, ROLE_1] },
        { key: "BACK_TO_HOME_BUTTON", role: [ROLE_2, ROLE_1] },
        { key: "NEXT_PRODUCT_CREATION_BUTTON", role: [ROLE_1] }
    ];

    const settings = useAppSelector(selectSettings);

    if (settings.role) {
        const item = RIGHTS.find((obj) => {
            return obj.key === key;
        });
        return item?.role.includes(settings.role);
    }
};

export { useStudioAccessRights };
