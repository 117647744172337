import { useNavigate, useParams } from "react-router-dom";

import { Box, Button, IconButton } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";

import { useAppSelector } from "store/hooks";
import { selectPicking } from "store/pickingSlice";
import { PRODUCTS_TO_PICK_PARAM } from "constants/operationsRules";

interface Props {
    setIsProductPicked: React.Dispatch<React.SetStateAction<boolean>>;
    setPickedProductData: React.Dispatch<React.SetStateAction<any>>;
}

const ScanErrorButtonsGroup = ({
    setIsProductPicked,
    setPickedProductData
}: Props): JSX.Element => {
    const navigate = useNavigate();

    const { product_picking_status } = useParams();

    const productToPick = useAppSelector(selectPicking).productsToPick[0];
    const pickingSessionId = useAppSelector(selectPicking).id;

    // When product has wrong / lost / unreadable QR
    // set specific data for PickingProduct call
    // TODO : handle wrong & lost/unreadable cases separately
    function handleWrongProductItemQr(): void {
        const isRetrieval = Boolean(productToPick?.retrieval);

        setPickedProductData({
            has_wrong_item_qr: true,
            is_not_found: false,
            is_picked: true,
            is_retrieval: isRetrieval,
            picking_session: pickingSessionId,
            ...(product_picking_status === PRODUCTS_TO_PICK_PARAM
                ? isRetrieval
                    ? { retrieved_product_id: productToPick.id }
                    : { ordered_product_id: productToPick.id }
                : {})
        });
        setIsProductPicked(true);
    }

    return (
        <Box
            sx={{
                alignItems: "flex-end",
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
            }}
        >
            <IconButton
                aria-label="Undo"
                color="error"
                onClick={() => {
                    navigate(-1);
                }}
                size="large"
            >
                <UndoIcon />
            </IconButton>
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    width: "80%"
                }}
            >
                <Button
                    color="primary"
                    onClick={handleWrongProductItemQr}
                    size="large"
                    sx={{ width: "100%" }}
                    variant="outlined"
                >
                    Pas de QR / Illisible
                </Button>

                <Button
                    color="primary"
                    onClick={handleWrongProductItemQr}
                    size="large"
                    sx={{ width: "100%" }}
                    variant="outlined"
                >
                    Mauvais QR
                </Button>
            </Box>
        </Box>
    );
};

export default ScanErrorButtonsGroup;
