import { useForm } from "react-hook-form";

import { postControlledProducts } from "services/controls";
import { handleCallError } from "services/exceptions";
import { useControlTasksDisplay } from "hooks/controlTasksDisplay";

import { Box } from "@mui/material";
import AttractivityTagActions from "./AttractivityTagActions";
import BrandActions from "./BrandActions";
import CategoryActions from "./CategoryActions";
import SizeActions from "./SizeActions";
import UniverseActions from "./UniverseActions";
import WornPhotoActions from "./WornPhotosActions";

import { useAppDispatch } from "store/hooks";

import type { Criteria, ProductState, RatedCriteria } from "types/product";

interface Props {
    attractivitytagsList: RatedCriteria[];
    isRowDisabled: boolean;
    product: ProductState;
    controlledProducts: string[];
    setControlledProducts: React.Dispatch<React.SetStateAction<string[]>>;
    setIsRowDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    setIsRowLoading: React.Dispatch<React.SetStateAction<boolean>>;
    smallBrand?: Criteria;
    taskName?: string;
    updateProductUniverseToOtherGender?: (gender: string) => Promise<void>;
    vintageBrand?: Criteria;
}

const ControlActions = ({
    attractivitytagsList,
    isRowDisabled,
    product,
    controlledProducts,
    setControlledProducts,
    setIsRowDisabled,
    setIsRowLoading,
    smallBrand,
    taskName,
    updateProductUniverseToOtherGender,
    vintageBrand
}: Props): JSX.Element => {
    const { control, setValue } = useForm({
        mode: "onSubmit"
    });

    const dispatch = useAppDispatch();

    // Create product control (used for brand & size)
    async function createControl(): Promise<void> {
        const result = await handleCallError(dispatch, postControlledProducts, taskName, [
            product.sku
        ]);
        if (result) {
            setIsRowDisabled(true);
            if (product.sku && !controlledProducts.includes(product.sku)) {
                setControlledProducts([...controlledProducts, product.sku]);
            }
        }
    }

    return (
        <form>
            <Box
                sx={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    minWidth: "250px"
                }}
            >
                {useControlTasksDisplay(
                    "ATTRACTIVITY_TAGS_FIELDS",
                    taskName?.toUpperCase() ?? ""
                ) && (
                    <AttractivityTagActions
                        attractivitytagsList={attractivitytagsList}
                        controlledProducts={controlledProducts}
                        isRowDisabled={isRowDisabled}
                        product={product}
                        setControlledProducts={setControlledProducts}
                        setIsRowDisabled={setIsRowDisabled}
                    />
                )}

                {useControlTasksDisplay("BRAND_FIELDS", taskName?.toUpperCase() ?? "") && (
                    <BrandActions
                        control={control}
                        createControl={createControl}
                        isRowDisabled={isRowDisabled}
                        product={product}
                        setIsRowLoading={setIsRowLoading}
                        smallBrand={smallBrand}
                        vintageBrand={vintageBrand}
                    />
                )}

                {useControlTasksDisplay("CATEGORY_FIELDS", taskName?.toUpperCase() ?? "") && (
                    <CategoryActions
                        control={control}
                        controlledProducts={controlledProducts}
                        isRowDisabled={isRowDisabled}
                        product={product}
                        setControlledProducts={setControlledProducts}
                        setIsRowDisabled={setIsRowDisabled}
                        setIsRowLoading={setIsRowLoading}
                    />
                )}

                {useControlTasksDisplay("SIZE_FIELDS", taskName?.toUpperCase() ?? "") && (
                    <SizeActions
                        control={control}
                        controlledProducts={controlledProducts}
                        createControl={createControl}
                        isRowDisabled={isRowDisabled}
                        product={product}
                        setIsRowLoading={setIsRowLoading}
                        setValue={setValue}
                    />
                )}

                {useControlTasksDisplay("UNIVERSE_FIELDS", taskName?.toUpperCase() ?? "") &&
                    updateProductUniverseToOtherGender && (
                        <UniverseActions
                            isRowDisabled={isRowDisabled}
                            updateProductUniverseToOtherGender={updateProductUniverseToOtherGender}
                        />
                    )}

                {useControlTasksDisplay("DELETE_PHOTO_BUTTON", taskName?.toUpperCase() ?? "") && (
                    <WornPhotoActions
                        control={control}
                        controlledProducts={controlledProducts}
                        createControl={createControl}
                        isRowDisabled={isRowDisabled}
                        product={product}
                        setIsRowLoading={setIsRowLoading}
                    />
                )}
            </Box>
        </form>
    );
};

export default ControlActions;
