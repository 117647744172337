import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
interface Props {
    handleChange: any;
    selectedValue?: any;
}

const BrandRadioButtons = ({ selectedValue, handleChange }: Props): JSX.Element => {
    return (
        <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            name="brand"
            onChange={handleChange}
            value={selectedValue}
        >
            <FormControlLabel
                control={<Radio />}
                label="Marque pas dans la liste"
                value="Petite marque"
            />
            <FormControlLabel control={<Radio />} label="Sans marque" value="Sans marque" />
        </RadioGroup>
    );
};

export default BrandRadioButtons;
