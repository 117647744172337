import { useEffect, useState } from "react";

import { fetchMaterials } from "services/criterias";
import { handleCallErrorWithLoader } from "services/exceptions";

import AutocompleteField from "components/formFields/AutocompleteField";
import FormField from "components/formFields/FormField";

import { selectProduct } from "store/productSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import type { Control } from "react-hook-form";
import type { Criteria } from "types/product";

interface Props {
    control: Control;
}

const MaterialAutocompleteField = ({ control }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const product = useAppSelector(selectProduct);

    const [materialsList, setMaterialsList] = useState<Criteria[]>([]);

    useEffect(() => {
        handleCallErrorWithLoader(dispatch, fetchMaterials).then((result) => {
            setMaterialsList(result);
        });
    }, []);

    return (
        <FormField>
            <AutocompleteField
                control={control}
                existantCriteria={product.material}
                label={"Matière"}
                name="material"
                optionsList={materialsList}
            />
        </FormField>
    );
};

export default MaterialAutocompleteField;
