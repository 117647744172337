import { useState } from "react";
import { Controller } from "react-hook-form";

import { fetchBrands } from "services/criterias";

import AutocompleteField from "components/formFields/AutocompleteField";
import BrandRadioButtons from "../toggleButtonGroups/BrandRadioButtons";
import FormField from "components/formFields/FormField";

import { selectProduct } from "store/productSlice";
import { useAppSelector } from "store/hooks";

import type { Control } from "react-hook-form";
import type { Criteria } from "types/product";

interface Props {
    control: Control;
}

const BrandAutocompleteField = ({ control }: Props): JSX.Element => {
    const [brandsList, setBrandsList] = useState<Criteria[]>([]);
    const [selectedRadioValue, setSelectedRadioValue] = useState("Marque");

    const product = useAppSelector(selectProduct);

    async function setBrandsListInAutocomplete(inputBrand: string): Promise<void> {
        const result = await fetchBrands(inputBrand);

        setBrandsList(result);
        setSelectedRadioValue(inputBrand);
    }

    return (
        <Controller
            control={control}
            name="brand"
            render={({ field }) => (
                <FormField>
                    <AutocompleteField
                        control={control}
                        existantCriteria={product.brand}
                        label="Marque"
                        name="brand"
                        optionalOnInputChange={setBrandsListInAutocomplete}
                        optionsList={brandsList}
                    />
                    <BrandRadioButtons
                        handleChange={async (e: { target: { value: any } }) => {
                            const selectedValue = e.target.value;
                            const brandButton = await fetchBrands(selectedValue);
                            const formattedData = {
                                id: brandButton[0]?.id,
                                label: selectedValue
                            };

                            setSelectedRadioValue(selectedValue);
                            field.onChange(formattedData);
                        }}
                        selectedValue={selectedRadioValue}
                    />
                </FormField>
            )}
        />
    );
};

export default BrandAutocompleteField;
