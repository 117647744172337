import { Link, useLocation, useNavigate } from "react-router-dom";

import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectAuthentication, clearAuthenticationInfos } from "store/authenticationSlice";
import { clearSettings } from "store/settingsSlice";

import { STUDIO_USER_GROUP, WAREHOUSE_USER_GROUP } from "constants/usersRules";

/**
 * Header components
 * Displays title and settings button
 */
const Header = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const authenticationInfos = useAppSelector(selectAuthentication);
    const currentGroup = localStorage.getItem("currentGroup");
    const isOnProductFormPage = ["/studio/product/accept", "/studio/product/reject"].includes(
        location.pathname
    );

    const logout = (): any => {
        dispatch(clearAuthenticationInfos());
        dispatch(clearSettings());
        localStorage.clear();
    };

    function handleRedirect(path: string): void {
        navigate(path);
    }

    return (
        <AppBar position="static">
            <Toolbar variant="dense">
                {currentGroup === WAREHOUSE_USER_GROUP && (
                    <Typography
                        onClick={() => {
                            handleRedirect("/warehouse");
                        }}
                        sx={{ flexGrow: 1 }}
                        variant="h6"
                    >
                        OMAJ OMS
                    </Typography>
                )}
                {currentGroup === STUDIO_USER_GROUP && (
                    <Typography
                        onClick={() => {
                            !isOnProductFormPage && handleRedirect("/studio");
                        }}
                        sx={{ flexGrow: 1 }}
                        variant="h6"
                    >
                        OMAJ PIM
                    </Typography>
                )}
                {!currentGroup && (
                    <Typography sx={{ flexGrow: 1 }} variant="h6">
                        OMAJ
                    </Typography>
                )}

                {currentGroup === STUDIO_USER_GROUP && (
                    <IconButton
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        aria-label="Settings"
                        color="inherit"
                        size="large"
                    >
                        <Link to="/studio/settings">
                            <SettingsIcon />
                        </Link>
                    </IconButton>
                )}

                <IconButton
                    aria-controls="menu-appbar"
                    aria-label="Logout"
                    color="inherit"
                    disabled={!authenticationInfos.isLoggedIn}
                    onClick={logout}
                    size="large"
                >
                    <LogoutIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
