import { type UsualValue } from "types/main";

export const commentsValues: UsualValue[] = [
    {
        label: "❌ Etiq. taille",
        value: "Pas d’étiquette - la taille a été estimée par notre équipe"
    },
    {
        label: "❌ Etiq. de composition",
        value: "Pas d’étiquette de composition"
    },
    {
        label: "Transparent",
        value: "Transparent"
    },
    {
        label: "Modèle ...",
        value: "Modèle XXX"
    },
    {
        label: "Réversible",
        value: "Vêtement réversible"
    },
    {
        label: "Taille W... L...",
        value: "Taille W XXX L XXX"
    },
    {
        label: "Ensemble : tailles =",
        value: "Ensemble : Le haut et le bas sont de la même taille"
    },
    {
        label: "Ensemble : tailles ≠",
        value: "Ensemble : Le haut est en taille XXX et le bas en taille XXX"
    },
    {
        label: "Grossesse",
        value: "Femme enceinte / grossesse"
    },
    {
        label: "Allaitement",
        value: "Vêtement d’allaitement"
    }
];
