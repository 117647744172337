import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import { Container, Box } from "@mui/material";

import ErrorDialog from "components/errorDialog/ErrorDialog";
import Header from "components/Header";
import Loader from "components/loader/Loader";
import StockFooter from "components/stockFooter/StockFooter";

import initSentry from "helpers/sentry";

import FeatureSwitch from "pages/featureSwitch/FeatureSwitch";
import StudioHome from "pages/studio/home/StudioHome";
import Settings from "pages/studio/settings/Settings";
import ScanProduct from "pages/studio/product/ScanProduct";
import OpenStock from "pages/studio/stock/OpenStock";
import ControlView from "pages/studio/control/ControlView";
import Microphone from "components/Microphone";
import ProductConformity from "pages/studio/product/ProductConformity";
import ProductForm from "pages/studio/product/ProductForm/ProductForm";
import ProductOverview from "pages/studio/product/ProductOverview";
import ProductPickingStatusRouter from "pages/warehouse/picking/routers/ProductPickingStatusRouter";
import PickingCart from "pages/warehouse/picking/pickingCart/PickingCart";
import PickingRails from "pages/warehouse/picking/pickingRails/PickingRails";
import PickLocation from "pages/studio/location/PickLocation";
import PricingPage from "pages/studio/pricing/PricingPage";
import ProtectedRoute from "pages/protectedRoute/ProtectedRoute";
import WarehouseHome from "pages/warehouse/home/WarehouseHome";
import { setCurrentGroup } from "store/authenticationSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectLoader } from "store/loaderSlice";
import { selectStock, clearStock, addStockDetails } from "store/stockSlice";
import { submitStudio } from "store/settingsSlice";

import appStyle from "App.module.css";

initSentry();

function App(): JSX.Element {
    const dispatch = useAppDispatch();
    const loader = useAppSelector(selectLoader);
    const stock = useAppSelector(selectStock);
    const [isFooterCollapsed, setIsFooterCollapsed] = useState<boolean>(false);
    const [bodyHeight, setBodyHeight] = useState<string>("app__pageMax");

    useEffect(() => {
        // Get stored stock
        const storedStock = localStorage.getItem("stock");
        if (storedStock) {
            const jsonStock = JSON.parse(storedStock);

            if (jsonStock?.barcode) {
                dispatch(addStockDetails(jsonStock));
            } else {
                dispatch(clearStock());
            }
        }

        // Get stored settings
        const role = localStorage.getItem("settings.role");
        if (role) {
            dispatch(submitStudio(role));
        }

        // Get stored group
        const currentGroup = localStorage.getItem("currentGroup");
        if (currentGroup) {
            dispatch(setCurrentGroup(currentGroup));
        }
    }, []);

    useEffect(() => {
        isFooterCollapsed ? setBodyHeight("app__pageMax") : setBodyHeight("app__pageWithFooter");
    }, [isFooterCollapsed]);

    return (
        <div className={appStyle.app__container}>
            <ErrorDialog />
            <Header />
            <Microphone />
            <div className={`${appStyle[bodyHeight]}`}>
                {loader.isLoading && <Loader />}
                <Container maxWidth="xl">
                    <Box mt="2rem">
                        <Routes>
                            <Route element={<ProtectedRoute />} path="/">
                                <Route element={<FeatureSwitch />} path="/switch" />

                                <Route path="studio">
                                    <Route element={<StudioHome />} index />
                                    <Route element={<ControlView />} path="control/:taskName" />
                                    <Route
                                        element={<ControlView />}
                                        path="control/:taskName/:attractivityTagId"
                                    />
                                    <Route element={<PickLocation />} path="location" />
                                    <Route path="product">
                                        <Route element={<ProductConformity />} path="open" />
                                        <Route element={<ScanProduct />} path="scan" />
                                        <Route element={<ProductOverview />} path="overview" />
                                        <Route
                                            element={<ProductForm productStatus="ACCEPTED" />}
                                            path="accept"
                                        />
                                        <Route
                                            element={<ProductForm productStatus="REFUSED" />}
                                            path="reject"
                                        />
                                        <Route element={<PricingPage />} path="pricing" />
                                    </Route>
                                    <Route element={<Settings />} path="settings" />
                                    <Route element={<OpenStock />} path="stock/open" />
                                </Route>

                                <Route path="warehouse">
                                    <Route element={<WarehouseHome />} index />
                                    <Route path="pickingrails">
                                        <Route element={<PickingRails />} index />
                                        <Route path=":picking_rail_id">
                                            <Route
                                                element={<ProductPickingStatusRouter />}
                                                path=":product_picking_status/*"
                                            />
                                            <Route element={<PickingCart />} path="overview" />
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                        </Routes>
                    </Box>
                </Container>
            </div>
            {stock.barcode ? (
                <StockFooter
                    isFooterCollapsed={isFooterCollapsed}
                    setIsFooterCollapsed={setIsFooterCollapsed}
                />
            ) : null}
        </div>
    );
}

export default App;
