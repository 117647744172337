import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { postControlledProducts } from "services/controls";
import { handleCallErrorWithLoader } from "services/exceptions";

import { Box, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import ChoiceButton from "components/buttons/ChoiceButton";

import { useAppDispatch } from "store/hooks";

import { CONTROL_BRANDS, CONTROL_SIZES } from "constants/usersRules";
import type { ProductState } from "types/product";

interface Props {
    controlledProducts: string[];
    handleNextControlSession: () => void;
    isControlDialogOpen: boolean;
    productsToControl: ProductState[];
    remainingControls: number;
    setIsControlDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    taskName?: string;
    totalControls: number;
}

const ControlDialog = ({
    controlledProducts,
    handleNextControlSession,
    isControlDialogOpen,
    productsToControl,
    remainingControls,
    setIsControlDialogOpen,
    taskName,
    totalControls
}: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [controlsPostResult, setControlsPostResult] = useState<any>();

    // If control task is brands or sizes, controls are already posted - skip to next step
    useEffect(() => {
        if (taskName && [CONTROL_BRANDS, CONTROL_SIZES].includes(taskName?.toUpperCase())) {
            setControlsPostResult({ created_controls: controlledProducts, errors: [] });
        }
    }, [controlledProducts]);

    // On modal validation : post all controls for the current session products
    async function postControls(): Promise<void> {
        const skusToControl = productsToControl.map((p) => {
            return p.sku;
        });
        const result = await handleCallErrorWithLoader(
            dispatch,
            postControlledProducts,
            taskName,
            skusToControl
        );
        if (result) {
            setControlsPostResult(result);
        }
    }

    return (
        <Dialog
            onClose={() => {
                setIsControlDialogOpen(false);
            }}
            open={isControlDialogOpen}
            sx={{ padding: "20px" }}
        >
            <DialogTitle textAlign="center">
                {!controlsPostResult
                    ? `Valider la session de contrôle : ${String(taskName)}`
                    : `Résultat des contrôles : ${String(taskName)}`}
            </DialogTitle>
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}
            >
                {!controlsPostResult ? (
                    <>
                        <Box my="20px">
                            <Typography
                                sx={{ textAlign: "center" }}
                            >{`${controlledProducts.length} produits modifiés`}</Typography>
                            <Typography
                                sx={{ textAlign: "center" }}
                            >{`${remainingControls} produits non modifiés`}</Typography>
                        </Box>
                        <Box mt="20px" sx={{ display: "flex", justifyContent: "center" }}>
                            <ChoiceButton
                                color="primary"
                                onClick={postControls}
                                text="✅ Valider la session"
                                variant="contained"
                            />
                        </Box>
                    </>
                ) : (
                    <>
                        <Box my="20px">
                            <Typography sx={{ textAlign: "center" }}>
                                {`✅ ${String(
                                    controlsPostResult.created_controls.length
                                )} contrôles créés`}
                            </Typography>
                            {controlsPostResult.errors.length ? (
                                controlsPostResult.errors.map((e: any) => {
                                    return (
                                        <Typography
                                            key={e.sku}
                                            color="error"
                                            sx={{ textAlign: "center" }}
                                        >
                                            {`❌ ${String(e.sku)}`}
                                        </Typography>
                                    );
                                })
                            ) : (
                                <></>
                            )}
                            {totalControls === controlsPostResult.created_controls?.length && (
                                <Typography sx={{ textAlign: "center" }}>
                                    {`Tous les ${String(taskName)} ont été contrôlés`}
                                </Typography>
                            )}
                        </Box>
                        <Box mt="20px" sx={{ display: "flex", justifyContent: "center" }}>
                            <ChoiceButton
                                color="secondary"
                                onClick={() => {
                                    navigate("/studio");
                                }}
                                text={`Choisir un autre contrôle`}
                                variant="contained"
                            />
                            {totalControls > controlsPostResult.created_controls?.length && (
                                <ChoiceButton
                                    color="primary"
                                    onClick={handleNextControlSession}
                                    text={`Session ${String(taskName)} suivante`}
                                    variant="contained"
                                />
                            )}
                        </Box>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ControlDialog;
