import { useNavigate } from "react-router-dom";

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import ChoiceButton from "components/buttons/ChoiceButton";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectStock } from "store/stockSlice";

import { handleCallErrorWithLoader } from "services/exceptions";
import { updateStock } from "services/stocks";

import type { StockOverview } from "types/stock";

interface Props {
    clearCurrentStock: () => void;
    finishedStockProducts?: StockOverview;
    isStockFooterDialogOpen: boolean;
    setIsStockFooterDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const StockFooterDialog = ({
    clearCurrentStock,
    finishedStockProducts,
    isStockFooterDialogOpen,
    setIsStockFooterDialogOpen
}: Props): JSX.Element => {
    const stock = useAppSelector(selectStock);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    /**
     * Finish button
     * - Send API request to change stock status
     */
    const handleFinishStock = (): void => {
        void (async () => {
            const data = {
                status: "PHOTOS_FINISHED"
            };
            await handleCallErrorWithLoader(dispatch, updateStock, data, stock.barcode);
            clearCurrentStock();
            navigate("/studio");
        })();
    };

    const handleClose = (): void => {
        setIsStockFooterDialogOpen(false);
    };

    return (
        <Dialog
            aria-labelledby="stock-confirm-dialog"
            onClose={handleClose}
            open={isStockFooterDialogOpen}
        >
            <DialogTitle id="stock-confirm-dialog">{"Etes-vous sûr(e) ?"}</DialogTitle>
            <DialogContent>
                <Typography>Le traitement du colis sera considéré comme terminé.</Typography>
                {finishedStockProducts && (
                    <Box
                        sx={{
                            backgroundColor: "#989E8F",
                            marginTop: 4,
                            padding: "12px 0",
                            textAlign: "center"
                        }}
                    >
                        <Typography sx={{ fontWeight: "bold" }} variant="h6">
                            {finishedStockProducts.total} produits processés :
                        </Typography>
                        <Typography>
                            {finishedStockProducts.accepted} accepté
                            {finishedStockProducts.accepted > 1 && "s"}
                        </Typography>
                        <Typography>
                            {finishedStockProducts.refused} refusé
                            {finishedStockProducts.refused > 1 && "s"}
                        </Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
                <ChoiceButton
                    color="secondary"
                    onClick={() => {
                        setIsStockFooterDialogOpen(false);
                    }}
                    text="Annuler"
                    variant="contained"
                />
                <ChoiceButton
                    color="primary"
                    onClick={handleFinishStock}
                    text="Confirmer"
                    variant="contained"
                />
            </DialogActions>
        </Dialog>
    );
};

export default StockFooterDialog;
