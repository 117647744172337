import { useState } from "react";
import type { Control } from "react-hook-form";

import { fetchBrands } from "services/criterias";
import { handleCallError } from "services/exceptions";
import { patchProductInfos } from "services/products";

import AutocompleteField from "components/formFields/AutocompleteField";
import ChoiceButton from "components/buttons/ChoiceButton";

import { useAppDispatch } from "store/hooks";

import type { Criteria, ProductState } from "types/product";

interface Props {
    control: Control;
    createControl: () => Promise<void>;
    isRowDisabled: boolean;
    product: ProductState;
    setIsRowLoading: React.Dispatch<React.SetStateAction<boolean>>;
    smallBrand?: Criteria;
    vintageBrand?: Criteria;
}

const BrandActions = ({
    control,
    createControl,
    isRowDisabled,
    product,
    setIsRowLoading,
    smallBrand,
    vintageBrand
}: Props): JSX.Element => {
    const dispatch = useAppDispatch();

    const [brandsList, setBrandsList] = useState<Criteria[]>([]);
    const [displayedBrand, setDisplayedBrand] = useState<Criteria | undefined>(product.brand);

    async function setBrandsListInAutocomplete(inputBrand: string): Promise<void> {
        const result = await fetchBrands(inputBrand);
        setBrandsList(result);
    }

    // Patch product brand & display new value in autocomplete field
    async function updateProductBrand(data: Criteria): Promise<void> {
        const result = await updateProduct({ brand: data });
        result && setDisplayedBrand(data);
    }

    // Patch product (works for brand & internal_notes)
    async function updateProduct(dataToSend: any): Promise<any> {
        setIsRowLoading(true);
        const result = await handleCallError(dispatch, patchProductInfos, dataToSend, product.sku);
        if (result) {
            createControl();
        }
        setIsRowLoading(false);
        return result;
    }

    return (
        <>
            <AutocompleteField
                control={control}
                disabled={isRowDisabled}
                existantCriteria={displayedBrand}
                label="Marque"
                name={product.sku ?? ""}
                optionalOnChange={updateProductBrand}
                optionalOnInputChange={setBrandsListInAutocomplete}
                optionsList={brandsList}
            />
            <ChoiceButton
                disabled={isRowDisabled}
                onClick={() => {
                    smallBrand && updateProductBrand(smallBrand);
                }}
                sx={{ marginBottom: "-10px", textTransform: "none", width: "100%" }}
                text="Petite marque"
                variant="outlined"
            />
            <ChoiceButton
                disabled={isRowDisabled}
                onClick={() => {
                    vintageBrand && updateProductBrand(vintageBrand);
                }}
                sx={{ marginBottom: "-10px", textTransform: "none", width: "100%" }}
                text="👵 Vintage"
                variant="outlined"
            />
            <ChoiceButton
                disabled={isRowDisabled}
                onClick={() => {
                    updateProduct({ internal_notes: "Marque à rajouter" });
                }}
                sx={{ marginBottom: "-10px", textTransform: "none", width: "100%" }}
                text="✚ Marque à rajouter"
                variant="outlined"
            />
        </>
    );
};

export default BrandActions;
