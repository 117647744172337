import FormField from "components/formFields/FormField";
import TextInputField from "components/formFields/TextInputField";

import { selectProduct } from "store/productSlice";
import { useAppSelector } from "store/hooks";

import type { Control } from "react-hook-form";

interface Props {
    control: Control;
}

const InternalNotesNoteField = ({ control }: Props): JSX.Element => {
    const product = useAppSelector(selectProduct);

    return (
        <FormField>
            <TextInputField
                control={control}
                existantNote={product.internal_notes}
                label={"Notes (privées)"}
                minRows={3}
                multiline
                name="internal_notes"
            />
        </FormField>
    );
};

export default InternalNotesNoteField;
