import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSpeechRecognition } from "react-speech-recognition";

import ChoiceButton from "components/buttons/ChoiceButton";
import ScreenTitle from "components/screenTitle";

import { clearErrorMessage } from "store/errorSlice";
import { useAppDispatch } from "store/hooks";
import { enableCloseStock } from "store/stockSlice";

const ProductConformity = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const commands = [
        {
            command: "Accepter",
            callback: () => {
                navigate("/studio/product/scan");
            }
        },
        {
            command: "Refuser",
            callback: () => {
                navigate("/studio/product/reject");
            }
        }
    ];

    useSpeechRecognition({ commands });

    useEffect(() => {
        dispatch(enableCloseStock(true));
        dispatch(clearErrorMessage());
    }, []);

    return (
        <>
            <ScreenTitle text={"Nouveau produit"} />
            <p>{"Est-il conforme aux critères OMAJ ?"}</p>
            <div>
                <ChoiceButton
                    color="error"
                    onClick={() => {
                        navigate("/studio/product/reject");
                    }}
                    text="Non - Refuser"
                    variant="contained"
                />
                <ChoiceButton
                    onClick={() => {
                        navigate("/studio/product/scan");
                    }}
                    text="Oui - Accepter"
                    variant="contained"
                />
            </div>
        </>
    );
};

export default ProductConformity;
