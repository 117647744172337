import { axiosClient } from "helpers/axios";

async function fetchStockFromBarcode(barcode: string): Promise<any> {
    const result = await axiosClient().get(`stocks/${barcode}/without_products/`);

    return result.data;
}

async function updateStock(data: any, barcode: string): Promise<any> {
    const result = await axiosClient().patch(`stocks/${barcode}/`, data);

    return result.data;
}

export { fetchStockFromBarcode, updateStock };
